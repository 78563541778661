define("adept-iq/pods/components/adhoc-reports-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    workspace: Ember.inject.service(),
    notifications: Ember.inject.service(),
    report: Ember.inject.service(),
    isAdhocViewEnabled: false,
    hasFetchedAdhocUrl: false,

    init() {
      this._super(...arguments);
    },

    didReceiveAttrs() {
      this._super(...arguments);
    },

    willInsertElement() {
      this._super(...arguments);

      this.getAdhocViewUrl();
    },

    willDestroyElement() {
      this._super(...arguments);

      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    actions: {
      onIFrameLoad() {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        const notifications = this.get('notifications');
        const that = document.getElementById('reportViewFrame');

        try {
          const isLoaded = that.contentWindow.window.length; // 0 or 1

          if (isLoaded === 0) {
            notifications.warning('Error related to the url link of the adhoc report report');
          }
        } catch (err) {
          notifications.warning('Error into the try/catch iframe onload section');
        }
      }

    },

    async getAdhocViewUrl() {
      this.workspace.set('isGlobalSpinnerVisible', true);

      try {
        const result = await this.report.getAdhocViewUrl();

        if (!Ember.isEmpty(result)) {
          this.set('iframeSrc', result);
          this.set('isAdhocViewEnabled', true);
        }
      } catch (error) {
        this.notifications.warning('Failed to load Adhoc View');
      } finally {
        this.workspace.set('isGlobalSpinnerVisible', false);
      }
    }

  });

  _exports.default = _default;
});