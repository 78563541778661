define("adept-iq/adapters/-neo4j-neo4jService", ["exports", "adept-iq/adapters/application", "adept-iq/config/api-urls"], function (_exports, _application, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    host: _apiUrls.API.neo4jService.host
  });

  _exports.default = _default;
});