define("adept-iq/adapters/archived-activity-log", ["exports", "adept-iq/adapters/-neo4j-neo4jService"], function (_exports, _neo4jNeo4jService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _neo4jNeo4jService.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    getActivityLogs(query) {
      const session = this.get('session');
      var url = this.buildURL('event', null, null, 'query', query);
      return this.get('ajax').post(url, {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: query
      });
    }

  });

  _exports.default = _default;
});