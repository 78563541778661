define("adept-iq/pods/components/side-drawer/cancel-trip-form/cancel-related-trips/component", ["exports", "moment", "adept-iq/config/environment", "lodash"], function (_exports, _moment, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    relatedTrips: null,
    isLateCancel: false,

    init() {
      this._super(...arguments);
    },

    relatedTripDisplayGroups: Ember.computed('relatedTrips.@each.id', function () {
      const relatedTrips = this.get('relatedTrips');
      const sortedRelatedTrips = relatedTrips.sortBy('schedule.displayName', 'id');

      const groupCollection = _lodash.default.groupBy(sortedRelatedTrips, trip => {
        const scheduleStart = trip.get('schedule.start');
        return (0, _moment.default)(scheduleStart).format(_environment.default.dateTimeFormat.dateMoment);
      });

      const groups = _lodash.default.map(groupCollection, (trips, title) => {
        return {
          title,
          trips
        };
      });

      return groups;
    }),
    relatedTripsExist: Ember.computed('relatedTrips.@each.id', function () {
      const relatedTripsLength = this.get('relatedTrips.length');
      return relatedTripsLength > 0;
    }),
    actions: {
      onSelectedTrip(trip, event) {
        const isChecked = event.target.checked;
        trip.set('isChecked', isChecked);
        const isAllTripsChecked = this.get('relatedTrips').isEvery('isChecked');
        const isAllChecked = this.get('isAllChecked');
        if (isAllTripsChecked && !isAllChecked || !isAllTripsChecked && isAllChecked) this.toggleProperty('isAllChecked');
      },

      onCheckAllTrips() {
        const relatedTrips = this.get('relatedTrips');
        this.toggleProperty('isAllChecked');
        const isChecked = this.get('isAllChecked');
        relatedTrips.forEach(relatedTrip => {
          if (relatedTrip.isLateCancel === this.isLateCancel) {
            relatedTrip.set('isChecked', isChecked);
          }
        });
      }

    }
  });

  _exports.default = _default;
});