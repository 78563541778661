define("adept-iq/services/dwh-service", ["exports", "ember-concurrency", "ember-changeset", "adept-iq/config/api-urls", "moment", "adept-iq/utils/rql-generators", "lodash"], function (_exports, _emberConcurrency, _emberChangeset, _apiUrls, _moment, _rqlGenerators, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    showSearchPopUp: false,
    reconcileDate: null,
    routes: null,
    selectedProvider: null,
    selectedRoute: null,
    selectedRouteInfo: null,
    routeFound: null,
    reconcileDateMinusOne: Ember.computed('reconcileDate', function () {
      if (!this.get('reconcileDate')) {
        return null;
      }

      return (0, _moment.default)(this.get('reconcileDate')).add(-1, 'days').format('YYYY-MM-DD');
    }),
    reconcileDatePlusOne: Ember.computed('reconcileDate', function () {
      if (!this.get('reconcileDate')) {
        return null;
      }

      return (0, _moment.default)(this.get('reconcileDate')).add(1, 'days').format('YYYY-MM-DD');
    }),
    routeOptions: Ember.computed('routes', 'selectedProvider', function () {
      const selectedProviderId = this.get('selectedProvider.id');
      const routes = this.get('routes') || [];
      const routeOptions = routes.filter(route => !selectedProviderId || route.provider === selectedProviderId).map(route => {
        return {
          'id': route.id,
          'routeId': _lodash.default.get(route, 'attributes.routeName')
        };
      });
      return routeOptions;
    }),
    vehicleOptions: Ember.computed('routes', function () {
      const routes = this.get('routes') || [];
      const vehicleSet = routes.reduce((vSet, route) => {
        if (_lodash.default.get(route, 'attributes.vehicleName')) {
          vSet.add(_lodash.default.get(route, 'attributes.vehicleName'));
        }

        return vSet;
      }, new Set());
      const vehicleOptions = Array.from(vehicleSet).map(vehicle => {
        return {
          id: vehicle,
          callsign: vehicle
        };
      });
      return vehicleOptions;
    }),
    driverOptions: Ember.computed('routes', function () {
      const routes = this.get('routes') || [];
      const driverSet = routes.reduce((dSet, route) => {
        if (_lodash.default.get(route, 'attributes.driverId')) {
          dSet.add(_lodash.default.get(route, 'attributes.driverId'));
        }

        return dSet;
      }, new Set());
      const driverOptions = Array.from(driverSet).map(driver => {
        return {
          id: driver,
          badgenumber: driver
        };
      });
      return driverOptions;
    }),
    routeToSelect: Ember.computed('routeOptions', 'routeFound', function () {
      const routeFound = this.get('routeFound');
      if (!routeFound) return;
      const routeOption = this.get('routeOptions').find(route => `${route.id}` === `${routeFound}`);
      return routeOption;
    }),
    isRouteCompleted: Ember.computed('selectedRouteInfo', function () {
      if (this.get('selectedRouteInfo')) {
        // TODO: see if we need to add route complete conditoin check here.
        return true;
      }

      return false;
    }),
    reconTripsWithBreaks: Ember.computed('selectedRouteInfo', function () {
      const trips = (this.get('selectedRouteInfo.trips') || []).map(trip => {
        return {
          iqId: trip.tripId,
          type: 'Trip',
          ...trip
        };
      });
      const breaks = (this.get('selectedRouteInfo.breaks') || []).map(b => {
        return {
          iqId: b.iqid,
          type: 'Break',
          ...b
        };
      });
      return trips.concat(breaks);
    }),
    fetchTripsWithBreaks: (0, _emberConcurrency.task)(function* (id) {
      // const dwhRouteAdapter = this.get('store').adapterFor('dwh-route');
      const routeInfo = yield this.get('store').findRecord('dwh-route', id);
      const validator = this.validate;
      this.set('selectedRouteInfo', new _emberChangeset.default(routeInfo, validator));
    }).restartable(),
    // eslint-disable-next-line no-unused-vars
    validate: _ref => {
      let {
        key,
        newValue,
        oldValue,
        changes,
        content
      } = _ref;
      // TODO: add validations here
      return true;
    },

    closePopups() {
      if (this.get('workspace.stackDepth')) {
        this.get('workspace').popState();
      }
    },

    async findTripsByDateAndTripId(date, tripId) {
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.dwhService.host}/trip`;
      const fields = 'id,attributes.rider.pk,attributes.schedulename,attributes.subscriptionId,attributes.groupId,attributes.rider.id,attributes.rider.attributes.lastName,attributes.rider.attributes.firstName,attributes.tripStatus,attributes.routeId,attributes.routeName';
      const json = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          include_data_source_str: JSON.stringify(['Y']),
          usage_str: JSON.stringify(['165']),
          rql_filter: (0, _rqlGenerators.stringLikeRQL)('tripId', [tripId]),
          fields
        }
      };
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });

      const data = _lodash.default.get(res, 'data', []);

      return data;
    },

    async fetchDwhStops(date) {
      const session = this.get('session');

      const timezone = _moment.default.tz.guess();

      const apiUrl = `${_apiUrls.API.dwhService.host}/stop-point?timezone=${timezone}`;
      const fields = 'id,attributes.iqid,attributes.routename,attributes.stoptype,attributes.requesttime,attributes.promisetime,attributes.passengerfirstname,attributes.passengerlastname,attributes.streetnumber,attributes.streetaddress,attributes.sublocality,attributes.locality,attributes.subregion,attributes.region,attributes.postalcode,attributes.actualodometer,attributes.actualarrivetime,attributes.actualdeparttime,attributes.tripId,attributes.tripStatus,attributes.tripStatusReason,attributes.tripPurpose,attributes.fareCategory,attributes.tripWCCount,attributes.tripPCACount,attributes.tripCompCount,attributes.vehicleId,attributes.driverId';
      const json = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields
        }
      };
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data.map(content => {
        return {
          id: content.id,
          ...content.attributes
        };
      });

      if (data.length === 0) {
        throw new Error('UNABLE TO DOWNLOAD. NO DATA EXISTS FOR THE SELECTED DATE.');
      }

      return data;
    },

    async getDwhRoutesByDate(date) {
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.dwhService.host}/route`;
      const fields = `
      id,
      provider,
      attributes.schedulename,
      attributes.routeName,
      attributes.tenantId,
      attributes.vehicleName,
      attributes.driverId,
      attributes.driverFullName,
      attributes.driverPhoneNumber,
      attributes.plannedStartTime,
      attributes.plannedEndTime,
      attributes.actualstarttime,
      attributes.actualendtime`.replace(/(\r\n|\n|\r|\s)/gm, '');
      const json = {
        data: {
          schedulename_str: JSON.stringify([date]),
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields
        }
      };
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data;
      return data;
    },

    async getDwhRouteById(id) {
      const session = this.get('session');
      const apiUrl = `${_apiUrls.API.dwhService.host}/route`;
      const fields = `
      id,
      provider,
      startGarage,
      endGarage,
      breaks,
      trips,
      attributes.schedulename,
      attributes.routeName,
      attributes.tenantId,
      attributes.vehicleName,
      attributes.driverId,
      attributes.driverFullName,
      attributes.driverPhoneNumber,
      attributes.plannedStartTime,
      attributes.plannedEndTime,
      attributes.actualstarttime,
      attributes.actualendtime`.replace(/(\r\n|\n|\r|\s)/gm, '');
      const json = {
        data: {
          data_source_str: JSON.stringify(['DB']),
          usage_str: JSON.stringify(['165']),
          fields,
          rql_filter: (0, _rqlGenerators.eqRQL)('routeAdeptId', [id])
        }
      };
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        contentType: 'application/json',
        data: json
      });
      const data = res.data && res.data.length > 0 ? res.data.firstObject : null;
      return data;
    },

    async patchDwhStops(date, uploadBinary) {
      const session = this.get('session');

      const timezone = _moment.default.tz.guess();

      const apiUrl = `${_apiUrls.API.dwhService.host}/stop-point/upload?date=${date}&timezone=${timezone}`;
      const res = await this.get('ajax').request(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`,
          'Content-Type': 'text/csv'
        },
        processData: false,
        data: uploadBinary
      });
      return res;
    }

  });

  _exports.default = _default;
});