define("adept-iq/pods/components/header-nav/component", ["exports", "moment", "adept-iq/config/mapped-permIds", "adept-iq/config/environment", "adept-iq/config/api-urls", "adept-iq/utils/help-page-redirect", "adept-iq/pods/components/iq-widgets/vehicle-driver-manager-widget/config"], function (_exports, _moment, _mappedPermIds, _environment, _apiUrls, _helpPageRedirect, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import RoadSupervisorApi from 'adept-iq/classes/road-supervisor-api';
  const showWorkDiagnostics = localStorage.showWorkDiagnostics ? localStorage.showWorkDiagnostics === 'true' : _environment.default.work.showDiagnostics;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    editModal: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    scheduleGeneration: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    stopsReorder: Ember.inject.service(),
    store: Ember.inject.service(),
    booking: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    roadSupervisor: Ember.inject.service(),
    interaction: Ember.inject.service(),
    work: Ember.inject.service(),
    user: Ember.inject.service(),
    systemConfig: Ember.inject.service(),
    classNames: ['row', 'no-gutters', 'header-wrapper', 'header-nav'],
    classNameBindings: ['isLightMode:is-light:is-dark', 'isReordering'],
    ajax: Ember.inject.service(),
    dashboards: null,
    transitionTo: null,
    configureWorkspaceAllowed: false,
    saveWorkspaceAllowed: false,
    saveAsWorkspaceAllowed: false,
    deleteWorkspaceAllowed: false,
    openWorkspaceAllowed: false,
    generateScheduleAllowed: false,
    closeScheduleAllowed: false,
    systemConfigurationAllowed: false,
    resetPasswordAllowed: false,
    aboutAdeptIQAllowed: true,
    helpAdeptIQAllowed: false,
    // using observer instead of computed because of AIQ-2944
    // index.js:163 Uncaught Error: Assertion Failed: You modified "helpAdeptIQAllowed" twice on <adept-iq@component:header-nav::ember332> in a single render.
    helpAdeptIQAllowedObserver: Ember.observer('workspace.{isVehicleDriverDashboard,helpSelectedTab}', 'systemConfig.{showRightPanel,currentComponentPath}', function () {
      const isVehicleDriverDashboard = this.get('workspace.isVehicleDriverDashboard');
      const isRoadSupervisorDashboard = this.get('workspace.isRoadSupervisorDashboard');
      const selectedTab = this.get('workspace.helpSelectedTab');
      const showRightPanel = this.get('systemConfig.showRightPanel');
      const currentComponentPath = this.get('systemConfig.currentComponentPath');
      const SYSTEM_CONFIG_COMPONENTS = ['route-master', 'places'];

      if (showRightPanel) {
        this.set('helpAdeptIQAllowed', SYSTEM_CONFIG_COMPONENTS.includes(currentComponentPath));
      } else {
        this.set('helpAdeptIQAllowed', !isRoadSupervisorDashboard && !(isVehicleDriverDashboard && selectedTab === _config.LOOKUP_TAB));
      }
    }),
    //openTableauReportAllowed: false,
    logoutAllowed: true,
    avlmLite: false,
    clearButtonEnabled: Ember.computed.gt('activeContext.topActiveContext.checkedItems.length', 0),
    isReordering: Ember.computed.alias('stopsReorder.reorderInProgress'),
    isEditing: Ember.computed.alias('workspace.isEditing'),
    isLightMode: Ember.computed.alias('workspace.isLightMode'),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    isNewUser: Ember.computed.alias('session.isNewUser'),
    isHidden: Ember.computed.alias('stopsReorder.isHidden'),
    isScheduler: Ember.computed.readOnly('user.isScheduler'),
    isRoadSupOnTablet: Ember.computed.readOnly('user.isRoadSupOnTablet'),
    isAuthenticatedAndValidUser: Ember.computed('isAuthenticated', 'isNewUser', function () {
      const isValid = this.get('isAuthenticated') && this.get('isNewUser') === 'false';
      return isValid;
    }),
    isBookingWorkspace: Ember.computed.alias('workspace.isBookingDashboard'),
    isDispatchWorkspace: Ember.computed.alias('workspace.isDispatchDashboard'),
    showConfigWorkspace: Ember.computed('workspace.dashboardModel', 'configureWorkspaceAllowed', 'isRoadSupOnTablet', function () {
      const configWorkspaceAllowed = this.get('configureWorkspaceAllowed');
      const isBookingWorkspace = this.get('workspace.isBookingDashboard');
      const isPermissionWorkspace = this.get('workspace.isPermissionsDashboard');
      const isRoadSupOnTablet = this.get('isRoadSupOnTablet');
      return configWorkspaceAllowed && !isBookingWorkspace && !isPermissionWorkspace && !isRoadSupOnTablet;
    }),
    hideTimeLinePicker: Ember.computed.or('workspace.{isScheduleDashboard,isVehicleDriverDashboard,isPlaybackDashboard,isReportsDashboard,isAdhocReportsDashboard,isBookingDashboard,isReconcileDashboard,isPermissionsDashboard}'),
    showWorkDiagnostics,
    activeRoute: Ember.computed.alias('router.currentRouteName'),

    didInsertElement() {
      this._super(...arguments); // exposes console commands `showDiagnostics()` and `hideDiagnostics()`
      // to view queue states; setting persists in `localStorage` for reload


      window.showDiagnostics = () => {
        this.set('showWorkDiagnostics', true);
        localStorage.showWorkDiagnostics = true;
      };

      window.hideDiagnostics = () => {
        this.set('showWorkDiagnostics', false);
        localStorage.showWorkDiagnostics = false;
      };
    },

    configPermissionsChange: Ember.observer('activeRoute', 'workspace.configPermissions', 'permissionLayer.userPermissionHash', 'workspace.dashboardModel', function () {
      this.resetHeaderFunctions();
      this.configureHeaderPermission();
    }),
    oneDayTimelineNotFuture: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate'));
      const endDate = (0, _moment.default)(this.get('workspace.endDate'));
      const currentDate = (0, _moment.default)().startOf('day');

      if (startDate.format('YYYY-MMM-D') !== endDate.format('YYYY-MMM-D')) {
        return false;
      }

      if (endDate.startOf('day').isAfter(currentDate)) {
        return false;
      }

      const routes = _environment.default.APP.avlmLite ? this.get('activeContext.workspaceData.avlm-route') : this.get('activeContext.workspaceData.route'); // Only 1 date is selected by this point
      // make sure that the schedule is not locked already.

      if (routes) {
        if (routes.some(route => {
          return route.get('schedule.locked');
        })) {
          return false;
        }
      }

      return true;
    }),

    init() {
      this._super(...arguments);

      this.configPermissionsChange();
    },

    resetHeaderFunctions() {
      this.set('configureWorkspaceAllowed', false);
      this.set('saveWorkspaceAllowed', false);
      this.set('saveAsWorkspaceAllowed', false);
      this.set('deleteWorkspaceAllowed', false);
      this.set('openWorkspaceAllowed', false);
      this.set('generateScheduleAllowed', false);
      this.set('closeScheduleAllowed', false);
      this.set('systemConfigurationAllowed', false);
      this.set('resetPasswordAllowed', false);
      this.set('aboutAdeptIQAllowed', true);
      this.set('logoutAllowed', true); // this.set('openTableauReportAllowed', false);
    },

    configureHeaderPermission() {
      const configPermissions = this.get('workspace.configPermissions');
      const activeRoute = this.get('activeRoute');
      const userId = this.get('session').data.authenticated.userId ? this.get('session').data.authenticated.userId.replace(/-/g, '_') : '';
      const deletePrivateWorkspacePerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deletePrivateWorkspace);
      const deletePublicWorkspacePerm = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deletePublicWorkspace);
      const dashboard = this.get('workspace.dashboardModel');
      const category = `workspaces-${userId}`;
      const canDeleteAll = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deleteWorkspace); // Only enable headerPermission when route to dashboard.

      const headerPermissionEnabled = _environment.default.APP.test || activeRoute === 'dashboard.index';

      if (headerPermissionEnabled && !Ember.isNone(configPermissions)) {
        const headerFunctions = configPermissions.headerFunctions;
        const timelineFunction = configPermissions.timeline;

        if (!Ember.isNone(timelineFunction)) {
          if (timelineFunction[0] === 'fullAccess') {
            this.set('timeline', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.timeline, null));
          }
        }

        if (!Ember.isNone(headerFunctions)) {
          headerFunctions.forEach(headerFunction => {
            if (headerFunction === 'all') {
              this.set('configureWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.configureWorkspace, null));
              this.set('saveWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.saveWorkspace, _mappedPermIds.default.savePrivateWorkspace]));
              this.set('saveAsWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.saveAsWorkspace, _mappedPermIds.default.savePrivateWorkspace]));
              this.set('deleteWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.deletePublicWorkspace, null));
              this.set('openWorkspaceAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.openWorkspace, null));
              this.set('generateScheduleAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.generateSchedule, null));
              this.set('closeScheduleAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeSchedule, null));
              this.set('systemConfigurationAllowed', true && this.get('permissionLayer').permInUserHash(_mappedPermIds.default.showSystemConfig, null)); // this.set('openTableauReportAllowed', true);

              this.set('aboutAdeptIQAllowed', true);
              this.set('resetPasswordAllowed', false);
              this.set('logoutAllowed', true);
            } else {
              const allowedFunctionName = headerFunction + 'Allowed';
              let permGranted = false;

              switch (headerFunction) {
                case 'openWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.openWorkspace, null);
                  break;

                case 'configureWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.configureWorkspace, null);
                  break;

                case 'saveWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.saveWorkspace, _mappedPermIds.default.savePrivateWorkspace]);
                  break;

                case 'saveAsWorkspace':
                  permGranted = this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.saveAsWorkspace, _mappedPermIds.default.savePrivateWorkspace]);
                  break;

                case 'deleteWorkspace':
                  permGranted = dashboard && (canDeleteAll && dashboard.get('isPublicWorkspace') || dashboard.get('isPublicWorkspace') && deletePublicWorkspacePerm && dashboard.get('userId') === userId || dashboard.get('isPrivateWorkspace') && deletePrivateWorkspacePerm && dashboard.get('category') === category);
                  break;

                case 'generateSchedule':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.generateSchedule, null);
                  break;

                case 'closeSchedule':
                  permGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.closeSchedule, null);
                  break;

                case 'systemConfiguration':
                  permGranted = this.get('permissionLayer').permInUserHash(null, [_mappedPermIds.default.showSystemConfig, _mappedPermIds.default.editPlaces, _mappedPermIds.default.editService, _mappedPermIds.default.manageRouteSchedule, _mappedPermIds.default.editContractManagement, _mappedPermIds.default.manageBlockoutRegions // TODO: temparary disable these two options
                  // mappedPermIds.manageSpeedRegions,
                  // mappedPermIds.manageADABoundaries
                  ]);
                  break;
                // case 'openTableauReport':
                //   permGranted = true;
                //   break;

                case 'resetPassword':
                  permGranted = false;
                  break;

                case 'logout':
                  permGranted = true;
                  break;

                default:
                  permGranted = false;
              }

              this.set(allowedFunctionName, true && permGranted);
            }
          });
        }
      }
    },

    actions: {
      onClearButtonClick() {
        // delay for a second so updates can finish before clearing.
        const checkedItems = this.get('activeContext.topActiveContext.checkedItems'); //NYAAR-19542: Fix Deselect All button in booking widget

        if (this.get('isBookingWorkspace')) {
          checkedItems.forEach(checkedItem => {
            this.get('booking').removeSelectedRider(checkedItem.record);
          });
          this.set('booking.filteredCurrentTrips', []);
          this.set('booking.currentBookings', []);
          this.set('booking.currentSubscriptions', []);
        }

        if (this.get('isDispatchWorkspace') && this.isReordering) {
          this.get('stopsReorder').stopReorder();
        }

        Ember.run.later(this, function () {
          this.get('activeContext.topActiveContext').clearCheckedItems();
        }, 1000);
      },

      onCloseReorder() {
        if (!this.get('isHidden')) {
          this.get('stopsReorder').stopReorder();
        }
      },

      onConfirmReorder() {
        if (!this.get('isHidden')) {
          this.get('stopsReorder').confirmReorder();
        }
      },

      onEditWorkspaceClick(dropdown) {
        const workspace = this.get('workspace');
        dropdown.actions.close();
        if (this.get('isEditing')) return;
        this.get('systemConfig').set('showRightPanel', false);
        const displayName = 'Add Widgets';

        if (workspace.get('dashboard.role') === 'Playback') {
          workspace.pushState('editPlayback', {
            displayName
          }).then(() => {// Q: state pushed?
          }).catch(() => {// denied access
          });
        } else {
          workspace.pushState('editWorkspace', {
            displayName
          }).then(() => {// Q: state pushed?
          }).catch(() => {// denied access
          });
        }
      },

      onSaveWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel');
        const userId = this.get('session').data.authenticated.userId.replace(/-/g, '_');

        if (dashboardModel.get('isDefaultWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Unable to Save',
            tip: 'Unable to Save. You must use the "Save Workspace As" option to save changes made to a default workspace.',
            warning: true,
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        if (dashboardModel.get('isPublicWorkspace') && dashboardModel.get('userId') !== userId) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Save Workspace',
            tip: 'Unable to save. You must use the "Save Workspace As" option to save changes made to a public workspace.',
            warning: true,
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        const dashboardInstance = this.get('workspace.dashboard');
        const includeWidgetStates = true;
        dashboardModel.commitInstance(dashboardInstance, includeWidgetStates);
        /*eslint-disable */

        return dashboardModel.save();
        /*eslint-enable */
      },

      onSaveWorkspaceAsClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('saveWorkspaceAs', {
          displayName: 'Save Workspace As'
        });
      },

      onDeleteWorkspaceClick(dropdown) {
        dropdown.actions.close();
        const dashboardModel = this.get('workspace.dashboardModel');

        if (dashboardModel.get('isDefaultWorkspace') || dashboardModel.get('isPlaybackWorkspace') || dashboardModel.get('isReconcileWorkspace') || dashboardModel.get('isReportsWorkspace') || dashboardModel.get('isBookingWorkspace') || dashboardModel.get('isRiderManagementWorkspace') || dashboardModel.get('isScheduleWorkspace')) {
          const tooltip = this.get('tooltip');
          tooltip.pushConfirmation({
            title: 'Unable to delete',
            warning: true,
            tip: 'Unable to delete. Default workspaces cannot be deleted.',
            primaryActionText: 'Ok',

            primaryAction() {
              tooltip.reset();
            }

          });
          return;
        }

        this.get('workspace').pushState('deleteWorkspace');
      },

      showAdministratorMessage() {
        const tooltip = this.get('tooltip');
        const title = '    ';
        const tip = 'This user is not available in tableau server. Please contact to Administrator';
        tooltip.pushConfirmation({
          tip,
          title,
          primaryActionText: 'Cancel',
          primaryAction: () => {
            return tooltip.reset();
          },
          hasOverlay: true
        });
      },

      RedirectToTableauServer(vizUrl) {
        const tooltip = this.get('tooltip');
        const title = 'Redirecting...';
        const tip = 'You are being re-directed to tableau server';
        tooltip.pushConfirmation({
          tip,
          title,
          primaryActionText: 'OK',
          primaryAction: () => {
            window.open(vizUrl);
            tooltip.reset();
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            return tooltip.reset();
          },
          hasOverlay: true
        });
      },

      handleRedirectionToTableauServer(token) {
        if (token === -1) {
          this.send('showAdministratorMessage');
          return;
        }

        const vizUrl = `${_apiUrls.API.tableauService.host}/trusted/${token}/t/R7/projects`;
        this.send('RedirectToTableauServer', vizUrl);
      },

      // onOpenTableauReportsClick(dropdown) {
      //   dropdown.actions.close();
      //   const session = this.get('session');
      //   const currentUser = localStorage.getItem('currentUser');
      //   if (currentUser) {
      //     this.get('ajax').post(`${API.tableauService.host}/trusted?username=${currentUser}&&target_site=r7`, {
      //       contentType: 'application/json',
      //       headers: {
      //         'Authorization': `Bearer ${session.data.authenticated.token}`
      //       }
      //     }).then((res) => {
      //       this.send('handleRedirectionToTableauServer', res);
      //     }).catch((err) => {
      //       this.send('handleRedirectionToTableauServer', err);
      //     });
      //   }
      // },
      onOpenWorkspaceClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('openWorkspace');
      },

      toggleTimelinePicker() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isEditingTimeline');
      },

      onGenerateScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('scheduleGeneration.task').perform();
      },

      toggleAboutAdeptIQ() {
        const workspace = this.get('workspace');
        workspace.toggleProperty('isShowAbout');
      },

      openHelpPage(dropdown) {
        const workspace = this.get('workspace');
        const systemConfig = this.get('systemConfig');
        let dashboardName = '';
        let selectedTab = ''; //Help based on system configuration : component selection

        if (systemConfig.get('showRightPanel')) {
          dashboardName = 'SystemConfig';
          selectedTab = systemConfig.get('currentComponentPath');
        } else {
          //Help based on workspace selection
          dashboardName = workspace.get('dashboardModel.role');
          selectedTab = workspace.get('helpSelectedTab');
        } // Combine dashboardName and selectedTab only if selectedTab is not empty


        const componentName = selectedTab ? `${dashboardName}_${selectedTab}` : dashboardName;
        (0, _helpPageRedirect.helpRedirect)(componentName);
        dropdown.actions.close();
      },

      transitionToForgotPassword(dropdown, link) {
        dropdown.actions.close();
        this.transitionTo(link);
      },

      transitionToModalAndClose(dropdown, modal) {
        dropdown.actions.close();
        this.transitionTo(`index.modals.${modal}`);
      },

      toggleLayoutType(dropdown) {
        dropdown.actions.close();
        this.toggleProperty('isLightMode');
      },

      onEditApplyCancel() {
        this.get('editModal').cancel();
      },

      onEditApplyCommit() {
        this.get('editModal').commit();
      },

      onCloseScheduleClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('closeSchedule');
      },

      onSystemConfigurationClick(dropdown) {
        dropdown.actions.close();
        this.get('workspace').pushState('editSystemConfiguration', {
          displayName: 'System Configuration'
        });
      },

      onLogout(dropdown) {
        dropdown.actions.close();
        this.onLogout();
      },

      toggleHeaderHelpPage() {
        (0, _helpPageRedirect.helpRedirect)('overview');
      }

    }
  });

  _exports.default = _default;
});