define("adept-iq/services/booking", ["exports", "adept-iq/classes/json-api-serializer/JSONAPISerializer", "adept-iq/utils/unwrapProxy", "adept-iq/utils/zone-validation", "adept-iq/config/api-urls", "moment", "lodash", "adept-iq/classes/rules/itinerary-type", "adept-iq/classes/rules/trip-type", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/config/mapped-permIds", "adept-iq/config/config-object-template", "adept-iq/config/environment", "adept-iq/config/mapped-status", "adept-iq/config/itinerary-action", "ember-concurrency", "adept-iq/models/rider-phone-number"], function (_exports, _JSONAPISerializer, _unwrapProxy, _zoneValidation, _apiUrls, _moment, _lodash, _itineraryType, _tripType, _asyncScheduleOperation, _asyncBookingOperation, _mappedPermIds, _configObjectTemplate, _environment, _mappedStatus, _itineraryAction, _emberConcurrency, _riderPhoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function cleanFieldString(text) {
    return typeof text === 'undefined' ? '' : text;
  }

  const WEATHER_API = `https://api.weather.gov/gridpoints/${_environment.default.weatherAPI.office}/${_environment.default.weatherAPI.gridx},${_environment.default.weatherAPI.gridy}/forecast`;
  const WEATHER_WITHOUT_HOURLY_API = `https://api.weather.gov/gridpoints/${_environment.default.weatherAPI.office}/${_environment.default.weatherAPI.gridx},${_environment.default.weatherAPI.gridy}/forecast/hourly`;

  const generateUUID = () => '_' + Math.random().toString(36).substr(2, 9);

  const TRAVEL_NEEDS_VERIFY = ['pca', 'serviceAnimal'];
  const PCA = 'PCA';
  const COMPANION = 'COMPANION';
  const BUS_TYPE = 'bus';
  const MAX_PHONE_NUMBER_LENGTH = 10;
  const DANCHORSUB_SERVICE_TYPE = 'DAnchorSub';
  const ITP_TRAVEL_MODE = 'itp';
  const PARATRANSIT_TRAVEL_MODE = 'paratransit';
  const CERTIFICATION_LOCATION = 'certification_location';
  const MAX_WALKING_DISTANCE_VALUE = 500;
  const COMPANION_TRAVEL_NEED_TYPE = 'companion';
  const PCA_TRAVEL_NEED_TYPE = 'pca';
  const SERVICE_TYPE_GENERAL = '1';
  const CALLBACK_BOOKINGMODE_TYPE = 'Callback';

  const FARE_CALCULATION_TYPE = _configObjectTemplate.default['config-System_Configuration-travel_need_types'].fields.find(field => field.fieldName === 'fareCalculationType');

  var _default = Ember.Service.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, {
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    apiContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    geocode: Ember.inject.service(),
    workspace: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    fareCalc: Ember.inject.service(),
    map: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    serializer: null,
    activeBooking: null,
    activeDragPlace: null,
    activeLegsInBooking: null,
    segmentStopIdCount: 0,
    legTravelNeedIdCount: 0,
    legIdCount: 0,
    selectedRiders: null,
    // this is used for add/edit bookings
    currentBookings: null,
    //this is to show the bookings in the bookings widget
    currentTrips: null,
    filteredCurrentTrips: null,
    //this is to show the bookings in the bookings widget
    currentSubscriptions: null,
    //this is to show the subscriptions in the bookings widget
    hasMoreExternalRiders: false,
    // this is to plug moreResuts field that comes from nyct when we search
    selectedCompanionCount: 0,
    isOpenErrorModal: false,
    errorMessage: null,
    canSave: true,
    setMarkerPinOff: false,
    riderIdToConditionMap: null,
    temporaryBookingResult: null,
    tempBookingInfo: null,
    tripIds: null,
    enableNewPromiseTimeApi: true,
    travelNeedItems: null,
    anchorTypeOptions: null,
    previousSubscriptionStartDate: null,
    previousSubscriptionEndDate: null,
    previousSubscriptionSuspendStartDate: null,
    previousSubscriptionSuspendEndDate: null,
    addressSelectType: null,
    isReturnTrip: null,
    isNotPromiseTime: null,
    itpSegmentPromiseTimeResults: null,
    passengerRowSelected: false,
    isPassengerRowChecked: false,
    copiedBookingRecord: null,
    isAllLegs: false,
    checkedRiders: null,
    userBookingPermissionDetails: null,
    tripDetails: null,
    allLegs: null,
    segmentStopPhoneNumberIds: null,
    firstLegRequestTime: null,
    overrideEligibility: false,
    maxBookingDate: null,
    travelNeedsOverride: false,
    overrideTravelNeedsDetails: null,
    isRiderPhoneNumberEdited: false,
    bookingMap: null,
    isEditingUnscheduledBooking: false,
    isScheduleOverLap: false,
    availableSchedules: null,
    selectedSchedule: null,
    legSchedule: null,
    bookingEventMsg: null,
    userEventMsg: null,
    skipSetView: false,

    init() {
      this._super(...arguments);

      this.set('currentBookings', []);
      this.set('currentSubscriptions', []);
      this.set('currentTrips', []);
      this.set('filteredCurrentTrips', []);
      this.set('selectedRiders', []);
      this.set('selectedTravelModeMap', {}); // {<legId> : <travelMode>}

      this.set('itpInfo', {
        itpRequestResults: [],
        itpResultType: {}
      });
      this.set('itpSegmentPromiseTimeResults', []);
      this.set('tempBookingInfo', {});
      this.set('anchorTypeOptions', [{
        id: 'pick',
        name: 'Pick'
      }, {
        id: 'drop',
        name: 'Appt'
      }]);
      this.set('tripIds', []);
      this.set('checkedRiders', []);
      this.set('tripDetails', []);
      this.set('overrideTravelNeedsDetails', []);
      this.set('bookingEventMsg', '');
      this.set('userEventMsg', '');
      this.serializer = new _JSONAPISerializer.default(); // Register 'booking' type

      this.serializer.register('booking', {
        id: 'id',
        relationships: {
          legs: {
            type: 'leg'
          },
          providerName: {
            type: 'providerName'
          }
        }
      });
      this.serializer.register('leg', {
        id: 'id',
        relationships: {
          segments: {
            type: 'segment'
          },
          legTravelNeeds: {
            type: 'legTravelNeed'
          },
          serviceWindowName: {
            type: 'serviceWindowName'
          },
          fundingAgencyName: {
            type: 'fundingAgencyName'
          },
          riderPhoneNumber: {
            type: 'riderPhoneNumber'
          },
          tripPurposeName: {
            type: 'tripPurposeName'
          }
        }
      });
      this.serializer.register('segment', {
        id: 'id',
        relationships: {
          pick: {
            type: 'segmentStop'
          },
          drop: {
            type: 'segmentStop'
          },
          leg: {
            type: 'leg'
          },
          fareTypeName: {
            type: 'fareTypeName'
          }
        }
      });
      this.serializer.register('legTravelNeed', {
        id: 'id',
        relationships: {
          travelNeedTypeName: {
            type: 'travelNeedTypeName'
          },
          leg: {
            type: 'leg'
          },
          passengerTypeName: {
            type: 'passengerTypeName'
          }
        }
      });
      this.serializer.register('providerName', {
        id: 'id'
      });
      this.serializer.register('travelNeedTypeName', {
        id: 'id'
      });
      this.serializer.register('passengerTypeName', {
        id: 'id'
      });
      this.serializer.register('serviceWindowName', {
        id: 'id'
      });
      this.serializer.register('fundingAgencyName', {
        id: 'id'
      });
      this.serializer.register('tripPurposeName', {
        id: 'id'
      });
      this.serializer.register('fareTypeName', {
        id: 'id'
      });
      this.serializer.register('segmentStop', {
        id: 'id'
      });
      this.serializer.register('place', {
        id: 'id'
      });
      this.serializer.register('address', {
        id: 'id'
      });
      this.serializer.register('location', {
        id: 'id'
      });
      this.serializer.register('rider', {
        id: 'id'
      });
      this.serializer.register('riderPhoneNumber', {
        id: 'id'
      });
      this.setProperties({
        pickDropCoordinatesOfLegs: [],
        travelNeedItems: [],
        segmentStopPhoneNumberIds: []
      });
      this.configureBookingPermission();
    },

    resetActivityLogMsg() {
      this.set('bookingEventMsg', '');
      this.set('userEventMsg', '');
    },

    appendActivityLogMsg(message) {
      this.set('bookingEventMsg', this.get('bookingEventMsg') + ' ' + message);
      this.set('userEventMsg', this.get('userEventMsg') + ' ' + message);
    },

    isTripOverlapAllowed: Ember.computed('permissionLayer.userPermissionHash', function () {
      return this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideTripOverlap);
    }),
    noOfSelectedRiders: Ember.computed('selectedRiders', 'selectedRiders.[]', function () {
      return this.get('selectedRiders.length');
    }),
    selectedRidersWithConditionalEligibility: Ember.computed('selectedRiders', 'selectedRiders.[]', function () {
      const store = this.get('store');
      return this.get('selectedRiders').filter(selectedRider => {
        const rider = store.peekRecord('rider', selectedRider.id);
        return rider.get('hasConditionalEligibilities');
      });
    }),

    createOverlapActivityLogs(trips) {
      const store = this.get('store');
      const activityLogAdapter = store.adapterFor('activity-log');
      const tripOverlap = this.get('tripOverlap');
      const noOfTrips = trips.length;
      if (Ember.isEmpty(tripOverlap)) return;
      tripOverlap.overlaps.forEach((overlap, index) => {
        if (!overlap.isOverlapping) return;
        const adjustedIndex = index % noOfTrips;
        const requestTime = tripOverlap.requestTimes[adjustedIndex];
        const newTrip = trips.find(trip => {
          const requestTimeString = requestTime ? requestTime.toString() : '';
          return trip.get('segment.leg.requestTime').toString() === requestTimeString;
        });
        const tripId = overlap.trip ? overlap.trip.tripId : '';
        const details = Ember.isPresent(tripId) ? `Overlapped with trip ${tripId} and overlap was overridden. Rider ${newTrip.get('rider.riderId')}.` : `Overlapped with another trip in the same booking and overlap was overridden. Rider ${newTrip.get('rider.riderId')}.`;

        if (Ember.isPresent(newTrip)) {
          const tripInfo = Ember.Object.create({
            context: {
              trip: newTrip
            }
          });
          const tripActivityLog = {
            details,
            activity: 'Override Trip Overlap',
            actualTime: (0, _moment.default)().toDate(),
            type: 'genericLogging',
            userId: this.session.data.authenticated.userId
          };
          const userInfo = {
            content: tripActivityLog
          };
          const addUserActionType = true;
          activityLogAdapter.createUserTripActivity(userInfo, tripInfo, addUserActionType);
        }
      });
      this.set('tripOverlap');
    },

    async overlapCheck(rider, requestTimes, tripIds) {
      const session = this.get('session');
      const json = {
        rider: rider,
        requestTimes
      };
      const tripOverlap = await this.get('ajax').post(_apiUrls.API.schedulingService.host + '/trip-overlap-check', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
      const overlaps = tripOverlap.overlaps || [];
      const noOfTrips = requestTimes.length;
      overlaps.forEach((overlap, index) => {
        const adjustedIndex = index % noOfTrips;
        const trip = overlap.trip;
        const leg = overlap.leg;

        if (overlap.isOverlapping && (Ember.isPresent(leg) || Ember.isPresent(trip) && trip.id !== tripIds[adjustedIndex])) {
          overlap.originalRequestTime = requestTimes[adjustedIndex];
        } else {
          overlap.isOverlapping = false;
        }
      });
      tripOverlap.requestTimes = requestTimes;
      this.set('tripOverlap', tripOverlap);
      return tripOverlap;
    },

    async showOverlapWarningMessage(tripOverlap) {
      const tooltip = this.get('tooltip');
      const items = [];
      const overrideLabel = this.get('isTripOverlapAllowed') ? 'Override' : '';
      let overlapWithLegs = false;
      let proceedOverlap = false;
      tripOverlap.overlaps.forEach(overlap => {
        const requestTime = (0, _moment.default)(overlap.originalRequestTime);

        if (overlap.isOverlapping) {
          if (Ember.isPresent(overlap.trip)) {
            items.push(`Trip with request time ${requestTime.format(_environment.default.dateTimeFormat.timeMoment)} overlaps with an existing trip.`);
          } else {
            overlapWithLegs = true;
            items.push(`Trip with request time ${requestTime.format(_environment.default.dateTimeFormat.timeMoment)} overlaps with another trip within this booking.`);
          }
        }
      });

      if (items.length === 0) {
        proceedOverlap = true;
      }

      if (proceedOverlap) {
        return true;
      }

      const subTips = {
        header: overlapWithLegs ? 'If you proceed, there is a risk that trips will be scheduled out of order.' : '',
        items
      };
      return new Promise(resolve => {
        tooltip.pushConfirmation({
          subTips: subTips,
          title: 'IQ Booking',
          class: 'booking-overlap-message',
          hasOverlay: true,
          primaryActionText: overrideLabel,
          primaryAction: () => {
            this.get('tooltip').reset();
            resolve(true);
          },
          secondaryActionText: 'Cancel',
          secondaryAction: () => {
            this.set('tripOverlap');
            this.get('tooltip').reset();
            resolve(false);
          },
          closeAction: () => {
            resolve(false);
          }
        });
      });
    },

    subscriptionTripMessage() {
      const message = this.get('tripIds.length') > 0 ? 'Are you sure you want to cancel the subscription trip(s)?' : 'Are you sure you want to delete this subscription?';
      return message;
    },

    cancelSubscriptionMessage() {
      const message = this.get('tripIds.length') > 0 ? 'Are you sure you want to cancel the subscription trip(s)?' : 'There are no trips selected, so no trips will be canceled for the subscription.  Are you sure you want to proceed?';
      return message;
    },

    /**
     *
     * @param riderConditionResultMap
     * @param filterFailedConditions : Boolean
     */
    getRiderIdToConditionsMap(riderConditionResultMap, filterFailedConditions) {
      const legs = this.get('activeBooking.legs').toArray();
      const selectedRidersWithConditionalEligibility = this.get('selectedRidersWithConditionalEligibility');
      const conditionsInConfig = this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-eligibility_conditions';
      });
      const allRiderIdToConditionMap = selectedRidersWithConditionalEligibility.reduce((result, r) => {
        const riderId = r.get('id');
        const rider = this.store.peekRecord('rider', riderId);
        const riderEligibilityCategories = rider.get('riderEligibilities') || [];
        const conditions = riderEligibilityCategories.toArray().filter(c => {
          return conditionsInConfig.find(cConfig => {
            return cConfig.get('data').name === c.get('eligibilityCondition.id');
          });
        });
        const conditionMap = [];
        legs.map(leg => {
          const requestTime = leg.get('requestTime');
          const conditionResultMap = riderConditionResultMap[riderId] || {};
          conditions.map(c => {
            const conditionName = c.get('eligibilityCondition.name');
            const conditionResult = conditionResultMap[conditionName];
            const conditionFrom = c.get('from');
            const conditionTo = c.get('to');

            if (requestTime > conditionFrom && requestTime < conditionTo) {
              conditionMap.push({
                conditionName: c.get('eligibilityCondition.displayName'),
                from: c.get('formattedFrom'),
                to: c.get('formattedTo'),
                requestTime: (0, _moment.default)(requestTime).format('MM/DD/YYYY hh:mm A'),
                result: (0, _lodash.isUndefined)(conditionResult) ? '-' : conditionResult
              });
            }
          });
        });
        result[riderId] = conditionMap;
        return result;
      }, {});

      if (!filterFailedConditions) {
        return allRiderIdToConditionMap;
      } //filter failed conditions riders and its result


      const riderIdToConditionMap = (0, _lodash.reduce)(allRiderIdToConditionMap, (result, riderConditionList, riderId) => {
        const failedConditionList = riderConditionList.filter(conditionResult => !conditionResult.result || conditionResult.result === 'Not Evaluated');

        if (failedConditionList.length > 0) {
          result[riderId] = failedConditionList;
        }

        return result;
      }, {});
      return riderIdToConditionMap;
    },

    async prepareBookingRawData(bookingRecord, bookingMode) {
      const legs = await this.prepareLegs(bookingRecord.get('legs'));
      const bookingId = bookingRecord.get('id');
      const providerName = this.prepareProvider(bookingRecord.get('provider'));
      const loggingMeta = bookingRecord.get('loggingMeta');
      const bookingPayload = {
        loggingMeta,
        status: bookingRecord.get('status'),
        bookingMode,
        providerName,
        legs
      };

      if (Ember.isNone(providerName)) {
        delete bookingPayload.providerName;
      }

      if (this.get('tempBookingInfo.tempBookingId')) {
        bookingPayload.temporaryBookingId = this.get('tempBookingInfo.tempBookingId');
      }

      if (bookingId) {
        bookingPayload.id = bookingId;
      }

      return bookingPayload;
    },

    prepareRider(riderRecord) {
      const formattedDob = (0, _moment.default)(riderRecord.get('dateOfBirth')).format('YYYY-MM-DD');
      const middleName = riderRecord.get('middleName');
      return {
        riderId: riderRecord.get('riderId'),
        firstName: riderRecord.get('firstName'),
        middleName: _lodash.default.isEmpty(middleName) ? null : middleName,
        lastName: riderRecord.get('lastName'),
        dateOfBirth: formattedDob,
        notes: riderRecord.get('notes')
      };
    },

    async prepareRiderPhoneNumber(riderRecord) {
      const mainPhoneNumber = riderRecord.get('notificationPhoneNumbers').find(pNo => {
        const type = Ember.isPresent(pNo.type) ? pNo.type.toLowerCase() : '';
        return type === _riderPhoneNumber.MAIN_PHONE_TYPE;
      });

      if (!mainPhoneNumber) {
        return null;
      }

      if (!mainPhoneNumber.description) {
        mainPhoneNumber.description = '# AT TOP OF CLIENT SCREEN';
      }

      const riderPhoneNumber = this.getRiderMainPhoneNumber(riderRecord.id);
      const riderPhoneId = riderPhoneNumber ? riderPhoneNumber.id : null; // if riderRecord doesn't contain the field, if it is empty by default value, we don't want to send these into the patch payload

      const json = {
        id: riderPhoneId,
        areaCode: mainPhoneNumber.areaCode,
        phoneNumber: mainPhoneNumber.phoneNumber,
        extension: _lodash.default.isNil(mainPhoneNumber.extension) || _lodash.default.isEmpty(mainPhoneNumber.extension) ? null : mainPhoneNumber.extension,
        description: mainPhoneNumber.description,
        mobileCarrier: _lodash.default.isNil(mainPhoneNumber.mobileCarrier) || _lodash.default.isEmpty(mainPhoneNumber.mobileCarrier) ? null : mainPhoneNumber.mobileCarrier,
        useForSMSNotifications: _lodash.default.isNil(mainPhoneNumber.useForSmsNotifications) ? null : mainPhoneNumber.useForSmsNotifications,
        useForVoiceNotifications: _lodash.default.isNil(mainPhoneNumber.useForVoiceNotifications) ? null : mainPhoneNumber.useForVoiceNotifications
      };

      const returnValue = _lodash.default.omitBy(json, _lodash.default.isNull);

      return returnValue;
    },

    prepareServiceWindowName(serviceWindow) {
      return {
        id: serviceWindow.get('name')
      };
    },

    prepareFundingAgencyName(fundingAgency) {
      return {
        id: fundingAgency.get('name')
      };
    },

    prepareTripPurposeName(tripPurpose) {
      return {
        id: tripPurpose.get('name')
      };
    },

    prepareFareTypeName() {
      const fareTypes = this.store.peekAll('fare-type');
      const fareType = fareTypes.filter(currFareType => {
        return currFareType.get('id').includes('cash');
      }).get('firstObject');
      const id = fareType ? fareType.get('id') : fareTypes.get('firstObject');
      return {
        id: id
      };
    },

    prepareProvider(providerRecord) {
      if (Ember.isNone(providerRecord.get('name'))) {
        return null;
      }

      return {
        id: providerRecord.get('name')
      };
    },

    prepareLegTravelNeeds(legTravelNeedRecords, legId) {
      const travelNeedsRecords = this.filterPcaAndServiceAnimal(legTravelNeedRecords);
      return travelNeedsRecords.map(legtneedRecord => {
        const legTravelNeedIdCount = this.get('legTravelNeedIdCount') + 1;
        this.set('legTravelNeedIdCount', legTravelNeedIdCount);
        return {
          id: `-${legTravelNeedIdCount}`,
          count: legtneedRecord.get('count'),
          leg: {
            id: `-${legId}`
          },
          travelNeedTypeName: {
            id: legtneedRecord.get('travelNeedType.id')
          },
          passengerTypeName: {
            id: legtneedRecord.get('passengerType.id')
          }
        };
      });
    },

    filterPcaAndServiceAnimal(legTravelNeedRecords) {
      if (!this.isCheckPca()) {
        return legTravelNeedRecords.filter(ltr => {
          return ltr.get('travelNeedType.name').toLowerCase() !== TRAVEL_NEEDS_VERIFY[0];
        });
      }

      if (!this.isCheckServiceAnimal()) {
        return legTravelNeedRecords.filter(ltr => {
          return ltr.get('travelNeedType.name').toLowerCase() !== TRAVEL_NEEDS_VERIFY[1];
        });
      }

      return legTravelNeedRecords;
    },

    createCloneLegTravelNeedRecord(cloneLegTravelNeed) {
      const store = this.get('store');
      const travelNeedType = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('travelNeedType'));
      const passengerType = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('passengerType'));
      const leg = (0, _unwrapProxy.unwrapProxy)(cloneLegTravelNeed.get('leg'));
      const legTravelNeedAttr = cloneLegTravelNeed.toJSON();
      const legTravelNeed = store.createRecord('leg-travel-need', legTravelNeedAttr);
      legTravelNeed.set('travelNeedType', travelNeedType);
      legTravelNeed.set('passengerType', passengerType);
      legTravelNeed.set('leg', leg);
      legTravelNeed.set('isCloned', true);
      return legTravelNeed;
    },

    async prepareLegs(legRecords) {
      const selectedRiders = this.get('selectedRiders');
      const legsForAllRiders = Ember.A();
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());

      for (let i = 0; i < selectedRiders.length; i++) {
        const selectedRider = selectedRiders[i];
        const rider = this.prepareRider(selectedRider);
        const riderPhoneNumber = await this.prepareRiderPhoneNumber(selectedRider);
        const riderLegs = [];
        let legTravelNeedRecords = legRecords.objectAt(0).legTravelNeeds;

        for (let legIndex = 0; legIndex < legRecords.length; legIndex++) {
          if (!this.isAllLegs) {
            legTravelNeedRecords = legRecords.objectAt(legIndex).legTravelNeeds;
          }

          const bigSeatTravelneeds = legTravelNeedRecords.filter(travelNeed => {
            return bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase')) && !travelNeed.get('isCloned') && travelNeed.get('isNew');
          });
          const uniqBigTns = {};
          bigSeatTravelneeds.forEach(t => {
            const type = t.get('travelNeedTypeNameUppercase');

            if (!uniqBigTns[type]) {
              uniqBigTns[type] = this.createCloneLegTravelNeedRecord(t);
              return;
            }

            uniqBigTns[type].set('count', parseInt(t.count, 10) + parseInt(uniqBigTns[type].count, 10));
          });
          const otherTravelNeeds = legTravelNeedRecords.filter(travelNeed => {
            return !bigSeatTRs.includes(travelNeed.get('travelNeedTypeNameUppercase'));
          });
          const uniqBigTRNs = Object.values(uniqBigTns);
          const allTravelNeeds = [...otherTravelNeeds, ...uniqBigTRNs];
          const riderLeg = await this.prepareLeg(legRecords.objectAt(legIndex), allTravelNeeds, rider, riderPhoneNumber);
          riderLegs.push(riderLeg);
        }

        legsForAllRiders.pushObjects(riderLegs);
      }

      return legsForAllRiders;
    },

    async prepareLeg(leg, legTravelNeedRecords, rider, riderPhoneNumber) {
      const legIdCount = this.get('legIdCount') + 1;
      this.set('legIdCount', legIdCount);
      const segments = await this.prepareSegments(leg.get('segments'), legIdCount, leg, rider);
      const segmentList = await Promise.all(segments);
      const serviceWindowName = this.prepareServiceWindowName(leg.get('serviceWindow'));
      const legTravelNeeds = this.prepareLegTravelNeeds(this.filterConsumableTravelNeeds(legTravelNeedRecords), legIdCount, leg);
      const fundingAgencyName = this.prepareFundingAgencyName(leg.get('fundingAgency'));
      const tripPurposeName = this.prepareTripPurposeName(leg.get('tripPurpose'));
      return {
        id: `-${legIdCount}`,
        requestTime: leg.get('requestTime'),
        anchor: leg.get('anchor'),
        purpose: leg.get('purpose'),
        scheduleName: leg.get('scheduleName'),
        segments: segmentList,
        serviceWindowName,
        fundingAgencyName,
        rider,
        riderPhoneNumber,
        legTravelNeeds,
        tripPurposeName
      };
    },

    prepareSegments: async function (segmentRecords, legId, leg, rider) {
      const legSegments = segmentRecords;

      if (this.canCreateItpSegment(legId)) {
        const itpRequestResults = this.get('itpInfo.itpRequestResults');
        const itpRequest = (0, _lodash.find)(itpRequestResults, itpResult => itpResult.legIndex === legId - 1);
        const itinerarySegments = await this.createSegmentsForItp(leg, legId, itpRequest);
        return itinerarySegments.map(async (segment, index) => {
          const pickSegmentStop = await this.prepareSegmentStop(segment.get('pick'), 'pick');
          const dropSegmentStop = await this.prepareSegmentStop(segment.get('drop'), 'drop');
          segment.set('legOrdinal', index + 1);
          return this.prepareSegmentJson(segment, index, pickSegmentStop, dropSegmentStop, leg, legId, _tripType.ITP_TRIP, rider.riderId);
        });
      }

      const firstSegment = legSegments.get('firstObject');
      const lastSegment = legSegments.get('lastObject');
      const pick = await this.prepareSegmentStop(firstSegment.get('pick'), 'pick'); // there is only one segment in every leg

      const drop = await this.prepareSegmentStop(lastSegment.get('drop'), 'drop');
      const segmentData = this.prepareSegmentJson(firstSegment, 0, pick, drop, leg, legId, _tripType.DEMAND_TRIP, rider.riderId);
      return [segmentData];
    },

    prepareSegmentJson(segment, segmentIndex, pick, drop, leg, legId, segmentType, riderId) {
      let segmentId = `-${legId}${segmentIndex + 1}`;
      const legIndex = legId - 1;
      const legID = segmentType === _tripType.ITP_TRIP ? leg.get('id') : `-${legId}`; //NYAAR-19302-if the travel mode is set as itp we have change that to paratransit travel mode

      const travelMode = segment.get('travelMode') && segment.get('travelMode') !== ITP_TRAVEL_MODE ? segment.get('travelMode') : PARATRANSIT_TRAVEL_MODE;

      if (this.get('tempBookingInfo.tempBookingId')) {
        const tempLeg = this.get('tempBookingInfo.tempBookingRecord.legs').find(l => (0, _moment.default)(l.get('requestTime')).isSame((0, _moment.default)(leg.get('requestTime')), 'minute') && l.get('segment.rider.riderId') === riderId);

        if (tempLeg) {
          segmentId = tempLeg.get('segment.id');
        }
      }

      return {
        id: segmentId,
        promiseTime: this.getPromiseTime(legIndex, leg.toJSON()),
        anchor: leg.get('anchor'),
        fare: leg.get('segment.fare'),
        travelMode: travelMode,
        noSharing: leg.get('segment.noSharing'),
        legOrdinal: segment.get('legOrdinal'),
        segmentType: segmentType,
        pick,
        drop,
        leg: {
          id: legID ? legID : `-${legId}`
        },
        fareTypeName: this.prepareFareTypeName()
      };
    },

    /**
     * return true  if  travel model is selected as 'itp' and not a no_solution type
     * @returns {boolean|*}
     */
    canCreateItpSegment(legId) {
      const legIndex = legId - 1;
      const itpRequestResults = this.get('itpInfo.itpRequestResults');
      const itpRequest = (0, _lodash.find)(itpRequestResults, itpResult => itpResult.legIndex === legIndex);
      if (!itpRequest) return false;
      const itpResultType = itpRequest.result.type;
      const selectedTravelMode = this.getSelectedTravelMode(legIndex);
      const isSelectedTravelModeITP = selectedTravelMode === 'itp';
      const isNotNoSolutionType = itpResultType !== _itineraryType.NO_SOLUTION;
      return isSelectedTravelModeITP && isNotNoSolutionType;
    },

    prepareAddress(addressRecord, zoneName) {
      const address = (0, _unwrapProxy.unwrapProxy)(addressRecord);
      if (Ember.isNone(address)) return {};
      const addressJson = address.toJSON();
      return { ...addressJson,
        streetNumber: addressJson.streetNumber,
        zoneName: zoneName ? zoneName.toString() : '1'
      };
    },

    async prepareSegmentStop(segmentStop, type, ignoreSegmentStopCount) {
      const placeRecord = segmentStop.get('place');
      const addressRecord = placeRecord.get('address');
      const zoneName = addressRecord.get('zoneName.name');
      const locationRecord = placeRecord.get('location');
      const place = await this.preparePlace(addressRecord);
      const segmentStopId = this.segmentStopIdCount + 1;

      if (!ignoreSegmentStopCount) {
        this.set('segmentStopIdCount', segmentStopId);
      }

      return {
        id: `-${segmentStopId}`,
        type,
        dwell: segmentStop.get('dwell'),
        place,
        notes: segmentStop.get('notes'),
        plannedEta: segmentStop.get('plannedEta'),
        address: this.prepareAddress(addressRecord, zoneName),
        location: this.prepareLocation(locationRecord)
      };
    },

    prepareLocation(locationRecord) {
      const location = (0, _unwrapProxy.unwrapProxy)(locationRecord);
      if (Ember.isNone(location)) return {};
      const lat = location.get('lat');
      const lng = location.get('lng');
      return {
        lat: lat ? lat.toString() : null,
        lng: lng ? lng.toString() : null,
        geoNode: 0
      };
    },

    async preparePlace(address) {
      const placeQuery = `in(addressId,(${address.get('id')}))&include=address,location`;
      const places = await this.store.query('place', {
        filter: placeQuery
      });
      const placeCategoryTypeName = places.get('firstObject.placeCategoryType.name') ? places.get('firstObject.placeCategoryType.name') : 'passenger';
      return {
        placeCategoryTypeName: placeCategoryTypeName,
        geocodingMethod: 'something'
      };
    },

    async convertBookingRecordToPayloadFormat(bookingRecord) {
      let bookingMode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Immediate';
      const bookingRawData = await this.prepareBookingRawData(bookingRecord, bookingMode);
      this.setLoadAndUnloadTimeInSegmentStop(bookingRawData);
      const jsonapi = this.serializer.serialize('booking', bookingRawData);
      const payload = this.prepareFinalPayloadForBooking(jsonapi);
      return payload;
    },

    resetAllEntityCounts() {
      this.setProperties({
        legIdCount: 0,
        legTravelNeedIdCount: 0,
        segmentStopIdCount: 0
      });
    },

    async saveEditSegmentStop(segmentStop, place, stopType, legNumber, activeBooking, defaultLegSegment) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
      const addressType = stopType === 'pick' ? `${stopType}up` : `${stopType}off`;
      const prevAddress = defaultLegSegment.get(`${stopType}PlaceAddress`);
      const defaultNotes = defaultLegSegment ? defaultLegSegment.get(`${stopType}Notes`) : '';
      let haveUpdates = false;

      if (Ember.isNone(address.get('id'))) {
        const beNomadAddress = address.get('beNomadAddress');
        const lat = location.get('lat');
        const lng = location.get('lng');
        const geoNode = await this.getAddressGeoNode(lat, lng, beNomadAddress, addressType);

        if (geoNode) {
          const zone = store.peekRecord('zone-type', geoNode.zone.zoneName);
          address.set('zName', zone.get('displayName'));
          address.set('zoneName', zone);
        } else {
          address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
        }

        if (Ember.isNone(place.get('id')) && (prevAddress.streetNumber !== address.get('streetNumber') || prevAddress.streetAddress !== address.get('streetAddress') || prevAddress.locality !== address.get('locality'))) {
          place.set('geocodingMethod', '');
          const message = `Leg ${legNumber} ${addressType} address changed from '${prevAddress.streetNumber}, ${prevAddress.streetAddress}, ${prevAddress.locality}' to '${address.get('streetNumber')}, ${address.get('streetAddress')}, ${address.get('locality')}'.`;
          const addressResponse = await addressAdapter.postAddress(address);
          const locationResponse = await locationAdapter.postLocation(location);
          await addressAdapter.postAddressLocation(locationResponse, addressResponse);
          const placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
          const newPlace = await this.store.findRecord('place', placeResponse.data.id);
          segmentStop.set('place', newPlace);
          haveUpdates = true;
          this.appendActivityLogMsg(message);
        } else if (prevAddress.streetNumber !== address.get('streetNumber') || prevAddress.streetAddress !== address.get('streetAddress') || prevAddress.locality !== address.get('locality')) {
          const addressResponse = await addressAdapter.postAddress(address);
          const locationResponse = await locationAdapter.postLocation(location);
          await addressAdapter.postAddressLocation(locationResponse, addressResponse);
          const message = `Leg ${legNumber} ${addressType} address changed from '${prevAddress.streetNumber}, ${prevAddress.streetAddress}, ${prevAddress.locality}' to '${address.get('streetNumber')}, ${address.get('streetAddress')}, ${address.get('locality')}'.`;
          await placeAdapter.patchPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
          haveUpdates = true;
          this.appendActivityLogMsg(message);
        }
      }

      if (segmentStop.get('notes') !== defaultNotes) {
        const message = `Leg ${legNumber} ${addressType} note changed from '${defaultNotes}' to '${segmentStop.get('notes')}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (haveUpdates) segmentStop.save();
    },

    async saveEditSegmentStopPhoneNumber(activeBooking, segmentStop, defaultLegSegment, stopType, legNumber) {
      const addressType = stopType === 'pick' ? `${stopType}up` : `${stopType}off`;
      const phoneNumbers = (0, _unwrapProxy.unwrapProxy)(segmentStop.get('phoneNumbers'));
      const defaultPhoneNumber = defaultLegSegment ? defaultLegSegment.get(`${stopType}PhoneNumberAttr`) : '';
      const prevAreaCode = defaultPhoneNumber.areaCode ? defaultPhoneNumber.areaCode : null;
      const prevPhoneNumber = defaultPhoneNumber.phoneNumber ? defaultPhoneNumber.phoneNumber : null;
      const prevCompletePhone = prevAreaCode ? `${prevAreaCode}-${prevPhoneNumber}` : '';
      phoneNumbers.forEach(async phoneNumber => {
        const message = `Leg ${legNumber} ${addressType} phone number changed from '${prevCompletePhone} to '${phoneNumber.get('areaCode')}-${phoneNumber.get('phoneNumber')}'.`;
        const phoneId = phoneNumber.id;

        if (Ember.isNone(phoneId)) {
          await phoneNumber.save();
          this.appendActivityLogMsg(message);
        } else if (defaultPhoneNumber.areaCode !== phoneNumber.get('areaCode') || defaultPhoneNumber.phoneNumber !== phoneNumber.get('phoneNumber')) {
          await phoneNumber.save();
          this.appendActivityLogMsg(message);
        }
      });
    },

    createTravelNeedsActivityLogs(activeBooking, defaultTravelNeeds, currentLegTravelNeeds, defaultCompanionCount, legNumber) {
      const defaultPCATravelNeed = defaultTravelNeeds.find(dTN => dTN.travelNeedTypeNameUppercase === PCA);
      const currentPCATravelNeed = currentLegTravelNeeds.find(cTN => cTN.travelNeedTypeNameUppercase === PCA);
      const currentCompanionTravelNeedModel = currentLegTravelNeeds.find(cTN => cTN.travelNeedTypeNameUppercase === COMPANION);
      const currentCompanionTravelNeedCount = Ember.isPresent(currentCompanionTravelNeedModel) ? currentCompanionTravelNeedModel.get('count') : null;
      const defaultTNNames = defaultTravelNeeds.map(dTN => dTN.travelNeedTypeNameUppercase).filter(name => name !== COMPANION && name !== PCA);
      const currentTNNames = currentLegTravelNeeds.map(cTN => cTN.travelNeedTypeNameUppercase).filter(name => name !== COMPANION && name !== PCA);
      const travelNeedsDiff = (0, _lodash.difference)(defaultTNNames, currentTNNames);
      const defaultPCACount = Ember.isPresent(defaultPCATravelNeed) ? defaultPCATravelNeed.get('count') : 0;
      const currentPCACount = Ember.isPresent(currentPCATravelNeed) ? currentPCATravelNeed.get('count') : 0;
      const currentCompanionCount = Ember.isPresent(currentCompanionTravelNeedCount) ? currentCompanionTravelNeedCount : 0;
      let haveUpdates = false;

      if (travelNeedsDiff.length > 0 || currentLegTravelNeeds.length - defaultTravelNeeds.length !== 0) {
        const message = `Leg ${legNumber} travel needs changed from '${defaultTNNames.join(',')}' to '${currentTNNames.join(',')}'.`;
        this.appendActivityLogMsg(message);
        haveUpdates = true;
      }

      if (defaultCompanionCount !== currentCompanionCount) {
        const message = `Leg ${legNumber} companion value changed from'${defaultCompanionCount}' to '${currentCompanionCount}'.`;
        this.appendActivityLogMsg(message);
        haveUpdates = true;
      }

      if (defaultPCACount !== currentPCACount) {
        const message = `Leg ${legNumber} PCA value changed from'${defaultPCACount}' to '${currentPCACount}'.`;
        this.appendActivityLogMsg(message);
        haveUpdates = true;
      }

      return haveUpdates;
    },

    async saveEditLeg(activeBooking, leg, defaultBookingData, legIndex) {
      const legNumber = legIndex + 1;
      const defaultLegSegment = defaultBookingData.get('legSegments').objectAt(legIndex);
      const defaultTravelNeeds = defaultBookingData.get('travelNeedMapper')[legIndex] || [];
      const companionCount = defaultBookingData.get('companionCountMapper')[legIndex] || 0;
      const legTravelNeeds = leg.get('legTravelNeeds').toArray();
      const removeLegTravelNeeds = [];
      const currentAnchor = leg.get('anchor') === 'drop' ? 'appt' : leg.get('anchor');
      const defaultRequestTimeMoment = (0, _moment.default)(defaultLegSegment ? defaultLegSegment.requestTime : '');
      const currentRequestTimeMonent = (0, _moment.default)(leg.get('requestTime'));
      const defaultReqDate = defaultRequestTimeMoment.format('MM/DD/YYYY');
      const defaultReqTime = defaultRequestTimeMoment.format('h:mm A');
      const currentReqDate = currentRequestTimeMonent.format('MM/DD/YYYY');
      const currentReqTime = currentRequestTimeMonent.format('h:mm A');
      const defaultServiceType = defaultLegSegment.get('serviceWindow.name');
      const currentServiceType = leg.get('serviceWindow.name');
      const defaultLoadTime = defaultLegSegment.get('loadTime');
      const currentLoadTime = leg.get('loadTime');
      const defaultUnloadTime = defaultLegSegment.get('unloadTime');
      const currentUnloadTime = leg.get('unloadTime');
      let defaultAnchor = defaultLegSegment ? defaultLegSegment.anchor : '';
      let haveUpdates = false;
      haveUpdates = haveUpdates || this.createTravelNeedsActivityLogs(activeBooking, defaultTravelNeeds, legTravelNeeds, companionCount, legNumber);
      defaultAnchor = defaultAnchor === 'drop' ? 'appt' : defaultAnchor;
      defaultTravelNeeds.forEach(removeTravelNeed => {
        if (!legTravelNeeds.find(currTravelNeed => currTravelNeed.id === removeTravelNeed.id)) {
          removeLegTravelNeeds.push(removeTravelNeed);
        }
      });
      legTravelNeeds.forEach(legTravelNeed => {
        const defaultTravelNeed = defaultTravelNeeds.find(travelNeed => travelNeed.travelNeedTypeName === legTravelNeed.travelNeedTypeName);

        if (Ember.isNone(legTravelNeed.count) && this.isCompanionTravelNeed(legTravelNeed)) {
          legTravelNeed.set('count', 0);
        }

        if (Ember.isNone(defaultTravelNeed) || this.isCompanionTravelNeed(legTravelNeed) && companionCount !== legTravelNeed.count) {
          legTravelNeed.save();
        }
      });
      removeLegTravelNeeds.forEach(removeLegTravelNeed => {
        removeLegTravelNeed.deleteRecord();
        removeLegTravelNeed.save();
      });

      if (currentServiceType !== defaultServiceType) {
        const message = `Leg ${legNumber} service type changed from '${defaultServiceType}' to '${currentServiceType}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (currentReqDate !== defaultReqDate) {
        const message = `Leg ${legNumber} date changed from '${defaultReqDate}' to '${currentReqDate}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (currentReqTime !== defaultReqTime) {
        const message = `Leg ${legNumber} time changed from '${defaultReqTime}' to '${currentReqTime}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (currentAnchor !== defaultAnchor) {
        const message = `Leg ${legNumber} request type changed from '${defaultAnchor}' to '${currentAnchor}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (currentLoadTime !== defaultLoadTime) {
        const message = `Leg ${legNumber} load time changed from '${defaultLoadTime}' minutes to '${currentLoadTime}' minutes.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (currentUnloadTime !== defaultUnloadTime) {
        const message = `Leg ${legNumber} unload time changed from '${defaultUnloadTime}' minutes to '${currentUnloadTime}' minutes.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (haveUpdates) await leg.save();
    },

    async createAddress(segmentStop, stopType) {
      const store = this.get('store');
      const locationAdapter = store.adapterFor('location');
      const addressAdapter = store.adapterFor('address');
      const placeAdapter = store.adapterFor('place');
      const place = (0, _unwrapProxy.unwrapProxy)(segmentStop.get('place'));
      const address = (0, _unwrapProxy.unwrapProxy)(place.get('address'));
      const location = (0, _unwrapProxy.unwrapProxy)(place.get('location'));
      const addressType = stopType === 'pick' ? `${stopType}up` : `${stopType}off`;
      const beNomadAddress = address.get('beNomadAddress');
      const lat = location.get('lat');
      const lng = location.get('lng');
      const geoNode = await this.getAddressGeoNode(lat, lng, beNomadAddress, addressType);

      if (geoNode) {
        const zone = store.peekRecord('zone-type', geoNode.zone.zoneName);
        address.set('zName', zone.get('displayName'));
        address.set('zoneName', zone);
      } else {
        address.set('zoneName', store.peekAll('zone-type').get('firstObject'));
      }

      const addressResponse = await addressAdapter.postAddress(address);
      const locationResponse = await locationAdapter.postLocation(location);
      await addressAdapter.postAddressLocation(locationResponse, addressResponse);
      let placeResponse = null;

      if (Ember.isNone(place.get('id'))) {
        place.set('geocodingMethod', '');
        placeResponse = await placeAdapter.postPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
      } else {
        placeResponse = await placeAdapter.patchPlace(place, addressResponse.data.id, locationResponse.data.id, 'passenger');
      }

      return placeResponse;
    },

    async createNewSegmentStop(segmentStop, stopType) {
      const placeResponse = await this.createAddress(segmentStop, stopType);
      const place = await this.store.findRecord('place', placeResponse.data.id);
      segmentStop.set('place', place);
      return await segmentStop.save();
    },

    async createNewSegment(newSegment) {
      const pickSegmentStop = (0, _unwrapProxy.unwrapProxy)(newSegment.get('pick'));
      const dropSegmentStop = (0, _unwrapProxy.unwrapProxy)(newSegment.get('drop'));
      const pickStop = await this.createNewSegmentStop(pickSegmentStop, 'pick');
      const dropStop = await this.createNewSegmentStop(dropSegmentStop, 'drop');
      newSegment.set('pick', pickStop);
      newSegment.set('drop', dropStop);
      return await newSegment.save();
    },

    async createNewleg(activeBooking, newLeg, legNumber) {
      const rider = activeBooking.get('firstLeg.rider.content');
      const message = `Leg ${legNumber} added to booking.`;
      newLeg.set('rider', rider);
      newLeg.set('booking', activeBooking); // get the travel needs for the leg

      const legTravelNeeds = newLeg.get('legTravelNeeds').toArray();
      const leg = await newLeg.save();

      for (const legTravelNeedIndex in legTravelNeeds) {
        if (legTravelNeeds.hasOwnProperty(legTravelNeedIndex)) {
          const lTravelNeed = legTravelNeeds[legTravelNeedIndex];
          lTravelNeed.set('leg', leg);

          if (Ember.isNone(lTravelNeed.count) && this.isCompanionTravelNeed(lTravelNeed)) {
            lTravelNeed.set('count', 0);
          }

          if (this.isCompanionTravelNeed(lTravelNeed) && lTravelNeed.count !== 0) {
            await lTravelNeed.save();
          } else if (!this.isCompanionTravelNeed(lTravelNeed)) {
            await lTravelNeed.save();
          }
        }
      }

      const newSegments = leg.get('segments');
      const newSegmentsPromises = newSegments.map(async newSegment => {
        newSegment.set('leg', leg);
        await this.createNewSegment(newSegment);
      });
      await Promise.all(newSegmentsPromises);
      this.appendActivityLogMsg(message);
      return leg;
    },

    async editRiders(riders, activeBooking, defaultBookingData) {
      const store = this.get('store');
      const riderNotes = defaultBookingData.get('riderNotes');
      const riderPhoneNumbers = defaultBookingData.get('riderPhoneNumbers');
      const riderModels = store.peekAll('rider');

      for (const riderIndex in riders) {
        if (riders.hasOwnProperty(riderIndex)) {
          const rider = riders[riderIndex];
          const riderModel = riderModels.find(riderM => {
            return riderM.get('riderId') === rider.get('id');
          });
          const riderNote = riderNotes[riderIndex];
          const currentNote = rider.get('notes');
          const riderPhoneNumber = riderPhoneNumbers[riderIndex];
          const currentPhoneNumber = `${rider.get('mainAreaCode')}-${rider.get('mainPhoneNumber')}`;

          if (currentNote !== riderNote && Ember.isPresent(riderModel)) {
            const message = `Passenger notes changed from '${riderNote}' to '${currentNote}'.`;
            riderModel.set('notes', currentNote);
            await riderModel.save();
            this.appendActivityLogMsg(message);
          }

          if (currentPhoneNumber !== riderPhoneNumber && Ember.isPresent(riderModel)) {
            const riderPhoneNumberModel = riderModel.get('riderPhoneNumber');
            const message = `Passenger phone number changed from '${riderPhoneNumber}' to '${currentPhoneNumber}'.`;
            riderPhoneNumberModel.set('areaCode', rider.get('mainAreaCode'));
            riderPhoneNumberModel.set('phoneNumber', rider.get('mainPhoneNumber'));
            await riderPhoneNumberModel.save();
            this.appendActivityLogMsg(message);
          }
        }
      }
    },

    async saveEditSegment(activeBooking, segment, defaultLegSegment, legNumber) {
      const defaultFare = defaultLegSegment ? defaultLegSegment.fare : null;
      const currentFare = segment.get('fare');
      const defaultNoSharing = defaultLegSegment ? defaultLegSegment.noSharingText : '';
      const currentNoSharing = segment.get('noSharingText');
      let haveUpdates = false;

      if (defaultFare !== currentFare) {
        const message = `Leg ${legNumber} fare changed from '${defaultFare}' to '${currentFare}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (defaultNoSharing !== currentNoSharing) {
        const message = `Leg ${legNumber} rideshare value changed from '${defaultNoSharing}' to '${currentNoSharing}'.`;
        haveUpdates = true;
        this.appendActivityLogMsg(message);
      }

      if (haveUpdates) await segment.save();
    },

    async saveEditBooking(activeBooking, defaultBookingData) {
      const defaultLegs = defaultBookingData.get('legs');
      const legs = (0, _unwrapProxy.unwrapProxy)(activeBooking.get('legs')).toArray();
      const removeLegs = [];
      this.set('bookingEventMsg', 'Booking modified.\n');
      this.set('userEventMsg', `Changed booking ${activeBooking.get('id')} :`);
      await this.editRiders(defaultBookingData.get('rider').toArray(), activeBooking, defaultBookingData);
      defaultLegs.forEach(removeLeg => {
        if (!legs.find(currLeg => currLeg.id === removeLeg.id)) {
          removeLegs.push(removeLeg);
        }
      });

      for (const legIndex in legs) {
        if (legs.hasOwnProperty(legIndex)) {
          const leg = legs[legIndex];
          const defaultLegSegment = defaultBookingData.get('legSegments').objectAt(legIndex);
          const segments = leg.get('segments').toArray();
          const legNumber = parseInt(legIndex, 10) + 1;
          let isNewLeg = false;

          if (Ember.isNone(leg.id)) {
            isNewLeg = true;
            await this.createNewleg(activeBooking, leg, legNumber);
          }

          for (const segmentIndex in segments) {
            if (segments.hasOwnProperty(segmentIndex)) {
              const segment = segments[segmentIndex];
              const pick = (0, _unwrapProxy.unwrapProxy)(segment.get('pick'));
              const drop = (0, _unwrapProxy.unwrapProxy)(segment.get('drop'));
              const segmentToUse = Ember.isNone(defaultLegSegment) ? segment : defaultLegSegment;
              await this.saveEditSegmentStop(pick, pick.get('place'), 'pick', legNumber, activeBooking, segmentToUse);
              await this.saveEditSegmentStop(drop, drop.get('place'), 'drop', legNumber, activeBooking, segmentToUse);
              await this.saveEditSegmentStopPhoneNumber(activeBooking, pick, defaultLegSegment, 'pick', legNumber);
              await this.saveEditSegmentStopPhoneNumber(activeBooking, drop, defaultLegSegment, 'drop', legNumber);
              await pick.save();
              await drop.save();
              this.saveEditSegment(activeBooking, segment, defaultLegSegment, legNumber);
            }
          }

          if (!isNewLeg) {
            this.saveEditLeg(activeBooking, leg, defaultBookingData, legIndex);
          }
        }
      }

      removeLegs.forEach(removeLeg => {
        const legNumber = removeLeg.get('legOrdinal');
        const message = `Leg ${legNumber} removed from booking.`;
        removeLeg.deleteRecord();
        removeLeg.save();
        this.appendActivityLogMsg(message);
      });
      this.createBookingActivity(activeBooking.get('id'), ['booking', 'user']);
    },

    createBookingActivity(bookingId, loggingTypes) {
      const activityLogAdapter = this.get('store').adapterFor('activity-log');
      const actions = [];

      for (const type of loggingTypes) {
        switch (type) {
          case 'booking':
            actions.push({
              activity: 'Changed',
              loggingType: 'booking',
              details: this.get('bookingEventMsg')
            });
            break;

          case 'user':
            actions.push({
              activity: 'Booking Modified',
              loggingType: 'user',
              details: this.get('userEventMsg')
            });
            break;

          default:
            break;
        }
      }

      activityLogAdapter.createGenericBookingActivity(bookingId, actions);
    },

    saveBooking(isEditMode, defaultBookingData) {
      return new Promise(async (resolve, reject) => {
        const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));

        if (isEditMode) {
          try {
            await this.saveEditBooking(defaultBookingData.get('booking'), defaultBookingData);
          } catch (err) {
            this.resetAllEntityCounts();
            reject(err);
          } finally {
            this.resetAllEntityCounts();
            resolve();
          }
        } else {
          this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-with-subentity', {
            method: 'POST',
            contentType: 'application/json',
            headers: {
              Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
            },
            data: JSON.stringify(payload)
          }).then(res => {
            this.resetAllEntityCounts();
            resolve(res);
          }).catch(err => {
            if (err.status === 400) {
              err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
            }

            this.resetAllEntityCounts();
            reject(err);
          });
        }
      });
    },

    saveBookingStatus(bookingId) {
      let createRefusedBooking = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return new Promise(async (resolve, reject) => {
        const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));
        const url = `${_apiUrls.API.bookingService.host}/booking-with-subentity/${bookingId}${createRefusedBooking ? '?createRefusedBooking=true' : ''}`;
        this.get('ajax').post(url, {
          method: 'PATCH',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
          },
          data: JSON.stringify(payload)
        }).then(res => {
          this.resetAllEntityCounts();
          resolve(res);
        }).catch(err => {
          if (err.status === 400) {
            err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
          }

          this.resetAllEntityCounts();
          reject(err);
        });
      });
    },

    /**
     * update temp booking with  status 'scheduled' and  update  legs and segments..
     * then call schedule service to create trips
     * @returns {Promise<void>}
     */
    acceptBookingAsync: (0, _emberConcurrency.task)(function* (booking, operationType, options) {
      try {
        const {
          isJobSuccess,
          results,
          state
        } = yield this.createBookingAsyncOperation.perform(booking, operationType, options);

        if (!isJobSuccess) {
          this.resetAllEntityCounts(); // Regarding NYAAR-17874 - If SES or SS fails with any other error, the booking status should be set to Failed.

          yield this.asyncOperationStateSuccess(booking, state, results);
        }

        this.resetAllEntityCounts();
        return JSON.parse(results);
      } catch (e) {
        if (e.status === 400) {
          e.message = 'There was a problem with one of the fields. Please check over the form and try again.';
        }

        this.resetAllEntityCounts();
        return Promise.reject(e);
      }
    }).drop(),

    async updateBookingStatus(bookingId, status) {
      const bookingRecord = this.store.peekRecord('booking', bookingId);
      bookingRecord.set('status', status);
      await bookingRecord.save();
    },

    async asyncOperationStateSuccess(booking, state, results) {
      const tripInsertionMessage = 'Insertion failed';
      const failureState = 'failure';
      const bookingId = booking.data.id ? booking.data.id : booking.data.attributes.id || booking.data.attributes.temporaryBookingId;

      if (state === failureState) {
        if ((0, _lodash.isString)(results) && results.includes(tripInsertionMessage)) {
          await this.updateBookingStatus(bookingId, _mappedStatus.convertBookingStatus.unscheduled);
        } else {
          await this.updateBookingStatus(bookingId, _mappedStatus.convertBookingStatus.failed);
        }
      }
    },

    async acceptBooking() {
      const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'));
      const options = {
        operationData: payload,
        outputResult: true
      };
      let results;

      try {
        results = await this.acceptBookingAsync.perform(payload, 'primaryBooking', options);
      } catch (err) {
        throw err;
      }

      return results;
    },

    // todo : remove this logic in the future when the payload is json api complacent
    prepareFinalPayloadForBooking(jsonApi) {
      const included = jsonApi.included.map(entity => {
        if (entity.type === 'segmentStop') {
          const address = entity.attributes.address;
          const place = entity.attributes.place;
          const location = entity.attributes.location;
          delete entity.attributes.address;
          delete entity.attributes.place;
          delete entity.attributes.location;
          return { ...entity,
            relationships: {
              address: {
                data: {
                  type: 'address',
                  ...address
                }
              },
              place: {
                data: {
                  type: 'place',
                  ...place
                }
              },
              location: {
                data: {
                  type: 'location',
                  ...location
                }
              }
            }
          };
        }

        if (entity.type === 'leg') {
          const rider = entity.attributes.rider;
          delete entity.attributes.rider;
          return { ...entity,
            relationships: { ...entity.relationships,
              rider: {
                data: {
                  type: 'rider',
                  ...rider
                }
              }
            }
          };
        }

        return entity;
      });
      return {
        data: jsonApi.data,
        included
      };
    },

    setActiveLegsInBooking(activeLegsInBooking) {
      this.set('activeLegsInBooking', activeLegsInBooking.toArray());
    },

    async fetchAddressForLatLng(latlng) {
      const boundingBox = this.get('map.boundingBox'); // if the point is outside the valid zone polygon
      // return error and exit

      if (!(0, _zoneValidation.insideZone)([latlng.lat, latlng.lng], boundingBox)) {
        //this.get('notifications').warning('Unable to save. Location must be within the agency service boundary.');
        return;
      }

      const geocode = this.get('geocode');
      return geocode.reverseGeocode(latlng);
    },

    updateAddress(record, ttAddress, zone) {
      record.set('alias', cleanFieldString(ttAddress.address.alias));
      record.set('streetNumber', cleanFieldString(ttAddress.address.streetNumber));
      record.set('premise', cleanFieldString(ttAddress.premise));
      record.set('streetAddress', cleanFieldString(ttAddress.address.streetName));
      record.set('locality', cleanFieldString(ttAddress.address.city));
      record.set('subLocality', cleanFieldString(ttAddress.address.city));
      record.set('region', cleanFieldString(ttAddress.address.state));
      record.set('subRegion', cleanFieldString(ttAddress.address.municipality));
      record.set('postalCode', cleanFieldString(ttAddress.address.postalCode));
      record.set('country', cleanFieldString(ttAddress.address.country || ttAddress.address.countryCodeISO3));
      record.set('freeformAddress', cleanFieldString(ttAddress.address.freeformAddress));
      record.set('zName', zone.zoneDisplayName);
      record.set('zoneName.name', zone.zoneName);
    },

    setActiveDragPlace(place) {
      this.set('activeDragPlace', place);
    },

    addSelectedRider(rider) {
      this.get('selectedRiders').pushObject(rider);
    },

    removeSelectedRider(rider) {
      this.get('selectedRiders').removeObject(rider);
    },

    clearSelectedRiders() {
      this.set('selectedRiders', []);
    },

    async fetchRiderForTravelNeeds(externalRider) {
      const riderId = externalRider.get('id');

      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const externalRiderRecord = await this.store.queryRecord('rider-external', riderId);
        return externalRiderRecord;
      } finally {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    async fetchAllSelectedRidersForTravelNeeds() {
      return new Promise((resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const externalRiderPromises = this.get('selectedRiders').map(rider => {
          return this.fetchRiderForTravelNeeds(rider);
        });
        Promise.all(externalRiderPromises).then(response => {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(response);
        }).catch(e => {
          this.get('notifications').warning('error while fetching external rider for travel needs.');
          reject(e);
        });
      });
    },

    async fetchEligibilityForRider(rider) {
      return new Promise((res, rej) => {
        const riderId = rider.get('id');
        const session = this.get('session');
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        this.get('ajax').request(_apiUrls.API.riderManagementService.host + `/nyct-rider-eligibility/${riderId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${session.data.authenticated.token}`
          },
          contentType: 'application/json'
        }).then(riderEligibilityResponse => {
          const riderExternalEligibilityRecord = this.store.createRecord('rider-external-eligibility', { ...riderEligibilityResponse
          });
          rider.set('eligibility', riderExternalEligibilityRecord);
          rider.set('fareCategory', riderExternalEligibilityRecord.get('fareType'));
          riderExternalEligibilityRecord.set('riderExternal', rider);
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          res(riderExternalEligibilityRecord);
        }).catch(error => {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          rej(error);
        });
      });
    },

    // this can be done using store find but the url requires reshaping
    // we are trying to get Eligibilities for each selected Rider and set it to store
    async fetchAndStoreEligibilityForAllSelectedRiders() {
      return new Promise((resolve, reject) => {
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        const eligibilityPromises = this.get('selectedRiders').map(rider => {
          return this.fetchEligibilityForRider(rider);
        });
        Promise.all(eligibilityPromises).then(response => {
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          resolve(response);
        }).catch(e => {
          this.get('notifications').warning('error while fetching eligibility for rider.');
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          reject(e);
        });
      });
    },

    showAddressWarning(addressType) {
      let legType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.showErrorMessage(`Unknown Address. Please enter a valid ${addressType} ${legType} address.`);
    },

    async getAddressGeoNode(lat, lng, beNomadAddress, selectType) {
      try {
        const geoNode = await this.get('geocode').calculateZone(lat, lng);

        if (Ember.isNone(geoNode)) {
          this.showErrorMessage(`Unknown Address. Please enter a valid ${selectType} address.`);
        }

        return geoNode;
      } catch (e) {
        this.showErrorMessage(`Error while getting genode for ${beNomadAddress}.`);
        this.get('workspace').set('isGlobalSpinnerVisible', false);
      }
    },

    async getBookingDetails(bookingId) {
      this.store.findRecord('booking', bookingId);
    },

    createSubscriptionRecord() {
      const currentDate = new Date();
      const exclusion = this.get('store').createRecord('subscription-exclusion');
      const recurrencePattern = this.get('store').createRecord('subscription-recurrence-pattern', {
        type: 'daily',
        recurring: true
      });
      const subscription = this.get('store').createRecord('subscription', {
        requestTime: currentDate,
        exclusions: [exclusion],
        recurrencePatterns: [recurrencePattern]
      });
      return subscription;
    },

    addPcaTravelNeed(pcaTravelNeed, legTravelNeeds) {
      const travelNeedType = this.get('store').peekAll('travel-need-type').find(tntype => {
        return tntype.get('name') === pcaTravelNeed.type;
      });

      if (!Ember.isNone(travelNeedType)) {
        const legTravelNeed = this.get('store').createRecord('leg-travel-need', {
          count: pcaTravelNeed.cnt,
          travelNeedType: travelNeedType,
          passengerType: this.get('store').peekAll('passenger-type').find(ptype => {
            return ptype.name === pcaTravelNeed.type;
          })
        });
        legTravelNeeds.push(legTravelNeed);
      }
    },

    createLegTravelNeeds(riderTravelNeeds) {
      const store = this.get('store');
      const pcaRequiredRiders = this.get('selectedRiders').filter(rider => {
        return rider.get('pcaRequired');
      });
      const legTravelNeeds = [];
      const filterTravelNeeds = riderTravelNeeds.filter(rtneed => {
        return rtneed.type.toUpperCase() !== PCA;
      });
      const pcaTravelNeed = riderTravelNeeds.find(rtneed => {
        return rtneed.type.toUpperCase() === PCA;
      });
      filterTravelNeeds.forEach(riderTravelNeed => {
        const travelNeedType = store.peekAll('travel-need-type').find(tntype => {
          return tntype.get('id') === riderTravelNeed.type;
        });

        if (!Ember.isNone(travelNeedType)) {
          const isConsumable = travelNeedType.get('isConsumable');
          const legTravelNeed = store.createRecord('leg-travel-need', {
            count: isConsumable ? riderTravelNeed.cnt : 0,
            travelNeedType: travelNeedType,
            passengerType: store.peekAll('passenger-type').find(ptype => {
              let ptypeName = 'client';

              if (riderTravelNeed.type.toUpperCase() === PCA) {
                ptypeName = 'pca';
              }

              if (riderTravelNeed.type.toUpperCase() === COMPANION) {
                ptypeName = 'companion';
              }

              return ptype.name === ptypeName;
            })
          });
          legTravelNeeds.push(legTravelNeed);
        }
      });

      if (pcaTravelNeed && pcaRequiredRiders.length) {
        this.addPcaTravelNeed(pcaTravelNeed, legTravelNeeds);
      }

      return legTravelNeeds;
    },

    createLegServiceNeeds(riderServiceNeeds) {
      const store = this.get('store');
      const legServiceNeeds = [];
      riderServiceNeeds.forEach(riderServiceNeed => {
        const serviceNeedType = store.peekAll('service-need-type').find(tntype => {
          return tntype.get('id') === riderServiceNeed;
        });

        if (!Ember.isNone(serviceNeedType)) {
          const legServiceNeed = store.createRecord('leg-service-need', {
            serviceNeedType: serviceNeedType
          });
          legServiceNeeds.push(legServiceNeed);
        }
      });
      return legServiceNeeds;
    },

    createFirstLegSegmentRecord(legTravelNeeds, legServiceNeeds, fareAmount) {
      const store = this.get('store');
      const userBookingPermissionDetails = this.get('userBookingPermissionDetails');
      const isFutureDayBookingPermGranted = userBookingPermissionDetails.isFutureDayBookingPermGranted;
      const serviceWindow = store.peekAll('service-window').find(serviceWindowObject => {
        return serviceWindowObject.get('data.serviceType') === SERVICE_TYPE_GENERAL;
      });
      const fareType = store.peekAll('fare-type').firstObject;
      const date = new Date(new Date()); // if user has future booking permission add one day in leg.requestTime

      if (isFutureDayBookingPermGranted) {
        date.setDate(date.getDate() + 1);
      }

      const pickAddress = store.createRecord('address');
      const pickLocation = store.createRecord('location');
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropAddress = store.createRecord('address');
      const dropLocation = store.createRecord('location');
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const leg = store.createRecord('leg', {
        requestTime: date,
        anchor: 'pick',
        purpose: 'trip',
        notes: '',
        serviceWindow,
        legTravelNeeds,
        legServiceNeeds
      });
      const segment = store.createRecord('segment', {
        promiseTime: date,
        anchor: 'pick',
        fare: fareAmount,
        travelMode: PARATRANSIT_TRAVEL_MODE,
        segmentType: _tripType.DEMAND_TRIP,
        noSharing: 0,
        legOrdinal: 1,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: pickPlace
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: '',
          plannedTime: date,
          onboardCount: 1,
          place: dropPlace
        }),
        fareType: fareType,
        leg: leg
      });
      leg.set('segments', [segment]);
      legTravelNeeds.forEach(legTravelneed => {
        legTravelneed.set('leg', leg);
      });
      legServiceNeeds.forEach(legServiceNeed => {
        legServiceNeed.set('leg', leg);
      });
      return leg;
    },

    async createLegSegmentRecordForSingleBooking() {
      let isReturn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const externalRider = this.get('selectedRiders.firstObject');
      const rider = await this.get('store').peekRecord('rider', externalRider.id);
      await this.fetchRiderForTravelNeeds(externalRider);
      await this.fetchEligibilityForRider(externalRider);
      const riderTravelNeeds = externalRider.get('travelNeeds');
      const riderServiceNeeds = externalRider.get('serviceNeeds') || [];
      const store = this.get('store');
      const legTravelNeeds = this.createLegTravelNeeds(riderTravelNeeds);
      const legServiceNeeds = this.createLegServiceNeeds(riderServiceNeeds);
      const fareAmount = await this.getTotalFareAmount([externalRider], legTravelNeeds);
      const firstLeg = this.createFirstLegSegmentRecord(legTravelNeeds, legServiceNeeds, fareAmount);
      const purpose = isReturn ? 'return' : 'trip';
      firstLeg.set('purpose', purpose);
      const primaryAddressToCreateLeg = rider.riderPlaces.filter(place => place.riderPlaceTypeName === 'primary')[0];
      const placeId = Ember.isPresent(primaryAddressToCreateLeg) ? primaryAddressToCreateLeg.get('place.id') : null;
      const query = 'in(\'id\',(' + placeId + '))&include=address,location';

      if (Ember.isPresent(placeId)) {
        await this.store.query('place', {
          filter: query
        });
      }

      const pickAddress = Ember.isPresent(primaryAddressToCreateLeg) ? primaryAddressToCreateLeg.get('place.address') : store.createRecord('address', {
        lat: 0,
        lng: 0,
        locality: '',
        notes: '',
        postalCode: '',
        premise: '',
        region: '',
        streetAddress: '',
        streetNumber: '',
        country: ''
      });
      const pickLocation = Ember.isPresent(primaryAddressToCreateLeg) ? primaryAddressToCreateLeg.get('place.location') : store.createRecord('location', {
        lat: 0,
        lng: 0
      });

      if (!pickAddress.get('zoneName.name')) {
        this.showErrorMessage('Invalid pick Zone for passengers primary address.');
      }

      if (pickAddress.get('zoneName.name')) {
        // Reg - NYAAR-19619
        firstLeg.set('segments.firstObject.pick.place.address', pickAddress);
        firstLeg.set('segments.firstObject.pick.place.location', pickLocation);
        firstLeg.set('segments.firstObject.pick.notes', pickAddress.get('notes'));
      } else {
        this.showErrorMessage('Primary address of passenger does not exist.');
        const pickAddressRecord = store.createRecord('address');
        const pickLocationRecord = store.createRecord('location');
        firstLeg.set('segments.firstObject.pick.place.address', pickAddressRecord);
        firstLeg.set('segments.firstObject.pick.place.location', pickLocationRecord);
      }

      return [firstLeg];
    },

    calculateAmbulatoryCount(legTravelNeeds) {
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeats = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const totalRidersCount = this.get('selectedRiders').length + this.get('selectedCompanionCount');
      let totalCount = totalRidersCount;
      const pcaRiders = [];
      const wheelchairTravelNeed = legTravelNeeds.find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'WHEELCHAIR';
      });
      const bigSeatTravelneeds = legTravelNeeds.filter(travelNeed => {
        return bigSeats.includes(travelNeed.get('travelNeedTypeNameUppercase'));
      });
      const wcCount = wheelchairTravelNeed ? parseInt(wheelchairTravelNeed.count, 10) : 0;
      const bigSeatsCount = bigSeatTravelneeds.length;
      const totalWCCount = wcCount + bigSeatsCount;

      if (totalWCCount >= totalRidersCount) {
        totalCount = 0;
      } else {
        totalCount = totalCount - totalWCCount;
      }

      const pcaTravelNeed = legTravelNeeds.find(travelNeed => {
        return travelNeed.get('travelNeedTypeNameUppercase') === 'PCA';
      });
      this.get('selectedRiders').forEach(rider => {
        const riderTravelNeeds = rider.get('travelNeeds');
        riderTravelNeeds.forEach(riderTravelNeed => {
          if (riderTravelNeed.type === 'pca') {
            pcaRiders.push(rider);
          }
        });
      });

      if (pcaTravelNeed) {
        totalCount = totalCount + pcaRiders.length;
      }

      return totalCount;
    },

    reGroupLegtravelneedsForGroupBooking() {
      const selectedRiders = this.get('selectedRiders');
      const consumableTravelNeeds = [];
      const nonConsumableTravelNeeds = [];
      const travelNeedTypes = this.store.peekAll('travel-need-type');
      const bigSeatsTN = travelNeedTypes.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) === 2);
      const bigSeatTRs = bigSeatsTN.map(tr => tr.get('name').toUpperCase());
      const travelNeedsOfAllSelectedRiders = selectedRiders.map(r => r.get('travelNeeds')).reduce((t1, t2) => {
        return t1.concat(t2);
      }, []);
      const bigSeatTravelneeds = travelNeedsOfAllSelectedRiders.filter(travelNeed => {
        return bigSeatTRs.includes(travelNeed.type);
      });
      const otherTravelNeeds = travelNeedsOfAllSelectedRiders.filter(travelNeed => {
        return !bigSeatTRs.includes(travelNeed.type);
      }); // NYAAR-7811 For a group booking, if two users have the same non-consumable travel need,
      // that travel need will be displayed as two lines; for example,
      // if two people on the trip need a support cane you would see two 'Support cane' lines

      otherTravelNeeds.forEach(travelNeed => {
        if (travelNeed.cnt === 0) {
          nonConsumableTravelNeeds.push(travelNeed);
        } else {
          consumableTravelNeeds.push(travelNeed);
        }
      });
      const uniqTravelNeeds = (0, _lodash.uniqBy)(consumableTravelNeeds, t => t.type); // Reg - NYAAR-18902 The nonConsumableTravelNeeds should also be uniq ( EX : Lift Required )

      const uniqNonConsumableTravelNeeds = (0, _lodash.uniqBy)(nonConsumableTravelNeeds, t => t.type);
      return [...uniqTravelNeeds, ...bigSeatTravelneeds, ...uniqNonConsumableTravelNeeds];
    },

    reGroupLegServiceNeedsForBooking() {
      const selectedRiders = this.get('selectedRiders');
      const serviceNeedsOfAllSelectedRiders = selectedRiders.map(r => r.get('serviceNeeds')).reduce((t1, t2) => {
        return t1.concat(t2);
      }, []);
      const uniqServiceNeeds = (0, _lodash.uniq)(serviceNeedsOfAllSelectedRiders);
      return [...uniqServiceNeeds];
    },

    async getTotalFareAmount(riders, legTravelNeeds) {
      let override = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      let totalFareAmount = 0;
      const result = await Promise.all(riders.map(async rider => {
        return this.get('fareCalc').calcFareByRiderIdAndTravelNeeds(rider, legTravelNeeds, override);
      }));
      result.map(amount => {
        totalFareAmount += amount;
      });
      return Math.round(totalFareAmount * 1e2) / 1e2; // can't use toFixed(2) which returns a string.
    },

    async createLegSegmentRecordForGroupBooking() {
      let isReturn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const selectedRiders = this.get('selectedRiders');
      const allTravelNeeds = this.reGroupLegtravelneedsForGroupBooking();
      const allServiceNeeds = this.reGroupLegServiceNeedsForBooking();
      const legTravelNeeds = this.createLegTravelNeeds(allTravelNeeds);
      const legServiceNeeds = this.createLegServiceNeeds(allServiceNeeds);
      const totalFareAmount = await this.getTotalFareAmount(selectedRiders, legTravelNeeds);
      const firstLeg = this.createFirstLegSegmentRecord(legTravelNeeds, legServiceNeeds, totalFareAmount);
      const purpose = isReturn ? 'return' : 'trip';
      firstLeg.set('purpose', purpose);
      return [firstLeg];
    },

    async createBookingRecord(isGroupBooking, isSubscriptionClick, originalBookingId) {
      const provider = this.store.peekAll('provider').firstObject; // @TODO booking requires a provider

      const subscription = this.createSubscriptionRecord();
      const legs = isGroupBooking ? await this.createLegSegmentRecordForGroupBooking() : await this.createLegSegmentRecordForSingleBooking();

      if (isSubscriptionClick) {
        const firstLeg = legs.firstObject;
        const subscriptionServiceNeeds = this.createSubscriptionServiceNeedRecord(subscription);
        subscription.set('subscriptionServiceNeeds', subscriptionServiceNeeds);
        firstLeg.legServiceNeeds.forEach(legServiceNeed => {
          if (Ember.isPresent(legServiceNeed)) {
            legServiceNeed.unloadRecord();
          }
        });
      }

      const booking = this.store.createRecord('booking', {
        loggingMeta: {
          originalBooking: originalBookingId
        },
        subscription,
        legs,
        provider
      });
      legs.forEach(leg => {
        leg.set('booking', booking);
      });
      return booking;
    },

    setCompanionCount(selectedCompanionCount) {
      this.set('selectedCompanionCount', selectedCompanionCount || 0);
    },

    showErrorMessage(message) {
      this.set('errorMessage', message);
      this.set('isOpenErrorModal', true);
    },

    setActiveAddress(activeAddress) {
      this.set('activeAddress', activeAddress);
    },

    fetchWeatherForecastData() {
      return this.get('ajax').retryGet(WEATHER_API, WEATHER_WITHOUT_HOURLY_API);
    },

    async preparePayloadForDemandTrip(leg, rider, riderModel) {
      const walkingDistance = this.store.peekRecord('cs-config-item', 'config-Fixed_Route_Engine_Parameters/DefaultMaximumWalkingDistance');
      const maximumWalkingDistanceValue = walkingDistance ? walkingDistance.get('value') : MAX_WALKING_DISTANCE_VALUE;
      const firstSegment = leg.get('segments').firstObject;
      const lastSegment = leg.get('segments').lastObject;
      const pick = await this.prepareSegmentStop(firstSegment.get('pick'), 'pick', true);
      const drop = await this.prepareSegmentStop(lastSegment.get('drop'), 'drop', true);
      const tripAnchor = leg.get('anchor') === 'pick' ? 0 : 1; //TODO about .local() once got NYAAR-11301 fix

      const requestedTime = (0, _moment.default)(leg.get('requestTime')).local().format('YYYY-MM-DDTHH:mm');
      const data = {
        'request': {
          'requestId': generateUUID(),
          'pickCoordinates': {
            'lat': Number(pick.location.lat),
            'lng': Number(pick.location.lng)
          },
          'dropCoordinates': {
            'lat': Number(drop.location.lat),
            'lng': Number(drop.location.lng)
          },
          'tripAnchor': tripAnchor,
          'requestedTime': requestedTime,
          'searchWindow': {
            'start': 0,
            'end': 0
          },
          'clientInformation': {
            'clientId': Number(rider.get('id')),
            'loadTime': leg.get('loadTime'),
            'unloadTime': leg.get('unloadTime'),
            'walkingSpeed': riderModel.get('walkingSpeed'),
            'maximumWalkingDistance': Number(maximumWalkingDistanceValue)
          }
        }
      };
      return data;
    },

    async postScheduleDemandTripForLeg(leg, legIndex) {
      const selectedRider = this.get('selectedRiders').firstObject;
      const riderModel = this.store.peekRecord('rider', selectedRider.id);
      const payload = await this.preparePayloadForDemandTrip(leg, selectedRider, riderModel);
      return this.get('ajax').request(_apiUrls.API.fixedRouteEngineService.host + '/scheduler/demand-trip', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(payload)
      }).then(results => {
        return {
          legIndex,
          result: results[0]
        };
      });
    },

    async createSegmentsForItp(leg, legId, itpRequestResult) {
      const {
        segments: itpItinerarySegments
      } = itpRequestResult.result;
      const segmentPromises = itpItinerarySegments.map((itpItinerarySegment, index) => {
        //except first segment pick notes and last segment drop notes remaining other segments notes are saved with itp segment stop addresses
        const pickNotes = index === 0 ? leg.get('segments.firstObject.pick.notes') : itpItinerarySegment.start.address || itpItinerarySegment.start.description;
        const dropNotes = index === itpItinerarySegments.length - 1 ? leg.get('segments.lastObject.drop.notes') : itpItinerarySegment.end.address || itpItinerarySegment.end.description;
        return this.createLegSegmentForItp(itpItinerarySegment, legId, leg, pickNotes, dropNotes);
      });
      const segmentList = await Promise.all(segmentPromises);
      return segmentList;
    },

    async createLegSegmentForItp(itpSegment, legOrdinal, leg, pickNotes, dropNotes) {
      const store = this.get('store');
      const fareType = store.peekAll('fare-type').firstObject; // create pick place  =>  itinerary pick address

      const pickPlace = await this.createPlaceByLatLng(itpSegment.start.coordinates, 'pickup'); // create drop place

      const dropPlace = await this.createPlaceByLatLng(itpSegment.end.coordinates, 'dropoff');
      const {
        action
      } = _itineraryAction.ITINERARY_ACTION_MAP[itpSegment.action];
      const busDescription = itpSegment.description;
      let travelMode = action;

      if (action === BUS_TYPE) {
        travelMode = `${action} ${busDescription}`;
      }

      const segment = store.createRecord('segment', {
        promiseTime: (0, _moment.default)(itpSegment.start.time).toDate(),
        anchor: 'pick',
        fare: leg.get('segment.fare'),
        travelMode: travelMode,
        segmentType: _tripType.ITP_TRIP,
        noSharing: 0,
        legOrdinal: legOrdinal,
        pick: store.createRecord('segment-stop', {
          type: 'pick',
          notes: pickNotes,
          plannedEta: (0, _moment.default)(itpSegment.start.time).toDate(),
          onboardCount: 1,
          place: pickPlace,
          dwell: leg.get('segment.pick.dwell')
        }),
        drop: store.createRecord('segment-stop', {
          type: 'drop',
          notes: dropNotes,
          plannedEta: (0, _moment.default)(itpSegment.end.time).toDate(),
          onboardCount: 1,
          place: dropPlace,
          dwell: leg.get('segment.drop.dwell')
        }),
        fareType: fareType
      });
      return segment;
    },

    async setPlaceAddressByLatLng(place, latlng, selectType) {
      const bookingAddress = await this.fetchAddressForLatLng(latlng);
      const beNomadAddress = bookingAddress.address.beNomadAddress;
      const geoNode = await this.getAddressGeoNode(latlng.lat, latlng.lng, beNomadAddress, selectType);

      if (!geoNode) {
        return;
      }

      const location = place.get('location');
      location.set('lat', parseFloat(latlng.lat.toFixed(5)));
      location.set('lng', parseFloat(latlng.lng.toFixed(5)));
      const placeAddress = place.get('address');
      this.updateAddress(placeAddress, bookingAddress, geoNode.zone);
    },

    async setPlaceAddressByLatLngforITPInDB(location, address, latlng, selectType) {
      const bookingAddress = await this.fetchAddressForLatLng(latlng);
      const beNomadAddress = bookingAddress.address.beNomadAddress;
      const geoNode = await this.getAddressGeoNode(latlng.lat, latlng.lng, beNomadAddress, selectType);

      if (!geoNode) {
        return;
      }

      location.set('lat', parseFloat(latlng.lat.toFixed(5)));
      location.set('lng', parseFloat(latlng.lng.toFixed(5))); //Getting the zone type using the id present in the geoNode and setting it to address

      const zoneType = this.get('store').peekRecord('zone-type', geoNode.zone.zoneName);
      address.set('zoneName', zoneType);
      this.updateAddress(address, bookingAddress, geoNode.zone);
    },

    async fetchOrCreatePlaceByLatLngForITPInDB(latlng, selectType) {
      const store = this.get('store');
      const locationsQuery = `and(eq(lat,'${latlng.lat}'),eq(lng,'${latlng.lng}'))`;
      const locations = await store.query('location', {
        filter: locationsQuery
      });

      if (!Ember.isEmpty(locations)) {
        const placeQuery = `in(locationId,('${locations.firstObject.id}'))`;
        const places = await store.query('place', {
          filter: placeQuery
        }); //fix: NYAAR-17285, As place is empty we are creating place record to get the place ID.

        if (Ember.isEmpty(places)) {
          const address = store.createRecord('address');
          await this.setPlaceAddressByLatLngforITPInDB(locations.firstObject, address, latlng, selectType);
          const dbAddress = await address.save();
          return this.createPlaceRecord(locations.firstObject, dbAddress);
        }

        return places.firstObject;
      }

      const address = store.createRecord('address');
      const location = store.createRecord('location');
      await this.setPlaceAddressByLatLngforITPInDB(location, address, latlng, selectType);
      const dbLocation = await location.save();
      const dbAddress = await address.save();
      return this.createPlaceRecord(dbLocation, dbAddress);
    },

    createPlaceRecord(location, address) {
      const store = this.get('store');
      const placeCategoryType = store.peekRecord('place-category-type', 'passenger');
      const place = store.createRecord('place', {
        address: address,
        location: location,
        placeCategoryType: placeCategoryType,
        geocodingMethod: 'something'
      });
      return place.save();
    },

    addBookingTripForITPSegment: (0, _emberConcurrency.task)(function* (schedule, operation, options) {
      let asyncResult;

      try {
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(schedule, operation, options);

        if (!isJobSuccess) {
          return;
        }

        asyncResult = JSON.parse(results);
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          this.get('notifications').warning('Adding trip for ITP Segment Failed');
          throw e;
        }
      }

      return Promise.resolve(asyncResult);
    }).drop(),

    async getPromiseTimeForITPSegments(segments, booking) {
      const bookingId = booking.get('id');
      const tripsByBookingID = `and(eq(schedule.type,'booking'),in(booking,('${bookingId}')))&include=schedule,route,pick,drop`;
      const trips = await this.get('store').query('trip', {
        filter: tripsByBookingID
      });
      let schedule;

      if (!Ember.isEmpty(trips)) {
        schedule = trips.firstObject.get('schedule');
      }

      if (Ember.isEmpty(schedule)) {
        return;
      }

      const operationData = this.prepareAddBookingTripPayload(segments, booking, schedule);
      const options = {
        operationData,
        outputResult: true
      };
      const results = await this.addBookingTripForITPSegment.perform(schedule, 'addBookingTrip', options);
      return results;
    },

    prepareAddBookingTripPayload(segments, booking, schedule) {
      const {
        tripDataList,
        segmentStopIdMapList
      } = this.getTripDataPayloadInfo(booking, segments, schedule);
      const included = this.getIncludedPayload(booking, segments, segmentStopIdMapList);
      return {
        data: tripDataList,
        included
      };
    },

    /**
     * return tripdata payload for addBookingTrip schedule operation
     * @param booking
     * @param segments
     * @param schedule
     * @returns {{tripDataList: Array, segmentStopIdMapList: Array}}
     */
    getTripDataPayloadInfo(booking, segments, schedule) {
      const segmentStopIdMapList = [];
      const tripDataList = [];
      let segmentStopId = 0;

      for (const segment of segments) {
        const pickSegmentStop = segment.get('pick');
        const dropSegmentStop = segment.get('drop');
        const pickTripStopId = `-${++segmentStopId}`;
        const dropTripStopId = `-${++segmentStopId}`;
        const pickSegmentStopIdMap = {
          segmentId: segment.get('id'),
          tripStopId: pickTripStopId,
          segmentStopId: pickSegmentStop.get('id')
        };
        segmentStopIdMapList.push(pickSegmentStopIdMap);
        const dropSegmentStopIdMap = {
          segmentId: segment.get('id'),
          tripStopId: dropTripStopId,
          segmentStopId: dropSegmentStop.get('id')
        };
        segmentStopIdMapList.push(dropSegmentStopIdMap);
        const tripData = {
          type: 'trip',
          attributes: {
            status: 'waitlisted'
          },
          relationships: {
            pick: {
              data: {
                type: 'tripStop',
                id: pickTripStopId
              }
            },
            drop: {
              data: {
                type: 'tripStop',
                id: dropTripStopId
              }
            },
            schedule: {
              data: {
                type: 'schedule',
                id: schedule.get('id')
              }
            },
            segment: {
              data: {
                type: 'segment',
                id: segment.get('id')
              }
            },
            booking: {
              data: {
                type: 'booking',
                id: booking.get('id')
              }
            }
          }
        };

        if (booking.get('subscription.id')) {
          tripData.relationships.subscription = {
            data: {
              type: 'subscription',
              id: booking.get('subscription.id')
            }
          };
        }

        tripDataList.push(tripData);
      }

      return {
        tripDataList,
        segmentStopIdMapList
      };
    },

    /**
     * return included payload for addBookingTrip schedule operation
     * @param booking
     * @param segments
     * @param segmentStopIdMapList
     * @returns {{type: string, id: *}[]}
     */
    getIncludedPayload(booking, segments, segmentStopIdMapList) {
      const included = [{
        type: 'booking',
        id: booking.get('id')
      }];

      if (booking.get('subscription.id')) {
        included.push({
          type: 'subscription',
          id: booking.get('subscription.id')
        });
      }

      for (const segment of segments) {
        const segmentStopIdList = segmentStopIdMapList.filter(segmentStopIdInfo => {
          return segmentStopIdInfo.segmentId === segment.get('id');
        });
        const segmentInfo = {
          type: 'segment',
          id: segment.get('id')
        };
        included.push(segmentInfo);
        segmentStopIdList.forEach(segmentStopIdInfo => {
          const segmentStop = {
            type: 'segmentStop',
            id: segmentStopIdInfo.segmentStopId
          };
          included.push(segmentStop);
          const tripStop = {
            type: 'tripStop',
            id: segmentStopIdInfo.tripStopId,
            attributes: {
              plannedRouteOrdinal: 1
            },
            relationships: {
              segmentStop: {
                data: {
                  type: 'segmentStop',
                  id: segmentStopIdInfo.segmentStopId
                }
              }
            }
          };
          included.push(tripStop);
        });
      }

      return included;
    },

    /**
     *
     * @param latlng {lat : <value> , lng : <value>}
     * @returns {Promise<void>}
     */
    async createPlaceByLatLng(latlng, selectType) {
      const store = this.get('store');
      const zoneType = store.createRecord('zone-type');
      const address = store.createRecord('address', {
        zoneName: zoneType
      });
      const location = store.createRecord('location');
      const place = store.createRecord('place', {
        address: address,
        location: location
      });
      await this.setPlaceAddressByLatLng(place, latlng, selectType);
      return place;
    },

    setItpInfo(itpRequestResults, itpResultType, hasOverrideITP) {
      if (!itpRequestResults || !itpResultType) {
        return this.set('itpInfo', {
          itpRequestResults: [],
          itpResultType: {},
          hasOverrideITP: null
        });
      }

      this.set('itpInfo', {
        itpRequestResults,
        itpResultType,
        hasOverrideITP
      });
    },

    setSelectedTravelMode(legIndex, travelMode) {
      if (legIndex < 0 || !travelMode) {
        return this.set('selectedTravelModeMap', {});
      }

      this.set(`selectedTravelModeMap.${legIndex}`, travelMode);
    },

    setSelectedTravelModeInBooking(legIndex, travelMode) {
      if (legIndex < 0 || !travelMode) {
        return;
      }

      const legs = this.get('activeBooking').legs;
      const concernLeg = legs.objectAt(legIndex);
      concernLeg.set('segments.firstObject.travelMode', travelMode);
    },

    getSelectedTravelMode(legIndex) {
      const legTravelModeMap = this.get('selectedTravelModeMap');
      return legTravelModeMap[legIndex];
    },

    /**
     * save temporary booking with status 'requested' and adding booking to trips.
     * this api will give  promise time  for each legs
     * @returns {Promise<*>}
     */
    async saveBookingWithPromiseTime(bookingMode) {
      const payload = await this.convertBookingRecordToPayloadFormat(this.get('activeBooking'), bookingMode);
      return await this.get('ajax').post(_apiUrls.API.bookingService.host + '/booking-trip', {
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${this.get('session.data.authenticated.token')}`
        },
        data: JSON.stringify(payload)
      }).then(result => {
        this.resetAllEntityCounts();
        return result;
      }).catch(err => {
        if (err.status === 400) {
          err.message = 'There was a problem with one of the fields. Please check over the form and try again.';
        }

        this.resetAllEntityCounts();
        return Promise.reject(err);
      });
    },

    // call promise time  async operation  and  store the promise time result
    async processPromiseTimeAsyncOperation(temporaryBookingResult, temporarybookingRecord) {
      const promiseTimeFailureReasonList = {};
      const {
        promiseTimeAsyncOperationInfoArray
      } = temporaryBookingResult;
      let bookingId = null; // checking the result because temporaryBookingResult gets different format depending on same day or not

      if (temporaryBookingResult.constructor === Array) {
        bookingId = temporaryBookingResult.firstObject.relationships ? temporaryBookingResult.firstObject.relationships.booking.data.id : temporaryBookingResult.firstObject.booking;
      } else {
        bookingId = temporaryBookingResult.booking || temporaryBookingResult.bookingId;
      }

      this.set('tempBookingInfo.tempBookingId', bookingId);
      this.set('tempBookingInfo.tempBookingRecord', temporarybookingRecord.firstObject); // if there is no schedule, promiseTimeAsyncOperationInfo will be empty
      // continue booking even promise time retrieval fails

      if (!promiseTimeAsyncOperationInfoArray) {
        promiseTimeFailureReasonList.ALL = {
          reason: 'Please contact administrator. "There is no schedule for the request date"'
        };
        this.set('tempBookingInfo.promiseTimeFailureReasonResult', promiseTimeFailureReasonList);
        return;
      }

      await this.get('store').queryRecord('booking', bookingId);
      let promiseTimeResultIncludedData = [];
      let index = 1;
      const promiseTimeResultDataList = {};

      for (const promiseTimeAsyncOperationInfo of promiseTimeAsyncOperationInfoArray) {
        const {
          isJobSuccess,
          results
        } = await this.callScheduleAsyncOperation(promiseTimeAsyncOperationInfo);

        if (isJobSuccess) {
          const promiseTimeResult = JSON.parse(results);
          const promiseTimeResultData = promiseTimeResult.data || [];
          const included = promiseTimeResult.included || []; // eslint-disable-next-line no-loop-func

          promiseTimeResultData.forEach(promiseTimeResponse => {
            promiseTimeResultDataList[`'${index}'`] = promiseTimeResponse;

            if (Ember.isPresent(promiseTimeResponse.attributes.reason)) {
              promiseTimeFailureReasonList[`'${index}'`] = {
                reason: promiseTimeResponse.attributes.reason
              };
            }

            index = index + 1;
          });
          promiseTimeResultIncludedData = [...promiseTimeResultIncludedData, ...included];
        } else {
          console.warn('failed to fetch promise time ' + results);
          const startIndex = results.indexOf('{') - 1;
          const endIndex = results.indexOf('}') + 1;
          const resultJSONStr = startIndex >= 0 && endIndex >= 0 ? results.substring(startIndex, endIndex) : '{}';
          const resultObject = JSON.parse(resultJSONStr);
          const tempMessage = Ember.isPresent(resultObject.statusCode) ? `"${resultObject.statusCode} - ${resultObject.reason}"` : results;
          const errorMessage = `Please contact administrator for failure reason. ${tempMessage}`;
          promiseTimeFailureReasonList.ALL = {
            reason: errorMessage
          };
        }
      }

      this.set('tempBookingInfo.promiseTimeResult', promiseTimeResultDataList);
      this.set('tempBookingInfo.promiseTimeFailureReasonResult', promiseTimeFailureReasonList);
      this.set('tempBookingInfo.promiseTimeResultIncludedData', promiseTimeResultIncludedData);
    },

    async callScheduleAsyncOperation(asyncOperationInfo) {
      return await this.get('asyncOperationState').perform(asyncOperationInfo, true);
    },

    setTemporaryBookingInfo(tempBookingId, promiseTimeResult) {
      this.set('tempBookingInfo.tempBookingId', tempBookingId);
      this.set('tempBookingInfo.promiseTimeResult', promiseTimeResult);
    },

    /**
     *  we can get promise time if these  4  condition  is passed
     * @param legIndex
     * @returns {*|boolean}
     */
    canGetPromiseTime(legIndex) {
      return this.get('enableNewPromiseTimeApi') && this.get('tempBookingInfo.tempBookingId') && this.hasPromiseTime(legIndex);
    },

    /**
     * get promise time  if paratransit or itp(feeder mode)  is selected..
     * otherwise return leg requested time
     * @param legIndex
     * @param leg  ==> leg object is plain javascript object
     * @returns {*}
     */
    getPromiseTime(legIndex, leg) {
      // if promise time  cannot be selected , get default   leg request time
      if (!this.canGetPromiseTime(legIndex)) {
        return leg.requestTime;
      }

      const promiseTimeData = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeData[`'${legIndex + 1}'`];

      if (Ember.isPresent(promiseTimeResult.attributes)) {
        return promiseTimeResult.attributes.promiseTime;
      }

      return null;
    },

    /**
     * @param legIndex
     * @returns {boolean}
     */
    hasPromiseTimeforItpsegment(legIndex) {
      const promiseTimeResultList = this.get('itpSegmentPromiseTimeResults.data') || [];
      const promiseTimeResult = promiseTimeResultList[legIndex];
      return !!promiseTimeResult;
    },

    /**
     *  we can get promise time if these conditions passed
     * @param legIndex
     * @returns {*|boolean}
     */
    canGetPromiseTimeforItpsegment(legIndex) {
      return this.get('enableNewPromiseTimeApi') && this.hasPromiseTimeforItpsegment(legIndex);
    },

    /**
     * get promise time  for paratransit segments when itp(feeder mode)  is selected..
     * otherwise return leg requested time
     * @param legIndex
     * @param leg  ==> leg object is plain javascript object
     * @returns {*}
     */
    getPromiseTimeforItpsegment(legIndex) {
      const promiseTimeData = this.get('itpSegmentPromiseTimeResults.data') || [];
      const promiseTimeResult = promiseTimeData[legIndex];
      return (0, _moment.default)(promiseTimeResult.attributes.promiseTime).format('hh:mm A');
    },

    /**
     *
     * @param legIndex
     * @param leg ==> leg object is plain javascript object
     * @returns {*}
     */
    getArrivalTime(legIndex) {
      // if promise time  cannot be selected , get default   leg request time
      if (!this.canGetPromiseTime(legIndex)) {
        return '';
      }

      const promiseTimeData = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeData[`'${legIndex + 1}'`];
      const dropTripStopId = promiseTimeResult.relationships.drop.data.id;
      const promiseTimeIncludedList = this.get('tempBookingInfo.promiseTimeResultIncludedData') || [];
      const dropTripStop = promiseTimeIncludedList.find(data => data.id === dropTripStopId);
      return dropTripStop && dropTripStop.attributes.plannedEta || '';
    },

    /**
     * @param legIndex
     * @returns {boolean}
     */
    hasPromiseTime(legIndex) {
      const promiseTimeResultList = this.get('tempBookingInfo.promiseTimeResult') || {};
      const promiseTimeResult = promiseTimeResultList[`'${legIndex + 1}'`];
      return !!promiseTimeResult;
    },

    /**
     * @param legIndex
     * @returns {result}
     */
    promiseTimeFailureReason(legIndex) {
      const promiseTimeResultList = this.get('tempBookingInfo.promiseTimeFailureReasonResult') || {};
      const promiseTimeResult = legIndex === 'ALL' ? promiseTimeResultList[legIndex] : promiseTimeResultList[`'${legIndex + 1}'`];
      return Ember.isPresent(promiseTimeResult) ? promiseTimeResult.reason : null;
    },

    // check temp booking is created
    hasTempBooking() {
      const tempBookingId = this.get('tempBookingInfo.tempBookingId');
      return !!tempBookingId;
    },

    async deleteTemporaryBooking(action, isEditMode) {
      try {
        this.get('workspace').set('isGlobalSpinnerVisible', true);

        if (this.get('enableNewPromiseTimeApi')) {
          const bookingID = this.get('tempBookingInfo.tempBookingId');
          const tripCancelJobs = await this.deleteBooking(bookingID, action, isEditMode);
          this.set('tempBookingInfo', {});
          this.get('workspace').set('isGlobalSpinnerVisible', false);
          return tripCancelJobs ? tripCancelJobs.tripCancelJobs : [];
        }
      } catch (error) {
        this.get('workspace').set('isGlobalSpinnerVisible', false);
        this.get('notifications').warning(`ERROR: ${error.message} WHILE DELETING TEMPORARY DATA`);
      }
    },

    async deleteBooking(bookingID, action, isEditMode, riderId) {
      const session = this.get('session');
      const doCancelBooking = !isEditMode;
      const loggingMeta = {
        refusedByRider: riderId
      };
      let json, resource;

      switch (action) {
        case 'x':
          json = JSON.stringify({
            doCancelBooking,
            loggingMeta,
            doCancelTrip: true
          });
          resource = 'booking-with-subentity';
          break;

        case 'editUnscheduled':
          json = JSON.stringify({
            doCancelBooking,
            loggingMeta,
            doCancelTrip: false
          });
          resource = 'booking-with-subentity';
          break;

        case 'refuse':
        case 'taxi':
        case 'edit':
          json = JSON.stringify({
            loggingMeta,
            doCancelTrip: true
          });
          resource = 'booking-with-subentity';
          break;

        default:
          json = JSON.stringify({
            loggingMeta,
            doCancel: true
          });
          resource = 'booking';
      }

      return await this.get('ajax').post(_apiUrls.API.bookingService.host + `/${resource}/` + bookingID, {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    async fetchSubscriptionTrips(subscriptionId) {
      const session = this.get('session');
      return await this.get('ajax').request(_apiUrls.API.bookingService.host + '/subscription/' + subscriptionId + '/trips', {
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${session.data.authenticated.token}`
        }
      });
    },

    setCheckedSubscriptionTrips(trips) {
      const tripIds = trips.map(trip => trip.id);
      this.set('tripIds', tripIds);
    },

    isCheckPca() {
      const travelNeeds = this.get('travelNeedItems');
      return travelNeeds.toArray().some(tr => TRAVEL_NEEDS_VERIFY[0].includes(tr.get('name')));
    },

    isCheckServiceAnimal() {
      const travelNeeds = this.get('travelNeedItems');
      return travelNeeds.toArray().some(tr => TRAVEL_NEEDS_VERIFY[1].includes(tr.get('name')));
    },

    /**
     * check whether taxi mode selected
     * @returns {*|boolean}
     */
    isTaxiModeSelected() {
      const selectedTravelModeMap = this.get('selectedTravelModeMap');
      const selectedTravelModes = Object.values(selectedTravelModeMap) || [];
      const isTaxiSelected = selectedTravelModes.length === 0 ? false : selectedTravelModes.every(travelMode => {
        return travelMode.toLowerCase() === 'taxi';
      });
      return isTaxiSelected;
    },

    /**
     * check either  paratransit or  itp with feeder mode selected
     * @returns {*|boolean}
     */
    hasParaTransitOrItpFeederModeSelected(legIndex) {
      const {
        itpResultType
      } = this.get('itpInfo');
      const selectedTravelModeMap = this.get('selectedTravelModeMap');
      const selectedTravelModes = Object.values(selectedTravelModeMap) || [];
      const isParatransitSelected = selectedTravelModes.length === 0 ? false : selectedTravelModes[legIndex].toLowerCase() === 'paratransit';
      const isItpFeederResultType = itpResultType[legIndex] === _itineraryType.FEEDER;
      return isParatransitSelected || isItpFeederResultType;
    },

    // NYAAR-13442 Booking: Consumable travel needs with count=0 should not be saved in leg travel needs tables
    filterConsumableTravelNeeds(legTravelNeedRecords) {
      const travelNeedItems = this.get('travelNeedItems');
      const consumableTravelNeeds = travelNeedItems.toArray().filter(tr => parseInt(tr.get('vehicleCapacityCount'), 10) > 0);
      const TR_N_TO_NOT_SAVE_IF_COUNT_ZERO = consumableTravelNeeds.map(tr => tr.get('name').toUpperCase());
      return legTravelNeedRecords.filter(lt => !(TR_N_TO_NOT_SAVE_IF_COUNT_ZERO.indexOf(lt.get('travelNeedTypeNameUppercase')) > -1 && parseInt(lt.get('count'), 10) === 0));
    },

    // NYAAR-13459 Booking - Upon editing a booking, service mode is getting changed
    getActiveBookingTravelModeMap() {
      const legTravelModeMap = {};
      this.get('activeBooking.legs').forEach((leg, index) => {
        legTravelModeMap[index] = leg.get('segments.firstObject.travelMode');
      });
      return legTravelModeMap;
    },

    isNumber(val) {
      const regEx = /^[0-9]*$/;
      const valString = typeof val === 'string' ? val : val.toString();

      if (valString && valString.match(regEx)) {
        return true;
      }

      return false;
    },

    getSysConfigCompanionFare() {
      const configItemsFareCategories = this.getSystemConfigFareCategories();
      const guestFareCategory = configItemsFareCategories.find(fareCategory => fareCategory.get('displayName') === 'Guest');
      const guestFare = Ember.isPresent(guestFareCategory) ? guestFareCategory.value.Amount || guestFareCategory.value.amount : 0;
      return Number(guestFare);
    },

    getSysConfigRegularFare() {
      const configItemsFareCategories = this.getSystemConfigFareCategories();
      const regularFareCategory = configItemsFareCategories.find(fareCategory => fareCategory.get('displayName') === 'REG');
      const regularFare = Ember.isPresent(regularFareCategory) ? regularFareCategory.value.Amount || regularFareCategory.value.amount : 0;
      return Number(regularFare);
    },

    getSystemConfigFareCategories() {
      return this.store.peekAll('cs-config-item').filter(configItem => {
        return configItem.category === 'config-System_Configuration-fare_categories';
      });
    },

    getAllowFundingAgency() {
      const allowFundingAgency = this.store.peekRecord('cs-config-item', 'config-Book_Trips/allowFundingAgency');
      return Ember.isPresent(allowFundingAgency) ? allowFundingAgency.get('value') : true;
    },

    getFutureBookingTimeline() {
      const futureBookingTimelineConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/Future_Booking_Timeline');
      const futureBookingTimelineString = Ember.isPresent(futureBookingTimelineConfig) ? futureBookingTimelineConfig.get('value') : '0';
      const futureBookingTimeline = parseInt(futureBookingTimelineString, 10);
      return !(0, _lodash.isNaN)(futureBookingTimeline) ? futureBookingTimeline : 0;
    },

    getEligibilityExpirationCheckDays() {
      const eligibilityExpirationCheckDaysConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/eligibilityExpirationCheckDays');
      const eligibilityExpirationCheckDaysString = Ember.isPresent(eligibilityExpirationCheckDaysConfig) ? eligibilityExpirationCheckDaysConfig.get('value') : '60';
      const eligibilityExpirationCheckDays = parseInt(eligibilityExpirationCheckDaysString, 10);
      return !(0, _lodash.isNaN)(eligibilityExpirationCheckDays) ? eligibilityExpirationCheckDays : 60;
    },

    getBookingMode() {
      const bookingModeConfig = this.store.peekRecord('cs-config-item', 'config-Book_Trips/bookingMode');
      return Ember.isPresent(bookingModeConfig) ? bookingModeConfig.get('value') : 'Immediate';
    },

    isCallBackBookingModeType() {
      return this.getBookingMode() === CALLBACK_BOOKINGMODE_TYPE;
    },

    setEmptyAddressLocation(place) {
      const address = this.store.createRecord('address');
      const location = this.store.createRecord('location');
      place.set('address', address);
      place.set('location', location);
    },

    validateDateNotToBePastDate(date, currentDate, prevDate, subscriptionType, dateType) {
      if ((0, _moment.default)(date).isBefore(currentDate) && !(0, _moment.default)(prevDate).isSame((0, _moment.default)(date))) {
        this.showErrorMessage(`Please select valid ${subscriptionType} ${dateType} date.`);
        return false;
      }

      return true;
    },

    async deleteTrips(bookingId) {
      const tripsByBookingID = `in(booking,('${bookingId}'))&include=schedule,route,pick,drop`;
      const trips = await this.get('store').query('trip', {
        filter: tripsByBookingID
      });

      if (!Ember.isEmpty(trips)) {
        for (const trip of trips.toArray()) {
          await trip.destroyRecord();
        }
      }
    },

    fetchAndReturnRiders(riderIds) {
      return new Promise((res, rej) => {
        const riderPromises = riderIds.map(rId => {
          return this.store.findRecord('rider', rId);
        });
        Promise.all(riderPromises).then(riderRecords => {
          res(riderRecords);
        }).catch(e => {
          rej(e);
        });
      });
    },

    fetchExternalRidersAndSetToSelectedRiders(externalRiderIds, setRiders) {
      return new Promise((res, rej) => {
        const riderExternalPromises = externalRiderIds.map(rId => {
          return this.store.findRecord('rider-external', rId);
        });
        Promise.all(riderExternalPromises).then(riderExternalRecords => {
          if (setRiders) {
            riderExternalRecords.forEach(rExt => {
              this.addSelectedRider(rExt);
            });
          }

          res(riderExternalRecords);
        }).catch(e => {
          rej(e);
        });
      });
    },

    async saveLegServiceNeed(booking) {
      const selectedRiders = this.get('selectedRiders');
      const activeBookingLegs = this.get('activeBooking.legs');
      const bookingLegs = booking.firstObject.get('legs').toArray().sortBy('id');
      const totalActiveLegsLength = activeBookingLegs.length;
      let legServiceNeeds = []; //for group booking we create added legs each for selected riders,so we have to create same no of leg-service-needs as legs have

      activeBookingLegs.forEach((leg, legIndex) => {
        let legDataIndex = legIndex;
        selectedRiders.forEach(() => {
          const legData = bookingLegs[legDataIndex];
          const prevLegServiceNeeds = leg.legServiceNeeds.toArray();
          const currentLegServiceNeeds = prevLegServiceNeeds.map(serviceNeed => {
            const legServiceNeed = this.get('store').createRecord('leg-service-need', {
              serviceNeedType: serviceNeed.serviceNeedType,
              leg: legData
            });
            return legServiceNeed;
          });
          legServiceNeeds.push(...currentLegServiceNeeds);
          legDataIndex = legDataIndex + totalActiveLegsLength;
        });
      });
      legServiceNeeds = (0, _lodash.flatten)(legServiceNeeds).toArray(); //NYAAR-18800 - If the serviceNeedType is null we don't want to save the serviceNeeds

      const filteredLegServiceNeeds = legServiceNeeds.filter(legServiceNeed => legServiceNeed.isNew && !Ember.isEmpty(legServiceNeed.serviceNeedType));
      await filteredLegServiceNeeds.reduce(async (acu, legServiceNeed) => {
        return acu.then(() => {
          return legServiceNeed.save();
        });
      }, Promise.resolve());
    },

    /**
    * this function is used in new booking form
    * @param {*} booking(array of objects)
    */
    async saveSegmentStopPhoneNumber(booking) {
      const bookingLegs = booking.firstObject.get('legs').toArray();
      const legs = this.get('activeBooking.legs').toArray();
      bookingLegs.forEach(async (leg, index) => {
        const activeLeg = legs[index];

        if (activeLeg) {
          const activeSegments = activeLeg.get('segments');
          const legSegments = leg.get('segments').toArray();
          await activeSegments.reduce((acu, segment, segmentIndex) => {
            return acu.then(async () => {
              const legSegment = legSegments[segmentIndex];
              const pickSegmentStop = legSegment.get('stops.firstObject');
              const dropSegmentStop = legSegment.get('stops.lastObject');
              const pickPhoneNumber = segment.get('pick.phoneNumbers.firstObject');
              const dropPhoneNumber = segment.get('drop.phoneNumbers.firstObject'); //remove if the pick phone number have empty record

              this.removeUnSavedPhoneNumbers(pickPhoneNumber, segment, 'pick'); //remove if the drop phone number have empty record

              this.removeUnSavedPhoneNumbers(dropPhoneNumber, segment, 'drop'); //save pick Phone number

              await this.saveNumberAndSetSegmentStop(pickPhoneNumber, segment, pickSegmentStop, 'pick'); //save drop Phone number

              await this.saveNumberAndSetSegmentStop(dropPhoneNumber, segment, dropSegmentStop, 'drop'); //delete Record if it does'nt have a pick phone number

              await this.deleteSegmentStopPhoneNumber(pickPhoneNumber); //delete Record if it does'nt have drop a phone number

              await this.deleteSegmentStopPhoneNumber(dropPhoneNumber);
            });
          }, Promise.resolve());
        }
      });
    },

    /**
    * this function is used for adjust legOrdinals in ITP leg
    */
    adjustItpBookingLegOrdinals() {
      const legs = this.get('activeBooking.legs').toArray();
      legs.forEach(leg => {
        const legSegments = leg.get('segments').toArray();
        legSegments.forEach((segment, index) => {
          const curLegOrdinal = segment.get('legOrdinal');
          segment.set('legOrdinal', curLegOrdinal + index);
        });
      });
    },

    //check the phone number length is required length
    checkPhoneNumberLength(phoneNumber) {
      return phoneNumber && phoneNumber.get('fullPhoneNumber').length === MAX_PHONE_NUMBER_LENGTH;
    },

    //remove the unsaved records
    removeUnSavedPhoneNumbers(phoneNumber, activeLegSegment, numberType) {
      if (phoneNumber && !this.checkPhoneNumberLength(phoneNumber) && !phoneNumber.get('id')) {
        activeLegSegment.get(`${numberType}.phoneNumbers`).removeObject(phoneNumber);
      }
    },

    async saveNumberAndSetSegmentStop(phoneNumber, activeLegSegment, segmentStop, numberType) {
      if (this.checkPhoneNumberLength(phoneNumber)) {
        phoneNumber.set('segmentStop', segmentStop);
        await phoneNumber.save(); // Phone No field in Trips details is not getting retained after we click on Tick icon. the segmentStopPhoneNumberIds used for edit and update booking.

        this.get('segmentStopPhoneNumberIds').pushObject(phoneNumber);
        const activeLegPhoneNumber = activeLegSegment.get(`${numberType}.phoneNumbers`);
        activeLegPhoneNumber.pushObject(phoneNumber);
        activeLegSegment.set(`${numberType}.phoneNumbers'`, activeLegPhoneNumber);
      }
    },

    //segment stop phone number delete
    async deleteSegmentStopPhoneNumber(phoneNumber) {
      if (phoneNumber && !this.checkPhoneNumberLength(phoneNumber) && phoneNumber.get('id')) {
        await phoneNumber.destroyRecord();
      }
    },

    createSubscriptionServiceNeedRecord(subscription) {
      const externalRider = this.get('selectedRiders.firstObject');
      const riderServiceNeeds = externalRider.get('serviceNeeds');
      const store = this.get('store');
      const subscriptionServiceNeeds = [];
      riderServiceNeeds.forEach(riderServiceNeed => {
        const serviceNeedType = store.peekAll('service-need-type').find(tntype => {
          return tntype.get('id') === riderServiceNeed;
        });

        if (!Ember.isNone(serviceNeedType)) {
          const subscriptionServiceNeed = store.createRecord('subscription-service-need', {
            serviceNeedType: serviceNeedType,
            subscription
          });
          subscriptionServiceNeeds.push(subscriptionServiceNeed);
        }
      });
      return subscriptionServiceNeeds;
    },

    // if all legs is checked in travel need section, set load and unload to all segments from first leg segment
    setLoadAndUnloadTimeInSegmentStop(bookingRawData) {
      if (this.isAllLegs && bookingRawData.legs.length > 1) {
        const loadTime = bookingRawData.legs.firstObject.segments.firstObject.pick.dwell;
        const unloadTime = bookingRawData.legs.firstObject.segments.firstObject.drop.dwell;
        bookingRawData.legs.forEach(leg => {
          const pickSegment = leg.segments.firstObject.pick;
          const dropSegment = leg.segments.firstObject.drop;
          pickSegment.dwell = loadTime;
          dropSegment.dwell = unloadTime;
        });
      }
    },

    createCopyBookingTravelNeeds(legTravelNeeds, newLeg) {
      const newLegTravelNeeds = legTravelNeeds.map(legTravelNeed => {
        const travelNeedType = (0, _unwrapProxy.unwrapProxy)(legTravelNeed.get('travelNeedType'));
        const passengerType = (0, _unwrapProxy.unwrapProxy)(legTravelNeed.get('passengerType'));
        const legTravelNeedAttr = legTravelNeed.toJSON();
        const travelNeedRecord = this.get('store').createRecord('leg-travel-need', legTravelNeedAttr);
        travelNeedRecord.set('travelNeedType', travelNeedType);
        travelNeedRecord.set('passengerType', passengerType);
        travelNeedRecord.set('leg', newLeg);
        return travelNeedRecord;
      });
      return newLegTravelNeeds;
    },

    setLoadAndUnloadTimeForLeg(leg) {
      const selectedRiders = this.get('selectedRiders');
      const loadTime = leg.get('loadTime');
      const unloadTime = leg.get('unloadTime');
      selectedRiders.forEach(rider => {
        rider.set('loadTime', loadTime);
        rider.set('unloadTime', unloadTime);
      });
    },

    // set pick and drop segment stop phone number if the copied segment stop have phone number
    setPickAndDropPhoneNumber(leg, newLeg) {
      const pickSegmentStopPhoneNumberId = leg.get('segments.firstObject.pick.phoneNumbers.firstObject.id');
      const dropSegmentStopPhoneNumberId = leg.get('segments.firstObject.drop.phoneNumbers.firstObject.id');

      if (pickSegmentStopPhoneNumberId) {
        const pickPhoneNumber = this.get('store').peekRecord('segment-stop-phone-number', pickSegmentStopPhoneNumberId);
        const pickPhoneNumberAttr = pickPhoneNumber.toJSON();
        const pickNumber = this.get('store').createRecord('segment-stop-phone-number', pickPhoneNumberAttr);
        newLeg.get('segments.firstObject.pick.phoneNumbers').pushObject(pickNumber);
      }

      if (dropSegmentStopPhoneNumberId) {
        const dropPhoneNumber = this.get('store').peekRecord('segment-stop-phone-number', dropSegmentStopPhoneNumberId);
        const dropPhoneNumberAttr = dropPhoneNumber.toJSON();
        const dropNumber = this.get('store').createRecord('segment-stop-phone-number', dropPhoneNumberAttr);
        newLeg.get('segments.firstObject.drop.phoneNumbers').pushObject(dropNumber);
      }
    },

    /**
     *NYAAR-18906 In Copy Booking, on changing the pick and drop address and on not saving booking the addresses from the existing bookings remove from widget
     * @param {*Object} leg
     * @param {*Object} newLeg
     */
    setPickAndDropAddressAndLocation(leg, newLeg) {
      const store = this.get('store');
      const legPickAddress = leg.get('segments.firstObject.pick.place.address');
      const legDropAddress = leg.get('segments.firstObject.drop.place.address');
      const legPickLocation = leg.get('segments.firstObject.pick.place.location');
      const legDropLocation = leg.get('segments.firstObject.drop.place.location');
      const pickAddressAttr = (0, _unwrapProxy.unwrapProxy)(legPickAddress).toJSON();
      const dropAddressAttr = (0, _unwrapProxy.unwrapProxy)(legDropAddress).toJSON();
      const pickLocationAttr = (0, _unwrapProxy.unwrapProxy)(legPickLocation).toJSON();
      const dropLocationAttr = (0, _unwrapProxy.unwrapProxy)(legDropLocation).toJSON();
      const pickAddress = store.createRecord('address', pickAddressAttr);
      const dropAddress = store.createRecord('address', dropAddressAttr);
      const pickLocation = store.createRecord('location', pickLocationAttr);
      const dropLocation = store.createRecord('location', dropLocationAttr);
      const pickPlace = store.createRecord('place', {
        address: pickAddress,
        location: pickLocation
      });
      const dropPlace = store.createRecord('place', {
        address: dropAddress,
        location: dropLocation
      });
      const pickZoneName = leg.get('segments.firstObject.pick.place.address.zoneName');
      const dropZoneName = leg.get('segments.lastObject.drop.place.address.zoneName');
      const pickZoneType = store.createRecord('zone-type');
      const dropZoneType = store.createRecord('zone-type');
      pickZoneType.set('name', pickZoneType.get('name'));
      dropZoneType.set('name', dropZoneType.get('name'));
      pickAddress.set('zoneName', pickZoneName);
      dropAddress.set('zoneName', dropZoneName);
      newLeg.set('segments.firstObject.pick.place', pickPlace);
      newLeg.set('segments.firstObject.drop.place', dropPlace);
      newLeg.set('segments.firstObject.pick.place.address', pickAddress);
      newLeg.set('segments.firstObject.drop.place.address', dropAddress);
    },

    cloneAddress(address) {
      const clonedAddress = this.store.createRecord('address', address.toJSON());
      clonedAddress.set('zoneName', address.get('zoneName'));
      return clonedAddress;
    },

    cloneLocation(location) {
      const clonedLocation = this.store.createRecord('location', location.toJSON());
      return clonedLocation;
    },

    clonePlace(place) {
      const clonedPlace = this.store.createRecord('place', place.toJSON());
      const clonedAddress = this.cloneAddress((0, _unwrapProxy.unwrapProxy)(place.get('address')));
      const clonedLocation = this.cloneLocation((0, _unwrapProxy.unwrapProxy)(place.get('location')));
      clonedPlace.set('address', clonedAddress);
      clonedPlace.set('location', clonedLocation);
      clonedPlace.set('placeCategoryType', place.get('placeCategoryType'));
      return clonedPlace;
    },

    cloneSegmentStopPhoneNumbers(segmentStopPhoneNumbers, newSegmentStop) {
      // segmentStop: belongsTo('segment-stop'),
      const phoneNumbers = segmentStopPhoneNumbers.map(phoneNumber => {
        const clonedSegStopPhoneNumber = this.store.createRecord('segment-stop-phone-number', phoneNumber.toJSON());
        clonedSegStopPhoneNumber.set('segmentStop', newSegmentStop);
        return clonedSegStopPhoneNumber;
      });
      return phoneNumbers;
    },

    cloneSegmentStop(segmentStop) {
      const clonedSegmentStop = this.store.createRecord('segment-stop', segmentStop.toJSON());
      const clonedPlace = this.clonePlace((0, _unwrapProxy.unwrapProxy)(segmentStop.get('place')));
      const clonedSegStopPhoneNumbers = this.cloneSegmentStopPhoneNumbers((0, _unwrapProxy.unwrapProxy)(segmentStop.get('phoneNumbers')));
      clonedSegmentStop.set('place', clonedPlace);
      clonedSegmentStop.set('phoneNumbers', clonedSegStopPhoneNumbers);
      return clonedSegmentStop;
    },

    cloneSegment(segment, newLegRecord) {
      const clonedSegment = this.store.createRecord('segment', segment.toJSON());
      const clonedPick = this.cloneSegmentStop((0, _unwrapProxy.unwrapProxy)(segment.get('pick')));
      const clonedDrop = this.cloneSegmentStop((0, _unwrapProxy.unwrapProxy)(segment.get('drop')));
      clonedSegment.set('pick', clonedPick);
      clonedSegment.set('drop', clonedDrop);
      clonedSegment.set('fareType', (0, _unwrapProxy.unwrapProxy)(segment.get('fareType')));
      clonedSegment.set('fareCategory', (0, _unwrapProxy.unwrapProxy)(segment.get('fareCategory')));
      clonedSegment.set('leg', newLegRecord);
      return clonedSegment;
    },

    cloneLegTravelNeed(legTravelNeedRecord, newLegRecord) {
      const clonedLegTravelNeed = this.store.createRecord('leg-travel-need', legTravelNeedRecord.toJSON());
      clonedLegTravelNeed.set('leg', newLegRecord);
      clonedLegTravelNeed.set('travelNeedType', legTravelNeedRecord.get('travelNeedType'));
      clonedLegTravelNeed.set('passengerType', legTravelNeedRecord.get('passengerType'));
      return clonedLegTravelNeed;
    },

    cloneLegServiceNeed(legServiceNeedRecord, newLegRecord) {
      const clonedLegServiceNeed = this.store.createRecord('leg-service-need', legServiceNeedRecord.toJSON());
      clonedLegServiceNeed.set('leg', newLegRecord);
      clonedLegServiceNeed.set('serviceNeedType', legServiceNeedRecord.get('serviceNeedType'));
      return clonedLegServiceNeed;
    },

    cloneLeg(leg, newbookingRecord) {
      const clonedLeg = this.store.createRecord('leg', leg.toJSON());
      const clonedSegments = leg.get('segments').toArray().map(segment => this.cloneSegment(segment, clonedLeg));
      const clonedLegTravelNeeds = leg.get('legTravelNeeds').toArray().map(legTravelNeed => this.cloneLegTravelNeed(legTravelNeed, clonedLeg));
      const clonedLegServiceNeeds = leg.get('legServiceNeeds').toArray().map(legServiceNeed => this.cloneLegServiceNeed(legServiceNeed, clonedLeg));
      clonedLeg.set('booking', newbookingRecord);
      clonedLeg.set('serviceWindow', leg.get('serviceWindow'));
      clonedLeg.set('fundingAgency', leg.get('fundingAgency'));
      clonedLeg.set('segments', clonedSegments);
      clonedLeg.set('legTravelNeeds', clonedLegTravelNeeds);
      clonedLeg.set('legServiceNeeds', clonedLegServiceNeeds);
      clonedLeg.set('tripPurpose', leg.get('tripPurpose')); // set anchor and request time null for cloned leg

      clonedLeg.set('anchor', null);
      clonedLeg.set('requestTime', null);
      return clonedLeg;
    },

    cloneLegs(legs, newbookingRecored) {
      return legs.map(leg => this.cloneLeg(leg, newbookingRecored));
    },

    setCopiedBookingLegs(leg, newLeg) {
      const pickText = 'segments.firstObject.pick';
      const dropText = 'segments.lastObject.drop';
      const pickNotes = leg.get(`${pickText}.notes`);
      const dropNotes = leg.get(`${dropText}.notes`);
      const serviceWindow = leg.get('serviceWindow');
      const legTravelNeeds = leg.get('legTravelNeeds');
      const noSharing = leg.get('segment.noSharing');
      const fundingAgency = leg.get('fundingAgency');
      const tripPurpose = leg.get('tripPurpose');
      newLeg.set(`${pickText}.notes`, pickNotes);
      newLeg.set(`${dropText}.notes`, dropNotes);
      newLeg.set('serviceWindow', serviceWindow);
      newLeg.set('segment.noSharing', noSharing);
      newLeg.set('anchor', null);
      newLeg.set('requestTime', null);
      newLeg.set('fundingAgency', fundingAgency);
      newLeg.set('tripPurpose', tripPurpose);
      this.setPickAndDropAddressAndLocation(leg, newLeg);
      this.setLoadAndUnloadTimeForLeg(leg);
      this.setPickAndDropPhoneNumber(leg, newLeg);
      const newLegTravelNeeds = this.createCopyBookingTravelNeeds(legTravelNeeds, newLeg);
      newLeg.set('legTravelNeeds', newLegTravelNeeds);
      return newLeg;
    },

    /**
     * Reg - NYAAR-18838 Set User Booking Permission Details
     */
    configureBookingPermission() {
      const isSameDayBookingPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createSameDayBookings, null);
      const isFutureDayBookingPermGranted = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.createFutureBookings, null);
      this.set('userBookingPermissionDetails', {
        isSameDayBookingPermGranted,
        isFutureDayBookingPermGranted
      });
    },

    getUserBookingPermissionDetail() {
      return this.get('userBookingPermissionDetails');
    },

    /**
     * Reg - NYAAR-18800 - When click closing icon ( X ) in the booking detail accept screen service need
     * will save with the corresponding leg. So, set the new created service need in the active booking leg
     */
    createAndSetServiceNeedInLeg() {
      const selectedRiders = this.get('selectedRiders');
      const externalRider = selectedRiders.get('firstObject');
      const store = this.get('store'); //NYAAR-19057-fix: Setting empty array if serviceNeeds is null

      const riderServiceNeeds = externalRider.get('serviceNeeds') || [];
      const serviceNeedTypes = store.peekAll('service-need-type');
      const filteredActiveBookingLegs = this.get('activeBooking.legs').filter(leg => !leg.get('id'));
      filteredActiveBookingLegs.forEach(leg => {
        const legServiceNeeds = riderServiceNeeds.map(riderServiceNeed => {
          const serviceNeedType = serviceNeedTypes.find(tntype => {
            return tntype.get('id') === riderServiceNeed;
          });

          if (!Ember.isNone(serviceNeedType)) {
            return store.createRecord('leg-service-need', {
              serviceNeedType: serviceNeedType,
              leg: leg
            });
          }
        });
        leg.set('legServiceNeeds', legServiceNeeds);
        return leg;
      });
    },

    //NYAAR-19136-some times single leg is creating with multiple trips
    uniqTrips(trips) {
      const tripsGroupedByBooking = (0, _lodash.groupBy)(trips, trip => {
        return trip.get('booking.id');
      });
      Object.keys(tripsGroupedByBooking).forEach(key => {
        const tripDetails = tripsGroupedByBooking[key];
        const sortedTrips = tripDetails.sortBy('tripId');
        const uniqTrips = sortedTrips.reverse().uniqBy('segment.id');
        tripsGroupedByBooking[key] = uniqTrips;
      });
      const uniqTripDetails = (0, _lodash.flattenDeep)(Object.entries(tripsGroupedByBooking));
      const filteredUniqTripDetails = uniqTripDetails.filter(trip => {
        //while groupby the trips some keys(booking id's) are string
        return typeof trip !== 'string' && trip.get('id');
      });
      return filteredUniqTripDetails;
    },

    /**
     * NYAAR-19195 : If user has permission, they will see 'DAnchorSub' in the service type drop-down for trips
     * @returns {*Array} serviceTypeOptions
     */
    getServiceTypeOptions() {
      const permissiontoDAnchorSub = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.setServiceTypetoDAnchorSub, null);
      const serviceTypeOptions = this.store.peekAll('service-window');
      return !permissiontoDAnchorSub ? serviceTypeOptions.filter(ele => ele.get('displayName') !== DANCHORSUB_SERVICE_TYPE) : serviceTypeOptions;
    },

    getFareCategoryOptions() {
      const serviceTypeOptions = this.store.peekAll('fare-category');
      return serviceTypeOptions;
    },

    getTripPurposeOptions() {
      return this.store.peekAll('trip-purpose');
    },

    /**
     * NYAAR-19220 : Querys route and trip model with routeId
     * @param {*String} routeId
     */
    async routeTripQuery(routeId) {
      await this.get('store').findRecord('route', routeId, {
        include: 'trips'
      });
    },

    //saving the modified phone number
    async saveEditedRiderPhoneNumber(phoneNumber, rider) {
      phoneNumber.type = phoneNumber.type.toLowerCase();
      const riderPhoneNumber = this.get('store').createRecord('rider-phone-number', phoneNumber);
      const riderRecords = await this.queryRiderRecord(rider); // Reg- NYAAR-19657 - if rider is not present in rider Database,
      // create the rider first and save the riderPhoneNumber Model

      if (!riderRecords.length) {
        const riderModel = this.get('store').createRecord('rider', {
          riderId: rider.riderId,
          firstName: rider.firstName,
          lastName: rider.lastName,
          middleName: rider.middleName,
          loadTime: rider.loadTime,
          unloadTime: rider.unloadTime,
          notes: rider.notes,
          dateOfBirth: rider.dateOfBirth
        });
        await this.saveModelData(riderModel);
        riderPhoneNumber.set('rider', riderModel);
        await this.saveModelData(riderPhoneNumber);
        return;
      }

      riderPhoneNumber.set('rider', riderRecords.get('firstObject'));
      await this.saveModelData(riderPhoneNumber);
    },

    /**
     *
     * @returns {Promise<void>}
     * @param model - Ember Model
     */
    async saveModelData(model) {
      await model.save();
    },

    async queryRiderRecord(rider) {
      const riderId = rider.get('riderId');
      const query = `and(eq(riderId,'${riderId}'),eq(firstName,'${rider.firstName}'),eq(lastName,'${rider.lastName}'))`;
      const riderRecords = await this.store.query('rider', {
        filter: query
      });
      return riderRecords.toArray();
    },

    //REG - NYAAR-19408 - Getting first riderPhoneNumber for the selected rider and
    //send it to the /booking-with-promise-time payload
    getRiderPhoneNumber(mainPhoneNumber) {
      const riderPhoneNumber = this.store.peekAll('rider-phone-number').filterBy('areaCode', mainPhoneNumber.areaCode).filterBy('phoneNumber', mainPhoneNumber.phoneNumber).sortBy('id').reverse().firstObject;
      return riderPhoneNumber;
    },

    getRiderMainPhoneNumber(id) {
      const riderPhoneNumber = this.store.peekAll('rider-phone-number').filter(riderPhone => riderPhone.get('rider.id') === id && riderPhone.get('type').toLowerCase() === 'main').firstObject;
      return riderPhoneNumber;
    },

    /**
     * Reg NYAAR-19625 - isAllLegs should be selected based on legTravelNeeds when we choose edit Booking
     * @param bookingLegs - Array
     */
    setIsAllLegField(bookingLegs) {
      if (bookingLegs.length > 1) {
        const firstLeg = (0, _lodash.head)(bookingLegs);
        const firstLegTravelNeeds = firstLeg.get('legTravelNeeds');
        const slicedLegs = bookingLegs.slice(1);
        const legTravelNeedMapper = {};
        firstLegTravelNeeds.forEach(firstLegTravelNeed => {
          legTravelNeedMapper[firstLegTravelNeed.get('travelNeedType.upperCaseName')] = firstLegTravelNeed;
        });
        const isEveyLegHasSameTravelNeed = slicedLegs.every(leg => {
          const legTravelNeeds = leg.get('legTravelNeeds');
          return legTravelNeeds.every(legTravelNeed => {
            const isTravelNeedAvailable = legTravelNeedMapper[legTravelNeed.get('travelNeedType.upperCaseName')];
            return isTravelNeedAvailable && Number(isTravelNeedAvailable.get('count')) === Number(legTravelNeed.get('count'));
          });
        });
        this.set('isAllLegs', isEveyLegHasSameTravelNeed);
      }
    },

    //getting booking related trips using booking id
    async getRelatedTrips(bookingId) {
      const trips = await this.get('store').query('trip', {
        filter: `eq(bookingId,'${bookingId}')`
      });
      return trips.toArray();
    },

    //if we select multiple riders, then we do edit or copy booking for single booking, selected riders set to single rider
    async setSelectedRiders(isEditMode) {
      const isCopyBooking = !Ember.isEmpty(this.get('copiedBookingRecord'));
      const isEditOrCopyBooking = isEditMode || isCopyBooking; //for edit and copy booking we change the selected riders for booking

      if (isEditOrCopyBooking) {
        const checkedRiders = this.get('checkedRiders');
        this.clearSelectedRiders();
        const riderExternalIds = checkedRiders.map(r => r.get('id'));
        this.get('workspace').set('isGlobalSpinnerVisible', true);
        await this.fetchExternalRidersAndSetToSelectedRiders(riderExternalIds, true);
        await this.fetchAllSelectedRidersForTravelNeeds();
        await this.fetchAndStoreEligibilityForAllSelectedRiders();
      }

      this.get('workspace').set('isGlobalSpinnerVisible', false);
    },

    //this function is used in new booking form for updating the certification location places
    async updateCertificationLocationPlace(booking) {
      const bookingLegs = booking.firstObject.get('legs').toArray();
      const legs = this.get('activeBooking.legs').toArray();
      bookingLegs.forEach(async (leg, index) => {
        const activeLeg = legs[index];

        if (activeLeg) {
          const activeSegments = activeLeg.get('segments');
          const legSegments = leg.get('segments').toArray();
          await activeSegments.reduce((acu, segment, segmentIndex) => {
            this.updatePlace(acu, segment, segmentIndex, legSegments);
          }, Promise.resolve());
        }
      });
    },

    //for checking is the place is certification location place
    async isCertificationLocationAddress(addressId) {
      let isCertificationLocationAddress = false;
      const placeQuery = `in(addressId,(${addressId}))&include=address,location`;
      const places = await this.store.query('place', {
        filter: placeQuery
      });

      if (places.get('firstObject.placeCategoryType.name') && places.get('firstObject.placeCategoryType.name') === CERTIFICATION_LOCATION) {
        isCertificationLocationAddress = true;
      }

      return isCertificationLocationAddress;
    },

    async querySegmentStop(segmentStopId) {
      const segmentStopQuery = `in(id,(${segmentStopId}))`;
      const segmentStop = await this.store.query('segment-stop', {
        filter: segmentStopQuery
      });
      return segmentStop.firstObject;
    },

    //save the place details
    async savePlaceDetails(addressId, place, certificationLocationRecord, stopId) {
      if (addressId) {
        const isAddressCertificationLocation = await this.isCertificationLocationAddress(addressId);

        if (isAddressCertificationLocation) {
          let placeData = place;

          if (!placeData) {
            const segmentStop = await this.querySegmentStop(stopId);
            placeData = (0, _unwrapProxy.unwrapProxy)(segmentStop.get('place'));
          }

          placeData.set('placeCategoryType', certificationLocationRecord);
          await placeData.save();
        }
      }
    },

    updatePlace(acu, segment, segmentIndex, legSegments) {
      const certificationLocationRecord = this.get('store').peekRecord('place-category-type', CERTIFICATION_LOCATION);
      const result = acu.then(async () => {
        const legSegment = legSegments[segmentIndex];
        const pickAddressId = segment.get('stops.firstObject.place.address.id');
        const dropAddressId = segment.get('stops.lastObject.place.address.id');
        const pickPlace = legSegment.set('stops.firstObject.place');
        const dropPlace = legSegment.set('stops.lastObject.place');
        const pickStopId = legSegment.get('stops.firstObject.id');
        const dropStopId = legSegment.get('stops.lastObject.id');
        await this.savePlaceDetails(pickAddressId, pickPlace, certificationLocationRecord, pickStopId);
        await this.savePlaceDetails(dropAddressId, dropPlace, certificationLocationRecord, dropStopId);
      });
      return result;
    },

    async getFutureBookingsByLeg(legFilter) {
      const futureLegs = await this.store.query('leg', {
        filter: legFilter
      });
      return futureLegs.map(leg => {
        const booking = (0, _unwrapProxy.unwrapProxy)(leg.get('booking'));
        return booking.id;
      });
    },

    async getSubscriptions(filter) {
      return await this.store.query('subscription', {
        filter
      });
    },

    getFareCalculationType(travelNeed) {
      const travelNeedTypeValue = travelNeed.get('travelNeedType.value');
      let fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.displayName] : null;

      if (Ember.isEmpty(fareCalculationType)) {
        fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.fieldName] : null;
      }

      return fareCalculationType;
    },

    isPcaTravelNeed(travelNeed) {
      const travelNeedTypeValue = travelNeed.get('travelNeedType.value');
      let fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.displayName] : null;

      if (Ember.isEmpty(fareCalculationType)) {
        fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.fieldName] : null;
      }

      return fareCalculationType === PCA_TRAVEL_NEED_TYPE;
    },

    isCompanionTravelNeed(travelNeed) {
      const travelNeedTypeValue = travelNeed.get('travelNeedType.value');
      let fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.displayName] : null;

      if (Ember.isEmpty(fareCalculationType)) {
        fareCalculationType = Ember.isPresent(travelNeedTypeValue) ? travelNeedTypeValue[FARE_CALCULATION_TYPE.fieldName] : null;
      }

      return fareCalculationType === COMPANION_TRAVEL_NEED_TYPE;
    },

    getTravelNeedsCompanion(travelNeeds) {
      const companions = travelNeeds.filter(travelNeed => {
        return this.isCompanionTravelNeed(travelNeed);
      });
      return companions;
    },

    getTravelNeedsCompanionCount(travelNeeds) {
      const companions = this.getTravelNeedsCompanion(travelNeeds);
      return companions.length > 0 ? companions.reduce((n, _ref) => {
        let {
          count
        } = _ref;
        return n + parseInt(count, 10);
      }, 0) : 0;
    }

  });

  _exports.default = _default;
});