define("adept-iq/pods/components/adhoc-reports-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OrJMpnCt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isAdhocViewEnabled\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"adhoc-report-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"widget\"],[11,\"style\",\"height:100%\"],[9],[0,\"\\n    \"],[7,\"iframe\"],[11,\"id\",\"reportViewFrame\"],[11,\"marginwidth\",\"0\"],[11,\"marginheight\",\"0\"],[11,\"hspace\",\"0\"],[11,\"vspace\",\"0\"],[11,\"align\",\"middle\"],[11,\"frameborder\",\"0\"],[11,\"height\",\"100%\"],[11,\"scrolling\",\"no\"],[11,\"width\",\"100%\"],[12,\"src\",[28,[[21,\"iframeSrc\"]]]],[12,\"onload\",[27,\"action\",[[22,0,[]],\"onIFrameLoad\"],null]],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loader\"],[9],[0,\"Loading...\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/adhoc-reports-widget/template.hbs"
    }
  });

  _exports.default = _default;
});