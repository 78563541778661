define("adept-iq/pods/components/iq-widgets/trips-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/trips-widget/config", "adept-iq/pods/components/iq-widgets/trips-widget/avlmConfig", "adept-iq/pods/components/iq-widgets/trips-widget/scheduleConfig", "adept-iq/config/environment", "adept-iq/utils/find-row-record", "adept-iq/utils/unwrapProxy", "adept-iq/config/api-urls", "ember-concurrency", "moment", "lodash", "adept-iq/config/mapped-permIds", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/async-booking-operation", "adept-iq/mixins/version-check", "adept-iq/utils/rql-generators", "adept-iq/models/trip", "adept-iq/models/stop-point", "adept-iq/models/dashboard", "adept-iq/models/segment"], function (_exports, _component, _config, _avlmConfig, _scheduleConfig, _environment, _findRowRecord, _unwrapProxy, _apiUrls, _emberConcurrency, _moment, _lodash, _mappedPermIds, _asyncScheduleOperation, _asyncBookingOperation, _versionCheck, _rqlGenerators, _trip, _stopPoint, _dashboard, _segment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const INCLUDES = ['rider', 'cluster.route', 'stops', 'stops.place', 'rider.travelNeeds', 'rider.travelNeeds.travelNeedType', 'cluster', 'cluster.route,stops.stopEvents', 'fareType', 'serviceWindow'];
  const TRIP_UNASSIGNED = 'UNASSIGNED';

  var _default = _component.default.extend(_asyncScheduleOperation.default, _asyncBookingOperation.default, _versionCheck.default, {
    dragCoordinator: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['trips-widget', 'data-test-trips-widget'],
    editModal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    workspace: Ember.inject.service(),
    session: Ember.inject.service(),
    booking: Ember.inject.service(),
    notifications: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    providersList: Ember.computed.alias('workspaceContext._selectedProviders'),
    cutOffTime4LateCancelTrip: 0,
    lateTripCancelMessage: null,
    config: _config.default,
    defaultIncludes: INCLUDES,
    noShowComponent: 'iq-widgets/noshow-event-stop-form-widget',
    cancelTripComponent: 'iq-widgets/cancel-type-form-widget',
    isScheduleDashboard: Ember.computed.readOnly('workspace.isScheduleDashboard'),
    isReconcileDashboard: Ember.computed.readOnly('workspace.isReconcileDashboard'),
    isDispatchDashboard: Ember.computed.readOnly('workspace.isDispatchDashboard'),
    providersDict: Ember.computed('providersList', function () {
      const ProvidersList = this.get('providersList');
      const dict = {};
      ProvidersList.forEach(provider => {
        dict[provider.get('id')] = provider.get('name');
      });
      return dict;
    }),
    routeexecevent: Ember.inject.service(),

    init() {
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const isScheduleDashboard = this.get('isScheduleDashboard');
      const implicitConditions = _config.default.implicit.conditions;

      if (_environment.default.APP.avlmLite) {
        this.set('config', _avlmConfig.default);
      }

      if (isDispatchDashboard) {
        const implicitConditionsForDispatch = [{
          type: 'stringEq',
          field: 'scheduleStatus',
          value: ['exported']
        }];
        _config.default.implicit.conditions = implicitConditions.concat(implicitConditionsForDispatch);
        this.set('config', _config.default);
      }

      if (isScheduleDashboard) {
        const restrictConditionForSchedule = ['scheduleStatus'];
        _scheduleConfig.default.implicit.conditions = _scheduleConfig.default.implicit.conditions.filter(condition => {
          if (restrictConditionForSchedule.includes(condition.field)) {
            return false;
          }

          return true;
        });
        this.set('config', _scheduleConfig.default);
      }

      this._super(...arguments);

      this.routeTripFetchQuery = this.routeTripFetchQuery.bind(this);
    },

    saveNoShowRecordFunction(records) {
      const store = this.get('store');
      const routeExecEventAdapter = store.adapterFor('route-exec-event');
      const tripNoShowAdapter = store.adapterFor('trip-noshow');
      const notifications = this.get('notifications');
      return records.reduce((acu, record) => {
        const dsStop = (0, _unwrapProxy.unwrapProxy)(record);
        const noshow = (0, _unwrapProxy.unwrapProxy)(dsStop.get('noShow'));
        const noshowReason = (0, _unwrapProxy.unwrapProxy)(noshow.get('noShowReasonCode'));
        const trip = (0, _unwrapProxy.unwrapProxy)(dsStop.get('trip'));
        trip.set('noshowDetails', noshow.get('notes'));
        return acu.then(() => routeExecEventAdapter.createStopNoShowEvent(record)).then(() => tripNoShowAdapter.createTripNoShow(trip, noshowReason)).then(() => {
          notifications.success(`Successfully No Showed Trip ${dsStop.get('trip.tripId')}`);
        }).catch(e => {
          notifications.warning(`Failed to No Show Trip ${dsStop.get('trip.tripId')}`);
          throw new Error(e);
        });
      }, Promise.resolve());
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('tableActions', [{
        id: 'print',
        permIds: [_mappedPermIds.default.downloadTrips],
        name: 'Download',
        action: () => {
          this.exportData();
        }
      }]);
      this.set('tripTransferAction', {
        id: 'tripTransfer',
        permId: _mappedPermIds.default.autoTransferTrip,
        name: 'Transfer Trip',
        action: trip => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingTripTransfer', {
              trip
            });
            return;
          }

          this.get('workspace').pushState('tripTransfer', {
            callback: this.get('updateTripSelection').bind(this),
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('bulkTripTransferAction', [{
        id: 'bulkTripTransfer',
        permId: _mappedPermIds.default.autoTransferTrip,
        name: 'Bulk Trip Transfer',
        action: trips => {
          if (this.get('isScheduleDashboard')) {
            this.get('workspace').pushState('schedulingBulkTripTransfer', {
              trips
            });
            return;
          }

          this.get('workspace').pushState('bulkTripTransfer', {
            trips
          });
        }
      }]);
      this.set('noShowAction', {
        id: 'noShow',
        permId: _mappedPermIds.default.noShowTrip,
        name: 'No Show',
        action: model => {
          const store = this.get('store');
          const pickStopPoint = model.get('pickStopPoint');
          const noShowReasonCodes = this.get('store').peekAll('no-show-reason-category');
          const overrideNoShowReason = this.get('permissionLayer').permInUserHash(_mappedPermIds.default.overrideNoShowReason);
          const routeTripStartTime = (0, _moment.default)(pickStopPoint.get('routeTripStartTime')).toDate(); // sort all no show reason code by the promise time start/end

          const sortedNoShowReasonCode = noShowReasonCodes.toArray().sort(function (a, b) {
            const aMinStartTime = Ember.isPresent(a.get('minutesFromPromiseTimeStart')) ? parseInt(a.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(a.get('minutesFromPromiseTimeEnd')) ? parseInt(a.get('minutesFromPromiseTimeEnd'), 10) : 999;
            const bMinStartTime = Ember.isPresent(b.get('minutesFromPromiseTimeStart')) ? parseInt(b.get('minutesFromPromiseTimeStart'), 10) : 999;
            const bMinEndTime = Ember.isPresent(b.get('minutesFromPromiseTimeEnd')) ? parseInt(b.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (aMinStartTime > bMinStartTime && aMinEndTime > bMinEndTime) {
              return 1;
            } else if (aMinStartTime < bMinStartTime && aMinEndTime < bMinEndTime) {
              return -1;
            }

            return 0;
          }); // calculate the duration for current time and stop's ETA

          const actualArriveTime = pickStopPoint.get('actualArriveTime') ? (0, _moment.default)(pickStopPoint.get('actualArriveTime')) : (0, _moment.default)();
          const promisedTime = (0, _moment.default)(pickStopPoint.get('promisedTime'));

          const minDurations = _moment.default.duration(actualArriveTime.diff(promisedTime)).asMinutes();

          let calculatedNoShowReason = sortedNoShowReasonCode.find(code => {
            const aMinStartTime = Ember.isPresent(code.get('minutesFromPromiseTimeStart')) ? parseInt(code.get('minutesFromPromiseTimeStart'), 10) : 999;
            const aMinEndTime = Ember.isPresent(code.get('minutesFromPromiseTimeEnd')) ? parseInt(code.get('minutesFromPromiseTimeEnd'), 10) : 999;

            if (minDurations >= aMinStartTime && minDurations <= aMinEndTime) {
              return true;
            }

            return false;
          });

          if (!Ember.isPresent(calculatedNoShowReason)) {
            // if it's really early then pick one with the biggest range
            if (minDurations > 0) {
              calculatedNoShowReason = sortedNoShowReasonCode.lastObject;
            } else {
              calculatedNoShowReason = sortedNoShowReasonCode.firstObject;
            }
          }

          pickStopPoint.set('routeTripStartTime', routeTripStartTime);
          pickStopPoint.set('noShow', store.createRecord('no-show', {
            timestamp: new Date(),
            noShowReasonCode: calculatedNoShowReason
          }));
          pickStopPoint.set('minPerformedTime', new Date());

          if (!Ember.isPresent(calculatedNoShowReason) || overrideNoShowReason) {
            pickStopPoint.set('noShowDropDownReadonly', false);
          } else {
            pickStopPoint.set('noShowDropDownReadonly', true);
          }

          const noShowComponent = this.get('noShowComponent'); // this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, { title: 'Perform No Show' });

          this.get('editModal').open(noShowComponent, [pickStopPoint], this.get('saveNoShowRecordFunction').bind(this), null, null, {
            title: 'No Show Trip'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('unPerformNoShowAction', {
        id: 'unperform',
        permId: _mappedPermIds.default.unperformStop,
        name: 'Unperform No Show',
        action: model => {
          const workspace = this.get('workspace');
          const pickStopPoint = model.get('pickStopPoint');
          const title = 'Unperform No Show';
          workspace.pushState('unPerformNoShow', {
            stopPoint: pickStopPoint,
            title
          }, {
            replaceSelf: true
          });
        },
        disableOnLockedRoute: true
      });
      this.set('approveNoShowRequestAction', {
        id: 'approveNoShowRequest',
        permId: _mappedPermIds.default.approveNoShowRequest,
        name: 'Approve NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Approval Form',
            trip,
            sectionId: 'approve'
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('denyNoShowRequestAction', {
        id: 'denyNoShowRequest',
        permId: _mappedPermIds.default.denyNoShowRequest,
        name: 'Deny NoShow Request',
        action: trip => {
          this.get('workspace').pushState('noShowForm', {
            displayName: 'No Show Denial Form',
            trip,
            sectionId: 'deny'
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('waitlistAction', {
        id: 'waitlist',
        permId: _mappedPermIds.default.waitlist,
        name: 'Waitlist',
        action: model => {
          this.waitlistTrip(model);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('cancelAction', {
        id: 'cancelTrip',
        permIds: [_mappedPermIds.default.cancelFuture, _mappedPermIds.default.cancelSameDay],
        name: 'Cancel Trip',
        action: model => {
          const trip = model;
          const tripId = _environment.default.APP.avlmLite ? model.get('externalId') : model.get('tripId');
          const booking = (0, _unwrapProxy.unwrapProxy)(model.get('booking.content'));
          const segment = (0, _unwrapProxy.unwrapProxy)(model.get('segment'));
          const isParaTransitTravelMode = model.get('segment.isParaTransitTravelMode');
          const isUnScheduledTrip = model.get('isUnScheduledTrip');
          const isScheduleDashboard = this.get('isScheduleDashboard');
          booking.set('timestamp', new Date());
          const cutOffTime4LateCancelTrip = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/cutOffTime4LateCancelTrip');
          const lateTripCancelMessage = this.store.peekRecord('cs-config-item', 'config-Cancel_Trips/Late_Trip_Cancel_Message');
          this.set('lateTripCancelMessage', lateTripCancelMessage);
          this.set('cutOffTime4LateCancelTrip', cutOffTime4LateCancelTrip);
          const value = Number(this.get('cutOffTime4LateCancelTrip').get('value'));
          const row = this.getSelectedCoreModel();
          const promiseTime = row.get('promiseTime');
          const tripDate = promiseTime ? (0, _moment.default)(promiseTime) : (0, _moment.default)(row.get('segment.leg.requestTime'));
          const lateCancelCutoffTime = (0, _moment.default)().add(value, 'minutes');
          const currentDate = (0, _moment.default)().startOf('day');
          const endTimeForCancellation = tripDate.subtract(value, 'minutes');
          const currentTime = (0, _moment.default)();
          const cancelTripPanelDetails = {
            lateCancelCutoffTime: lateCancelCutoffTime,
            lateCancel: false,
            displayName: 'Cancel Trip',
            trip,
            booking,
            segment,
            tripId
          };

          if (isScheduleDashboard && tripDate.date() <= currentDate.date() && (0, _moment.default)(endTimeForCancellation).isBefore(currentTime) && !isUnScheduledTrip && !isParaTransitTravelMode) {
            cancelTripPanelDetails.lateCancel = true;
            return this.showLateTripCancelBookingWarning(cancelTripPanelDetails);
          } else if (tripDate.date() <= currentDate.date() && (0, _moment.default)(endTimeForCancellation).isBefore(currentTime)) {
            cancelTripPanelDetails.lateCancel = true;
            return this.showLateTripCancelBookingWarning(cancelTripPanelDetails);
          }

          this.get('workspace').pushState('cancelTripForm', cancelTripPanelDetails);
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('editAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editFuture, _mappedPermIds.default.editPast, _mappedPermIds.default.editSameDay],
        name: 'Edit',
        action: async trip => {
          if (!trip.get('pick.segmentStop.place.address.id') || !trip.get('drop.segmentStop.place.address.id')) {
            const placeIdsInClause = [trip.get('pick.segmentStop.place.id'), trip.get('drop.segmentStop.place.id')].map(p => `'${p}'`).join(',');
            const placesQuery = `in(id,(${placeIdsInClause}))&include=address,location`;
            await this.get('store').query('place', {
              filter: placesQuery
            });
          }

          this.get('workspace').pushState('editTripWidget', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
      this.set('activityLogAction', {
        id: 'activityLog',
        permId: _mappedPermIds.default.activityLog,
        name: 'View Activity Log',
        action: trip => {
          const id = _environment.default.APP.avlmLite ? trip.get('externalId') : trip.get('tripId');
          this.get('workspace').pushState('tripActivityLog', {
            displayName: `Trip ${id} Activity Log`,
            trip
          });
        },
        disableOnLocked: false,
        disableOnLockedRoute: false
      });
      this.set('autoRescheduleAction', {
        id: 'autoReschedule',
        permId: _mappedPermIds.default.autoReschedule,
        name: 'Auto Reschedule',
        action: trip => {
          this.autoRescheduleTrip(trip);
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('autoRescheduleActionForWaitListedTrip', {
        id: 'autoReschedule',
        permId: _mappedPermIds.default.autoReschedule,
        name: 'Auto Reschedule',
        action: trip => {
          this.autoRescheduleForWaitListedTrip(trip);
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('sendToTaxiAction', {
        id: 'sendToTaxi',
        permId: _mappedPermIds.default.autoReschedule,
        name: 'Send to Taxi',
        action: trip => {
          this.sendToTaxiTrip(trip);
        },
        disableOnLocked: false,
        disableOnLockedRoute: true
      });
      this.set('dispatchEditAction', {
        id: 'edit',
        permIds: [_mappedPermIds.default.editSameDay],
        name: 'Edit',
        action: async trip => {
          if (!trip.get('pick.segmentStop.place.address.id') || !trip.get('drop.segmentStop.place.address.id')) {
            //if place address is not we have to query
            if (!trip.get('pick.segmentStop.place.id') || !trip.get('drop.segmentStop.place.id')) {
              const segmentStopIds = [trip.get('pick.segmentStop.id'), trip.get('drop.segmentStop.id')].map(s => `'${s}'`).join(',');
              await this.querySegmentStop(segmentStopIds);
            }

            const placeIdsInClause = [trip.get('pick.segmentStop.place.id'), trip.get('drop.segmentStop.place.id')].map(p => `'${p}'`).join(',');
            const placesQuery = `in(id,(${placeIdsInClause}))&include=address,location`;
            await this.get('store').query('place', {
              filter: placesQuery
            });
          } //if riderId address is not we have to query


          if (!trip.get('segment.leg.rider.riderId')) {
            const riderId = trip.get('segment.leg.rider.id');
            const riders = await this.queryRider(riderId);
            trip.set('segment.leg.rider', riders.firstObject);
          }

          this.get('workspace').pushState('editTripWidget', {
            trip
          });
        },
        disableOnLocked: true,
        disableOnLockedRoute: true
      });
    },

    rowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    oneDayTimeline: Ember.computed('workspace.{startDate,endDate}', function () {
      const startDate = (0, _moment.default)(this.get('workspace.startDate')).format('YYYY-MMM-D');
      const endDate = (0, _moment.default)(this.get('workspace.endDate')).format('YYYY-MMM-D');
      return startDate === endDate;
    }),

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto widget's other elements.

      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).addClass('drag-target');
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
    },

    drop(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.get('dragCoordinator.widgetType') !== 'routes') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target);
      if (Ember.isBlank(rowElement) || Ember.isBlank(rowElement.id)) return;
      this.$(`#${rowElement.id}`).removeClass('drag-target');
      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('rowGroup'));
      this.assignCluster(targetRowRecord, sourceRowRecord);
    },

    assignCluster(tripRow, routeRow) {
      const tripRecord = tripRow.get('record');
      const routeRecord = routeRow.get('record');

      if (routeRecord.constructor.modelName !== 'route') {
        return;
      }

      let cluster = routeRecord.get('clusters').firstObject;

      if (Ember.isBlank(cluster)) {
        cluster = this.get('store').createRecord('cluster', {
          route: routeRecord
        });
        tripRecord.set('cluster', cluster);
        cluster.save();
      } else {
        tripRecord.set('cluster', cluster);
        tripRecord.save();
      }
    },

    performDispatchWaitlist(tripId, dispatchRoute) {
      const session = this.get('session');

      const dispatchRouteVersion = _lodash.default.get(dispatchRoute, 'version', null);

      const json = {};

      if (dispatchRouteVersion) {
        json.data = {
          type: 'stopPoint',
          relationships: {
            dispatchRoute: {
              data: {
                type: 'dispatchRoute',
                id: dispatchRoute.get('id')
              }
            }
          }
        };
        json.included = [{
          type: 'dispatchRoute',
          id: dispatchRoute.get('id'),
          attributes: {
            version: dispatchRouteVersion
          }
        }];
      }

      return this.get('ajax').delete(_apiUrls.API.dispatchService.host + '/trip/' + tripId + '/waitlist', {
        method: 'DELETE',
        contentType: 'application/json',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        },
        data: json
      });
    },

    performScheduleWaitlist: (0, _emberConcurrency.task)(function* (trip) {
      const schedule = trip.get('route.schedule');
      const tripId = parseInt(trip.get('id'), 10);
      let result;

      try {
        const operationData = {
          tripId
        };
        const options = {
          operationData
        };
        result = yield this.createScheduleAsyncOperation.perform(schedule, 'tripWaitlist', options);

        if (!result) {
          throw new Error('Unable to place trip on waitlist.');
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      return Promise.resolve(result);
    }).drop(),

    performWaitlist(model) {
      if (this.get('isScheduleDashboard')) {
        return this.performScheduleWaitlist.perform(model);
      }

      return this.performDispatchWaitlist(model.get('id'), model.get('dispatchRoute'));
    },

    updateTripSelection() {
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const tripId = selectedWidgetRecord._key;
      const routeId = selectedWidgetRecord.routeId;

      if (tripId && routeId && this.get('widgetService').isSelected('tripWidget', tripId) && this.get('widgetService').isSelected('routeWidget', routeId)) {
        this.updateSelection(selectedWidgetRecord, false);
      } else {
        this.get('activeContext.topActiveContext').get('refreshTableContent').perform(['route']);
      }
    },

    async waitlistTrip(model) {
      const notifications = this.get('notifications');
      const title = 'Waitlist Trip';
      const tooltip = this.get('tooltip');
      const dispatchRoute = model.get('dispatchRoute');
      const lockRouteAction = this.get('lockRouteAction').bind(this);
      const unlockRouteAction = this.get('unlockRouteAction').bind(this);
      this.set('dispatchRouteToCheck', dispatchRoute);

      try {
        // Check dispatchRoute version first
        if (this.get('isDispatchDashboard')) {
          await this.get('versionCheckOperation').perform();
          await lockRouteAction();
        }

        tooltip.pushConfirmation({
          tip: `Are you sure you want to waitlist trip ${model.get('tripId')} ?`,
          title,
          primaryActionText: 'Confirm',
          primaryAction: async () => {
            try {
              await this.performWaitlist(model);
              notifications.success(`Successfully Waitlisted Trip ${model.get('tripId')}`);
              this.updateTripSelection();
            } catch (e) {
              let msg = `Failed to Waitlist Trip ${model.get('tripId')}`;
              if (e.message && e.message.includes('failed due to a conflict')) msg = msg + ', the Trip`s Route has already been modified by another request';
              notifications.warning(msg);
            } finally {
              await unlockRouteAction();
              tooltip.reset();
            }
          },
          secondaryActionText: 'Cancel',
          secondaryAction: async () => {
            await unlockRouteAction();
            return tooltip.reset();
          },
          closeAction: async () => {
            await unlockRouteAction();
          },
          hasOverlay: true
        });
      } catch (e) {
        if (this.get('isDispatchDashboard')) {
          await unlockRouteAction();
        }
      }
    },

    /**
     * NYAAR-19220: fetch query for route and trip model
     * @param {Object} model
     */
    async routeTripFetchQuery(model) {
      const routeId = model.get('route.id');

      if (routeId) {
        await this.get('booking').routeTripQuery(routeId);
      }
    },

    performAutoReschedule: (0, _emberConcurrency.task)(function* (model, operationData, operationType) {
      const schedule = this.getScheduleData(model);
      let asyncResult;

      try {
        const options = {
          operationData,
          outputResult: true
        };
        const {
          isJobSuccess,
          results
        } = yield this.createScheduleAsyncOperation.perform(schedule, operationType, options);
        asyncResult = isJobSuccess;

        if (!isJobSuccess) {
          const errMsg = 'Unable to reschedule trip in SES';

          if (results.includes(errMsg)) {
            throw new Error(errMsg);
          }

          throw new Error(`Trip ${model.get('tripId')} failed to reschedule`);
        }
      } catch (e) {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          throw e;
        }
      }

      yield this.get('routeTripFetchQuery')(model);
      return Promise.resolve(asyncResult);
    }),

    autoRescheduleTrip(model) {
      const title = 'Auto Reschedule';
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const failureMsg = `Trip ${model.get('tripId')} failed to reschedule`;
      const isWaitlistedTripScheduled = model.get('isWaitlisted');
      const originalRouteId = model.get('route.id');
      const originalRouteName = model.get('route.name');
      const travelMode = model.get('segment.travelMode');
      tooltip.pushConfirmation({
        tip: `Are you sure you want to automatically reschedule trip  ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const tripId = parseInt(model.get('id'), 10);
          const currentRouteId = parseInt(model.get('route.id'), 10);
          const operationData = {
            tripId,
            routes: [currentRouteId],
            routeUsage: 1
          };
          let operationType = 'tripReschedule'; // Reg-NYAAR-20243 for Taxi Trip the operationType Should be 'scheduleTrip'

          if (travelMode === _segment.TAXI_TYPE) {
            delete operationData.routes;
            delete operationData.routeUsage;
            operationType = 'scheduleTrip';
          }

          return this.get('performAutoReschedule').perform(model, operationData, operationType).then(() => {
            this.get('workspace').pushState('schedulingAutoReschedule', {
              trip: model,
              isWaitlistedTripScheduled: isWaitlistedTripScheduled,
              status: 'success',
              originalRouteId: originalRouteId,
              originalRouteName: originalRouteName
            });
          }).catch(err => {
            const errMsg = 'Unable to reschedule trip in SES';

            if (err.message === errMsg) {
              this.get('workspace').pushState('schedulingAutoReschedule', {
                trip: model,
                status: 'failure',
                originalRouteId: originalRouteId,
                originalRouteName: originalRouteName
              });
              return;
            }

            notifications.warning(failureMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        hasOverlay: true
      });
    },

    autoRescheduleForWaitListedTrip(model) {
      const title = 'Auto Reschedule';
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const failureMsg = `${model.get('tripId')} failed to reschedule`;
      const isWaitlistedTripScheduled = model.get('isWaitlisted');
      const isUnscheduledTripScheduled = model.get('isUnScheduledTrip');
      const isTaxiTravelMode = model.get('segment.isTaxiTravelMode');
      tooltip.pushConfirmation({
        tip: `Are you sure you want to automatically reschedule trip  ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const tripId = parseInt(model.get('id'), 10);
          const operationData = {
            tripId
          };
          const operationType = 'scheduleTrip';
          return this.get('performAutoReschedule').perform(model, operationData, operationType).then(() => {
            this.get('workspace').pushState('schedulingAutoReschedule', {
              trip: model,
              status: 'success',
              isWaitlistedTripScheduled: isWaitlistedTripScheduled,
              isUnscheduledTripScheduled: isUnscheduledTripScheduled,
              isTaxiTravelMode: isTaxiTravelMode
            });
          }).catch(err => {
            const errMsg = 'Unable to reschedule trip in SES';

            if (err.message === errMsg) {
              this.get('workspace').pushState('schedulingAutoReschedule', {
                trip: model,
                status: 'failure'
              });
              return;
            }

            notifications.warning(failureMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        hasOverlay: true
      });
    },

    dynamicSingleActions: Ember.computed('table.rows.@each.{selected,computedStatus}', 'selectedWidgetRecord', 'isLoading', function () {
      const role = this.get('workspace.dashboardModel.role');

      if (!_environment.default.APP.avlmLite) {
        switch (role) {
          case _dashboard.SCHEDULE_ROLENAME:
            return this._dynamicScheduleActions();

          case _dashboard.DISPATCH_ROLENAME:
          default:
            return this._dynamicDispatchActions();
        }
      }
    }),

    _dynamicScheduleActions() {
      const tripTransferAction = this.get('tripTransferAction');
      const noShowAction = this.get('noShowAction');
      const waitlistAction = this.get('waitlistAction');
      const activityLogAction = this.get('activityLogAction');
      const approveNoShowRequestAction = this.get('approveNoShowRequestAction');
      const denyNoShowRequestAction = this.get('denyNoShowRequestAction');
      const unPerformNoShowAction = this.get('unPerformNoShowAction');
      const autoRescheduleAction = this.get('autoRescheduleAction');
      const autoRescheduleActionForWaitListedTrip = this.get('autoRescheduleActionForWaitListedTrip');
      const editTrip = this.get('editAction');
      const cancelTrip = this.get('cancelAction');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const isTaxiTravelMode = row.get('segment.isTaxiTravelMode');
      const isBrokerEligible = row.get('segment.leg.brokerEligible');
      if (!row) return [];
      const isManualSchedulingMode = row.get('isManualSchedulingMode');
      let tripStatus = selectedWidgetRecord.tripStatus;
      tripStatus = tripStatus.charAt(0).toUpperCase() + tripStatus.substr(1);
      const isBrokerProviderType = row.get('provider.isBrokerProviderType');
      const isBrokerTravelMode = row.get('segment.isBrokerTravelMode');
      const pickStopStatus = row.get('pickStopPoint.computedStatus');
      const lastPerformedStopID = row.get('dispatchRoute.lastPerformedStop.trip.tripId');
      const currentRowTripID = this.get('lastRowClicked.tripId');
      const currentTime = new Date();
      const noShowTimeThreshold = this.get('store').peekRecord('cs-config-item', 'config-Driving_Configuration/noShowTimeThreshold');
      const promiseTime = row.get('promiseTime');
      let noShowAvailableTime = (0, _moment.default)(promiseTime);
      const isBusOrWalkTravelMode = row.get('segment.isBusOrWalkTravelMode'); //NYAAR-19151:No right-click context menu options for ITP trips

      if (isBusOrWalkTravelMode) return [];
      let options;

      switch (tripStatus) {
        case _trip.TRIP_SCHEDULED_STATUS:
          if (isBrokerTravelMode) {
            return [activityLogAction, tripTransferAction, editTrip, cancelTrip];
          }

          if (isTaxiTravelMode) {
            if (isManualSchedulingMode) {
              return [activityLogAction, cancelTrip, editTrip];
            }

            return [activityLogAction, autoRescheduleAction, cancelTrip, editTrip];
          }

          if (isManualSchedulingMode) {
            return this.addWaitListAction([activityLogAction, tripTransferAction, editTrip, cancelTrip], isBrokerProviderType, waitlistAction);
          }

          return this.addWaitListAction([activityLogAction, tripTransferAction, autoRescheduleAction, editTrip, cancelTrip], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_DISPATCH_STATUS:
          return this.addWaitListAction([activityLogAction, tripTransferAction], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_ENROUTE_STATUS:
          if (pickStopStatus === _stopPoint.ARRIVE_STOP_STATUS) {
            const actualArriveTime = row.get('actualArriveTime');
            if (actualArriveTime) noShowAvailableTime = _moment.default.max((0, _moment.default)(actualArriveTime), noShowAvailableTime);

            if (noShowTimeThreshold) {
              row.get('pickStopPoint').set('noShowTimeThreshold', noShowTimeThreshold);
              noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
            }

            if ((0, _moment.default)(currentTime).isBefore(noShowAvailableTime)) {
              return [activityLogAction];
            }

            return [activityLogAction, noShowAction];
          }

          if (noShowTimeThreshold) {
            row.get('pickStopPoint').set('noShowTimeThreshold', noShowTimeThreshold);
            noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
          }

          if ((0, _moment.default)(currentTime).isBefore(noShowAvailableTime)) {
            return this.addWaitListAction([activityLogAction, tripTransferAction], isBrokerProviderType, waitlistAction);
          }

          return this.addWaitListAction([activityLogAction, noShowAction, tripTransferAction], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_ACTIVE_STATUS:
          return [activityLogAction];

        case _trip.TRIP_WAITLIST_STATUS:
          if (isBrokerTravelMode) {
            options = [activityLogAction, editTrip];
            return this.addTransferTripActionForNonBrokerEligibleTrips(options, isBrokerEligible, tripTransferAction);
          }

          if (isManualSchedulingMode) {
            return [activityLogAction, tripTransferAction, editTrip, cancelTrip];
          }

          return [activityLogAction, editTrip, autoRescheduleActionForWaitListedTrip, cancelTrip];

        case _trip.TRIP_ILLEGAL_STATUS:
          return [activityLogAction, editTrip, cancelTrip];

        case _trip.TRIP_NOSHOW_STATUS:
          if (lastPerformedStopID === currentRowTripID) {
            return [activityLogAction, unPerformNoShowAction];
          }

          return [activityLogAction];

        case _trip.TRIP_NOSHOWREQUESTED_STATUS:
          return [approveNoShowRequestAction, denyNoShowRequestAction, activityLogAction];

        case _trip.TRIP_COMPLETED_STATUS:
          return [activityLogAction];

        case _trip.TRIP_CANCELED_STATUS:
        case _trip.TRIP_LATE_CANCELED_STATUS:
          return [activityLogAction];

        case _trip.TRIP_REQUESTED_STATUS:
          return [cancelTrip, activityLogAction, autoRescheduleAction];

        case _trip.TRIP_UNSCHEDULED_STATUS:
        case _trip.TRIP_CALLBACK_STATUS:
          if (isBrokerTravelMode) {
            return [activityLogAction, editTrip, cancelTrip];
          }

          if (isManualSchedulingMode) {
            return [activityLogAction, tripTransferAction, editTrip, cancelTrip];
          }

          return [activityLogAction, editTrip, autoRescheduleActionForWaitListedTrip, cancelTrip];

        default:
          return [activityLogAction];
      }
    },

    _dynamicDispatchActions() {
      const tripTransferAction = this.get('tripTransferAction');
      const noShowAction = this.get('noShowAction');
      const waitlistAction = this.get('waitlistAction');
      const activityLogAction = this.get('activityLogAction');
      const approveNoShowRequestAction = this.get('approveNoShowRequestAction');
      const denyNoShowRequestAction = this.get('denyNoShowRequestAction');
      const unPerformNoShowAction = this.get('unPerformNoShowAction');
      const autoRescheduleAction = this.get('autoRescheduleAction');
      const cancelTrip = this.get('cancelAction');
      const row = this.getSelectedCoreModel();
      const selectedWidgetRecord = this.get('selectedWidgetRecord');
      const isBrokerEligible = row.get('segment.leg.brokerEligible');
      const dispatchEditAction = this.get('dispatchEditAction');
      const isTaxiTravelMode = row.get('segment.isTaxiTravelMode');
      if (!row) return [];
      let tripStatus = selectedWidgetRecord.tripStatus;
      tripStatus = tripStatus.charAt(0).toUpperCase() + tripStatus.substr(1);
      const isBrokerProviderType = row.get('provider.isBrokerProviderType');
      const isBrokerTravelMode = row.get('segment.isBrokerTravelMode');
      const pickStopStatus = row.get('pickStopPoint.computedStatus');
      const lastPerformedStopID = row.get('dispatchRoute.lastPerformedStop.trip.tripId');
      const currentRowTripID = this.get('lastRowClicked.tripId');
      const currentTime = new Date();
      const noShowTimeThreshold = this.get('store').peekRecord('cs-config-item', 'config-Driving_Configuration/noShowTimeThreshold');
      const promiseTime = row.get('promiseTime');
      let noShowAvailableTime = (0, _moment.default)(promiseTime);
      const isBusOrWalkTravelMode = row.get('segment.isBusOrWalkTravelMode');
      if (isBusOrWalkTravelMode) return [];
      let options;

      switch (tripStatus) {
        case _trip.TRIP_SCHEDULED_STATUS:
          if (isTaxiTravelMode) return [activityLogAction, cancelTrip];
          return this.addWaitListAction([activityLogAction, tripTransferAction, cancelTrip, dispatchEditAction], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_DISPATCH_STATUS:
          if (isTaxiTravelMode) return [activityLogAction, cancelTrip];
          return this.addWaitListAction([activityLogAction, tripTransferAction, cancelTrip, dispatchEditAction], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_ENROUTE_STATUS:
          if (pickStopStatus === _stopPoint.ARRIVE_STOP_STATUS) {
            const actualArriveTime = row.get('actualArriveTime');
            if (actualArriveTime) noShowAvailableTime = _moment.default.max((0, _moment.default)(actualArriveTime), noShowAvailableTime);

            if (noShowTimeThreshold) {
              noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
            }

            if ((0, _moment.default)(currentTime).isBefore(noShowAvailableTime)) {
              return [activityLogAction, cancelTrip];
            }

            return [activityLogAction, noShowAction, cancelTrip];
          }

          if (noShowTimeThreshold) {
            noShowAvailableTime.add(parseInt(noShowTimeThreshold.value, 10), noShowTimeThreshold.unit);
          }

          if ((0, _moment.default)(currentTime).isBefore(noShowAvailableTime)) {
            if (isTaxiTravelMode) return [activityLogAction, cancelTrip];
            return this.addWaitListAction([activityLogAction, tripTransferAction, cancelTrip], isBrokerProviderType, waitlistAction);
          }

          return this.addWaitListAction([activityLogAction, noShowAction, tripTransferAction, cancelTrip], isBrokerProviderType, waitlistAction);

        case _trip.TRIP_ACTIVE_STATUS:
          return [activityLogAction];

        case _trip.TRIP_WAITLIST_STATUS:
          if (isBrokerTravelMode) {
            options = [activityLogAction];
            if (isTaxiTravelMode) return this.addTransferTripActionForNonBrokerEligibleTrips(options, isBrokerEligible);
            return this.addTransferTripActionForNonBrokerEligibleTrips(options, isBrokerEligible, tripTransferAction);
          }

          if (isTaxiTravelMode) return [activityLogAction, cancelTrip];
          return [activityLogAction, tripTransferAction, cancelTrip, dispatchEditAction];

        case _trip.TRIP_ILLEGAL_STATUS:
          return [activityLogAction, cancelTrip, dispatchEditAction];

        case _trip.TRIP_NOSHOW_STATUS:
          if (lastPerformedStopID === currentRowTripID) {
            return [activityLogAction, unPerformNoShowAction];
          }

          return [activityLogAction];

        case _trip.TRIP_NOSHOWREQUESTED_STATUS:
          return [approveNoShowRequestAction, denyNoShowRequestAction, activityLogAction];

        case _trip.TRIP_COMPLETED_STATUS:
          return [activityLogAction];

        case _trip.TRIP_CANCELED_STATUS:
        case _trip.TRIP_LATE_CANCELED_STATUS:
          return [activityLogAction];

        case _trip.TRIP_REQUESTED_STATUS:
          return [cancelTrip, activityLogAction, autoRescheduleAction];

        case _trip.TRIP_UNSCHEDULED_STATUS:
        case _trip.TRIP_CALLBACK_STATUS:
          return [activityLogAction, cancelTrip, dispatchEditAction];

        default:
          return [activityLogAction];
      }
    },

    // Hiding WaitList action for the broker trips
    addWaitListAction(actions, isBrokerProviderType, waitlistAction) {
      if (isBrokerProviderType) {
        return actions;
      }

      actions.unshift(waitlistAction);
      return actions;
    },

    dynamicBulkActions: Ember.computed('selectedWidgetRecord', 'checkedItems.[]', function () {
      const bulkTripTransferAction = this.get('bulkTripTransferAction');
      const isDispatchDashboard = this.get('isDispatchDashboard');
      const checkedRows = this.get('checkedItems') || [];

      if (!checkedRows.length) {
        return [];
      }

      const isScheduledRows = checkedRows.every(row => row.get('status') === 'scheduled');
      const isStopPerformed = isDispatchDashboard && checkedRows.some(selectedTrip => selectedTrip.get('isStopPointPerformed'));
      const isBrokerProviderType = checkedRows.some(row => row.get('provider.isBrokerProviderType'));
      const isTaxiTravelMode = checkedRows.some(row => row.get('segment.isTaxiTravelMode'));
      const isBusOrWalkTravelMode = checkedRows.some(row => row.get('segment.isBusOrWalkTravelMode')); // Check if all the selected trip belongs to same route

      const isRouteNameSame = checkedRows.map(route => route.get('routeName')).uniq(); //User must select only Scheduled trips for bulk TripTransfer

      if (isRouteNameSame.length > 1 || !isScheduledRows || isStopPerformed || isBrokerProviderType || isTaxiTravelMode || isBusOrWalkTravelMode) {
        return [];
      }

      return bulkTripTransferAction;
    }),

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      let rowStyleHandler = '';

      if (currRowData.isLocked) {
        rowStyleHandler += ' is-locked';
      }

      if (columnIndex === 0) {
        if (currRowData.otpStatus === 'Late') {
          rowStyleHandler += ' otp-late-first-column';
        }

        if (currRowData.otpStatus === 'On Time') {
          rowStyleHandler += ' otp-on-time-first-column';
        }

        if (currRowData.otpStatus === 'In Danger') {
          rowStyleHandler += ' otp-in-danger-first-column';
        }
      }

      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedTripRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('tripWidget');
      const selectedRouteSet = new Set();
      selectedTripRecords.forEach(data => {
        if (data.routeId) {
          selectedRouteSet.add(data.routeId);
        }
      });

      if (selectedRouteSet.size >= maxLimitByRouteSelection || selectedTripRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    },

    applyStatusReasonDisplayTransformation(widgetRecord) {
      if (widgetRecord.tripStatus === _trip.TRIP_NOSHOW_STATUS) {
        const noShowReason = widgetRecord.statusReason;

        if (Ember.isPresent(noShowReason)) {
          const noShowReasonCategories = this.get('store').peekAll('no-show-reason-category');
          const noShowCategory = noShowReasonCategories.findBy('name', noShowReason);

          if (Ember.isPresent(noShowCategory)) {
            widgetRecord.statusReasonDisplay = noShowCategory.get('displayName');
          } else {
            // If not found then set noShowReason directly
            widgetRecord.statusReasonDisplay = noShowReason;
          }
        }
      } else if (widgetRecord.tripStatus === _trip.TRIP_CANCELED_STATUS || widgetRecord.tripStatus === _trip.TRIP_LATE_CANCELED_STATUS) {
        const reason = widgetRecord.statusReason;
        const cancelTypes = this.get('store').peekAll('cancel-type');
        const cancelType = cancelTypes.findBy('id', reason);

        if (Ember.isPresent(cancelType)) {
          widgetRecord.statusReasonDisplay = cancelType.get('displayName');
        }
      } else if (widgetRecord.tripStatus === _trip.TRIP_ILLEGAL_STATUS) {
        widgetRecord.statusReasonDisplay = widgetRecord.statusReason;
      }
    },

    applyServiceNeedsTransformation(widgetRecord) {
      const store = this.get('store');
      const serviceNeedTypes = store.peekAll('service-need-type');
      const serviceNeeds = widgetRecord.serviceNeeds || [];
      serviceNeeds.forEach(serviceNeed => {
        const serviceNeedTypeName = serviceNeed.serviceNeedTypeName;
        const serviceNeedType = serviceNeedTypes.findBy('id', serviceNeedTypeName);
        serviceNeed.name = serviceNeedType.get('name');
        serviceNeed.displayName = serviceNeedType.get('displayName');
      });
    },

    applyFundingAgencyTransformation(widgetRecord) {
      const store = this.get('store');
      const fundingAgencies = store.peekAll('funding-agency');
      const fundingAgencyName = widgetRecord.fundingAgencyName;
      const fundingAgency = fundingAgencies.find(currFundingAgency => {
        return currFundingAgency.get('name') === fundingAgencyName;
      });

      if (Ember.isPresent(fundingAgency)) {
        widgetRecord.fundingAgencyDisplayName = fundingAgency.get('displayName');
      }
    },

    applyRouteLockInfoTransformation(widgetRecord) {
      if (widgetRecord.isLocked) {
        this.getUserName(widgetRecord);
      }
    },

    async getUserName(currRowData) {
      const lock = currRowData.lock;
      const store = this.get('store');
      let ssoUser = store.peekRecord('sso-user', lock.user);

      if (Ember.isNone(ssoUser)) {
        ssoUser = await store.find('sso-user', lock.user).catch(error => {
          const errorCode = Ember.isPresent(error.errors.get('firstObject').status) ? error.errors.get('firstObject').status : null; // This is a temporary hack because continuous otimization user key does not exist in sso
          // must remove after a permanent solution is found in locking in continuous optimization

          if (errorCode === '404') {
            currRowData.lock.fullName = 'Continuous Optimization';

            if (!store.hasRecordForId('sso-user', lock.user)) {
              return store.createRecord('sso-user', {
                id: lock.user,
                userId: lock.user,
                fullName: 'Continuous Optimization'
              });
            }
          }

          return null;
        });
      }

      if (!Ember.isNone(ssoUser)) {
        currRowData.lock.fullName = ssoUser.fullName;
      }
    },

    applyTravelNeedsTransformation(widgetRecord) {
      const store = this.get('store');
      const travelNeedTypes = store.peekAll('travel-need-type');
      const travelNeeds = widgetRecord.travelNeeds || [];
      travelNeeds.forEach(travelNeed => {
        const travelNeedTypeName = travelNeed.travelNeedTypeName;
        const travelNeedType = travelNeedTypes.findBy('id', travelNeedTypeName);
        travelNeed.displayName = travelNeedType ? travelNeedType.get('displayName') : '';
      });
    },

    applyTransformationData(widgetRecords) {
      const isScheduleDashboard = this.get('isScheduleDashboard');
      widgetRecords.forEach(widgetRecord => {
        if (!widgetRecord._transformed) {
          this.applyOtpStatusTransformation(widgetRecord);
          this.applyProviderTransformation(widgetRecord);
          this.applyStatusReasonDisplayTransformation(widgetRecord);
          this.applyServiceNeedsTransformation(widgetRecord);
          this.applyRouteLockInfoTransformation(widgetRecord);
          this.applyFundingAgencyTransformation(widgetRecord);
          this.applyTravelNeedsTransformation(widgetRecord);

          if (widgetRecord.hasOwnProperty('legNotes')) {
            widgetRecord.driverNotes = widgetRecord.legNotes;
          }

          if (this.get('isScheduleDashboard')) {
            this.applyRouteTransformation(widgetRecord);
          }

          widgetRecord._transformed = true;
        }

        if (isScheduleDashboard) {
          this.applyServiceStartAndEndTimeTransformation(widgetRecord);
        }
      });
      return widgetRecords;
    },

    /**
     * do not show Trip in TripWidget whoes Route ID contains - (SUP-)
     * @param widgetRecords
     * @param header
     * @param data
     * @returns {string|void|*}
     */
    determineWidgetRecordAction(widgetRecords, header, data) {
      if (data.isSupRoute) {
        return 'DELETE';
      }

      if (data.status === 'Cancelled') {
        return 'DELETE';
      }

      if (data.status === 'Removed') {
        return 'DELETE';
      }

      return this._super(...arguments);
    },

    async applyRouteTransformation(widgetRecord) {
      const {
        originalRouteId,
        originalRouteName,
        tripStatus,
        routeName,
        routeId
      } = widgetRecord;

      if (tripStatus === _trip.TRIP_SCHEDULED_STATUS) {
        widgetRecord.routeId = originalRouteId ? originalRouteId : routeId;
        widgetRecord.routeName = originalRouteName ? originalRouteName : routeName;
      }

      if ([_trip.TRIP_ILLEGAL_STATUS, _trip.TRIP_WAITLIST_STATUS, _trip.TRIP_CANCELED_STATUS, _trip.TRIP_LATE_CANCELED_STATUS].includes(tripStatus) && routeName) {
        widgetRecord.routeId = null;
        widgetRecord.routeName = null;
      }

      if (!routeName && routeId && _trip.TRIP_SCHEDULED_STATUS) {
        const route = await this.get('store').findRecord('route', widgetRecord.routeId);

        if (route) {
          widgetRecord.routeName = route.get('name');
        }
      }
    },

    applyProviderTransformation(widgetRecord) {
      if (widgetRecord.provider === TRIP_UNASSIGNED) return;
      const providersList = this.get('providersDict');
      widgetRecord.provider = providersList[widgetRecord.provider];
    },

    showLateTripCancelBookingWarning(cancelTripPanelDetails) {
      const tooltip = this.get('tooltip');
      tooltip.pushConfirmation({
        title: 'Late Trip Cancel',
        tip: this.get('lateTripCancelMessage').get('value'),
        primaryActionText: 'Ok',
        primaryAction: () => {
          tooltip.reset();
          this.get('workspace').pushState('cancelTripForm', cancelTripPanelDetails);
        },
        hasOverlay: true,
        closeAction: () => {
          tooltip.reset();
        }
      });
    },

    sendToTaxiTrip(model) {
      const title = 'Send to Taxi';
      const tooltip = this.get('tooltip');
      const notifications = this.get('notifications');
      const failureMsg = `Failed to send Trip ${model.get('tripId')}`;
      tooltip.pushConfirmation({
        tip: `Are you sure you want to send to taxi trip  ${model.get('tripId')} ?`,
        title,
        primaryActionText: 'Confirm',
        primaryAction: () => {
          const tripId = parseInt(model.get('id'), 10);
          const operationData = {
            tripId
          };
          const operationType = 'taxiTransfer';
          return this.get('performSendToTaxi').perform(model, operationData, operationType).then(() => {
            notifications.success(`Trip ${model.get('tripId')} successfully sent to taxi`);
          }).catch(() => {
            notifications.warning(failureMsg);
          }).finally(() => {
            tooltip.reset();
          });
        },
        secondaryActionText: 'Cancel',
        secondaryAction: () => tooltip.reset(),
        hasOverlay: true
      });
    },

    performSendToTaxi: (0, _emberConcurrency.task)(function* (model, operationData, operationType) {
      let result;

      try {
        result = yield this.get('performAutoReschedule').perform(model, operationData, operationType);
      } catch (e) {
        throw e;
      }

      return Promise.resolve(result);
    }),

    /**
     * NYAAR-18763 - Get schedule info for callback trip also..
     * @param model = trip
     * @returns schedule model{*}
     */
    getScheduleData(model) {
      const tripSchedule = model.get('schedule');
      const tripRouteSchedule = model.get('route.schedule');
      return model.get('segment.isTaxiTravelMode') || model.get('isWaitlisted') || model.get('isUnScheduledTrip') || model.get('isCallback') || model.get('segment.isBrokerTravelMode') || model.get('segment.isParaTransitTravelMode') ? tripSchedule : tripRouteSchedule;
    },

    addTransferTripActionForNonBrokerEligibleTrips(options, isBrokerEligible, tripTransferAction) {
      if (!isBrokerEligible) {
        options.pushObject(tripTransferAction);
      }

      return options;
    },

    //for getting the segmentstop places
    async querySegmentStop(segmentStopIds) {
      const segmentStopsQuery = `in(id,(${segmentStopIds}))`;
      await this.get('store').query('segment-stop', {
        filter: segmentStopsQuery
      });
    },

    //for getting the rider data
    async queryRider(riderId) {
      const riderQuery = `ilike('riderId','%${riderId}%')`;
      const riders = await this.store.query('rider', {
        filter: riderQuery
      });
      return riders.toArray();
    },

    //NYAAR-19787-in schedule workspace trips widget, future taxi trips service start and end time should be empty.
    applyServiceStartAndEndTimeTransformation(widgetRecord) {
      const serviceMode = widgetRecord.serviceMode;

      if (serviceMode === _segment.TAXI_TYPE) {
        widgetRecord.serviceStartTime = null;
        widgetRecord.serviceEndTime = null;
      } //NYAAR-20256- Start time and End time should not be blank for few Scheduled trips in Trip widget.


      const startTime = widgetRecord.serviceStartTime;
      const endTime = widgetRecord.serviceEndTime;

      if (!startTime || !endTime) {
        widgetRecord.serviceStartTime = 'GENERAL';
        widgetRecord.serviceEndTime = 'GENERAL';
      }
    }

  });

  _exports.default = _default;
});