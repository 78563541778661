define("adept-iq/pods/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "225iVmi5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dashboard-wrapper\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"isWidgetMinified\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"maximize-widget\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[23,[\"workspace\",\"useWidgetLayoutWorkspace\"]],[27,\"not\",[[23,[\"workspace\",\"isRiderManagementDashboard\"]]],null],[27,\"not\",[[23,[\"workspace\",\"isReportsDashboard\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"tile-dashboard-wrapper \",[27,\"unless\",[[23,[\"isWidgetMinified\"]],\"widget-maximize\",\"\"],null],\" \"]]],[9],[0,\"\\n    \"],[1,[27,\"tile-dashboard\",null,[[\"dashboard\"],[[23,[\"workspace\",\"dashboard\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"workspace\",\"isVehicleDriverDashboard\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"tile-dashboard-wrapper\"],[9],[0,\"\\n    \"],[2,\" vehicle-driver management component if needed can be transformed into a widget by embedding the component into that widget \"],[0,\"\\n    \"],[1,[21,\"iq-widgets/vehicle-driver-manager-widget\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"workspace\",\"isRiderManagementDashboard\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"rider-management-widget\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"workspace\",\"isReportsDashboard\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"reports-widget\",null,[[\"dashboard\"],[[23,[\"workspace\",\"dashboard\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"workspace\",\"isAdhocReportsDashboard\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"adhoc-reports-widget\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[21,\"outlet\"],false],[0,\"\\n\\n  \"],[1,[21,\"wootric-survey\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});